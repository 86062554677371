import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import SearchInput from '../../inputs/SearchInput';
import SelectInput from '../../inputs/SelectInput';
import Loader from '../../Loader';
import { fetchUsers } from '../../../actions/usersActions';
import { fetchCandidates } from '../../../actions/candidatesActions';

class Step1 extends React.Component {
	componentDidMount() {
		if (!this.props.candidates || this.props.candidates.length === 0) {
			this.props.fetchCandidates();
		}
		if (!this.props.users || this.props.users.length === 0) {
			this.props.fetchUsers();
		}
	}

	render() {
		if (!this.props.candidates) return <Loader />;
		return (
			<>
				<Form.Field>
					<Field
						name="candidate._id"
						component={SearchInput}
						options={this.props.candidates}
						props={{ initial: this.props.candidateId }}
						fluid
						required
					/>
				</Form.Field>
				<Form.Field>
					<label>Analist</label>
					<Field
						name="analist._id"
						component={SelectInput}
						selected={
							this.props.auth.permissions.includes('canAddReports')
								? this.props.auth.id
								: null
						}
						options={this.transformAnalists()}
						required
					/>
				</Form.Field>
				<Form.Field>
					<label>Jeugd/volwassen</label>
					<Field
						name="candidate.type"
						component={SelectInput}
						options={[
							{ text: 'Jeugd', value: 'jeugd', key: 'youth' },
							{ text: 'Volwassen', value: 'volwassen', key: 'adult' },
						]}
						selected="volwassen"
						required
					/>
				</Form.Field>
				<Form.Field>
					<label>Bestemming</label>
					<Field
						name="destination"
						component={SelectInput}
						options={[
							{ text: 'Kandidaat', value: 'kandidaat', key: 'candidate' },
							{ text: 'Klant', value: 'klant', key: 'customer' },
							{ text: 'Andere', value: 'andere', key: 'other' },
						]}
						selected="kandidaat"
						required
					/>
				</Form.Field>
			</>
		);
	}

	transformAnalists = () => {
		let analists = [];
		this.props.analists.forEach(analist => {
			analists.push({
				key: analist._id,
				text: `${analist.firstname} ${analist.lastname}`,
				value: analist._id,
			});
		});
		return analists;
	};
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.auth,
		candidates: Object.values(state.candidates)
			.filter(
				candidate =>
					candidate.state === 'analysis_completed' || candidate.state === 'results_sent',
			)
			.map(candidate => ({
				title: candidate.firstname + ' ' + candidate.lastname,
				firstname: candidate.firstname,
				lastname: candidate.lastname,
				id: candidate._id,
				scores: candidate.coreTalentsScores,
			})),
		analists: Object.values(state.users).filter(
			user => user.role.canValidateTestResults === true,
		),
	};
};

export default connect(
	mapStateToProps,
	{ fetchCandidates, fetchUsers },
)(Step1);
