import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SubQuestionList from '../subquestions/SubQuestionList';
import Icon from '../Icon';
import { CAN_DELETE_QUESTIONS, CAN_ADD_QUESTIONS, CAN_EDIT_QUESTIONS } from '../../permissions';

class QuestionItem extends React.Component {
	constructor() {
		super();
		this.state = {
			showSubQuestions: false,
		};
	}

	renderSubQuestions() {
		this.setState({ showSubQuestions: !this.state.showSubQuestions });
	}

	renderEditAction = question => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_EDIT_QUESTIONS)) {
			return (
				<Link to={`/vragen/aanpassen/${question._id}`}>
					<Icon icon="edit" />
				</Link>
			);
		}
	};

	renderDeleteAction = question => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_DELETE_QUESTIONS)) {
			return (
				<Link to={`/vragen/verwijderen/${question._id}`}>
					<Icon icon="delete" />
				</Link>
			);
		}
	};

	renderAddSubquestionAction = question => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_ADD_QUESTIONS)) {
			return (
				<Link to={`/vragen/subvraag/${question._id}`}>
					<Icon icon="plus" />
				</Link>
			);
		}
	};

	render() {
		const question = this.props.question;
		return (
			<>
				<Table.Row>
					<Table.Cell onClick={() => this.renderSubQuestions()}>
						{question.question}
					</Table.Cell>
					<Table.Cell onClick={() => this.renderSubQuestions()}>
						{question.coretalent ? question.coretalent.name : 'Geen talent'}
					</Table.Cell>
					<Table.Cell collapsing>
						<div className="waw-table-actions">
							{this.renderAddSubquestionAction(question)}
							{this.renderEditAction(question)}
							{this.renderDeleteAction(question)}
						</div>
					</Table.Cell>
				</Table.Row>
				{this.state.showSubQuestions && (
					<SubQuestionList primaryQuestion={this.props.question} />
				)}
			</>
		);
	}
}

const mapStateToProps = state => {
	return { auth: state.auth };
};

export default connect(
	mapStateToProps,
	null,
)(QuestionItem);
