import _ from 'lodash';
import {
	FETCH_CLUSTERS,
	FETCH_CLUSTER,
	DELETE_CLUSTER,
	EDIT_CLUSTER,
	CREATE_CLUSTER,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_CLUSTERS:
			//useing _.mapKeys here, to create objects from questions array => easier to use
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_CLUSTER:
			return { ...state, [action.payload._id]: action.payload };
		case EDIT_CLUSTER:
			return { ...state, [action.payload._id]: action.payload };
		case CREATE_CLUSTER:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_CLUSTER:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
