import React from 'react';
import { Route } from 'react-router-dom';
import RoleCreate from '../components/roles/RoleCreate';
import RoleDelete from '../components/roles/RoleDelete';
import RoleEdit from '../components/roles/RoleEdit';
import RoleList from '../components/roles/RoleList';
import RoleDetail from '../components/roles/RoleDetail';

const RolesRoutes = () => {
	return (
		<>
			<Route path="/rollen" exact component={RoleList} />
			<Route path="/rollen/detail/:id" exact component={RoleDetail} />
			<Route path="/rollen/aanpassen/:id" exact component={RoleEdit} />
			<Route path="/rollen/verwijderen/:id" exact component={RoleDelete} />
			<Route path="/rollen/toevoegen" exact component={RoleCreate} />
		</>
	);
};

export default RolesRoutes;
