import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { fetchCandidate } from '../../../actions/candidatesActions';
import { fetchUser } from '../../../actions/usersActions';
import Loader from '../../Loader';
import WysiwygEditor from '../../inputs/WysiwygEditor';
import { fetchTexts } from '../../../actions/settingsActions';

class Step3 extends React.Component {
	async componentDidMount() {
		this.props.fetchTexts();
		if (!this.props.candidate) {
			await this.props.fetchCandidate(this.props.candidateId);
		}
		if (!this.props.user) {
			await this.props.fetchUser(this.props.analistId);
		}
		this.props.onChangeReportName(
			`TalentScan_${this.props.candidate.firstname}_${this.props.candidate.lastname}`,
		);
		this.props.onChangeEnding(this.getPersonalText());
	}

	render() {
		if (!this.props.candidate || !this.props.analist || !this.props.report_ending)
			return <Loader />;
		return (
			<>
				<Form.Field>
					<label>Naam</label>
					<Field name="name" component="input" />
				</Form.Field>
				<Form.Field>
					<label>Persoonlijke afsluiter</label>
					<Field
						name="ending"
						component={WysiwygEditor}
						rows="20"
						text={this.getPersonalText()}
					/>
				</Form.Field>
			</>
		);
	}

	getPersonalText = () => {
		if (!this.props.analistId || !this.props.candidateId) return '';
		let text = this.props.report_ending
			.replace('**kandidaat_voornaam**', this.props.candidate.firstname)
			.replace('**analist_voornaam**', this.props.analist.firstname)
			.replace('**analist_achternaam**', this.props.analist.lastname);
		return text;
		/*`<p>Beste ${this.props.candidate.firstname},</p>
		<p>Je kreeg een behoorlijk aantal sterke KernTalenten mee die je als jouw persoonlijk kapitaal kan bekijken. Door je talenten nog verder te ontwikkelen en te versterken kan je jouw potentieel helemaal benutten.</p>
		<p>Neem de tijd om het te lezen. En vooral: vink het punt voor punt af telkens je voor een belangrijke keuze staat in je leven. Zo ben je er zeker van dat je zoveel mogelijke sterke KernTalenten kan inzetten, maar ook dat je geen te zwaar gewicht toekent aan je minder sterke talenten. Ik beloof je: daar zal je veel energie uit halen!</p><p><b>WaW maakt zich sterk om voor jou een job te selecteren die helemaal aansluit bij jouw talenten.</b> We willen immers dat je gelukkig wordt in je job en dat je jezelf verder kan ontplooien.</p>
		<p>Ik neem snel opnieuw contact met je op om een paar specifieke jobmogelijkheden te bespreken.</p>
		<p>Van harte welkom!</p>
		<p>${this.props.analist.firstname} ${this.props.analist.lastname}<br/>Erkend KernTalentanalist</p>`;*/
	};
}

const mapStateToProps = (state, ownProps) => {
	return {
		candidate: state.candidates[ownProps.candidateId],
		analist: state.users[ownProps.analistId],
		report_ending: state.settings.report_ending,
	};
};

export default connect(
	mapStateToProps,
	{ fetchCandidate, fetchUser, fetchTexts },
)(Step3);
