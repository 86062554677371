import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import WysiwygEditor from '../inputs/WysiwygEditor';

class CombinationForm extends React.Component {
	onSubmit = formValues => {
		const filteredValues = {
			cluster: formValues.cluster,
			code: formValues.code,
			coreTalents: formValues.coreTalents.map(coreTalent => ({
				_id: coreTalent._id,
				strength: coreTalent.strength,
			})),
			descriptionDefault: formValues.descriptionDefault,
			descriptionYouth: formValues.descriptionYouth,
		};
		this.props.onSubmit(filteredValues);
	};

	render() {
		const renderCoreTalents = ({ fields, meta: { error } }) => {
			const coreTalents = fields.getAll();
			return (
				<div>
					{fields.map((coreTalent, index) => {
						return (
							<div className="waw-flex field" key={coreTalents[index]._id}>
								<div className="waw-flex-1">{coreTalents[index].talent}</div>
								<div className="waw-flex-1">
									<Field name={`${coreTalent}.strength`} component="select">
										<option value="sterk">Sterk</option>
										<option value="half">Half</option>
										<option value="klein">Klein</option>
									</Field>
								</div>
							</div>
						);
					})}
				</div>
			);
		};

		return (
			<form className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<div className="waw-error">{this.props.error}</div>
				<div className="field">
					<label>Code</label>
					<div>
						<Field name="code" component="input" type="text" />
					</div>
				</div>
				<div className="field">
					<label>Kerntalenten</label>
					<div>
						<FieldArray name="coreTalents" component={renderCoreTalents} />
					</div>
				</div>

				<div className="field">
					<label>Beschrijving</label>
					<div>
						<Field name="descriptionDefault" component={WysiwygEditor} />
					</div>
				</div>
				<div className="field">
					<label>Beschrijving voor jeugd</label>
					<div>
						<Field name="descriptionYouth" component={WysiwygEditor} />
					</div>
				</div>
				<button type="submit" className="ui button primary">
					Opslaan
				</button>
			</form>
		);
	}
}

CombinationForm = reduxForm({
	form: 'combinationForm',
})(CombinationForm);

export default CombinationForm;
