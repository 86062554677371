import {
	START_QUESTIONNAIRE,
	FETCH_STARTED_QUESTIONNAIRE,
	ADD_ANSWER,
	FETCH_NEXT_QUESTION,
	FETCH_PREVIOUS_QUESTION,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case START_QUESTIONNAIRE:
			return { ...state, ...action.payload };
		case FETCH_STARTED_QUESTIONNAIRE:
			return { ...state, ...action.payload };
		case ADD_ANSWER:
			return { ...state, ...action.payload };
		case FETCH_NEXT_QUESTION:
			return { ...state, nextQuestion: action.payload };
		case FETCH_PREVIOUS_QUESTION:
			return { ...state, previousQuestion: action.payload };
		default:
			return state;
	}
};
