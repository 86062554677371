import React from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../img/wawlogo.svg';
import { search } from '../actions/searchActions';
import { logout } from '../actions/authActions';
import { Dropdown, Label, Image } from 'semantic-ui-react';
import '../css/Header.css';
import history from '../history';

const images = require.context('../img', true);

class Header extends React.Component {
	searchChanged(e) {
		this.props.search(e.target.value);
	}

	render() {
		return (
			<Grid columns={16} padded className="waw-header">
				<Grid.Column width={2}>
					<Link to="/">
						<img id="waw-logo" alt="logo" src={logo} />
					</Link>
				</Grid.Column>
				<Grid.Column width={8}>
					<div className="ui icon input" id="searchBox">
						<i className="circular search link icon" />
						<input
							type="text"
							placeholder="Zoek ..."
							value={this.props.searchValue}
							onChange={this.searchChanged.bind(this)}
						/>
					</div>
				</Grid.Column>
				<Grid.Column width={3} />
				<Grid.Column width={3}>
					<Dropdown
						style={{ float: 'right', margin: 0 }}
						trigger={
							<Label basic size="large">
								<Image src={images('./profile.svg')} avatar />
								{this.props.auth.firstname + ' ' + this.props.auth.lastname}
							</Label>
						}
						pointing="top left"
						icon={null}
					>
						<Dropdown.Menu>
							<Dropdown.Item
								text="Profiel"
								icon="user"
								onClick={() =>
									history.push(`/gebruikers/aanpassen/${this.props.auth.id}`)
								}
							/>
							<Dropdown.Item
								key="logout"
								text="Uitloggen"
								icon="sign out"
								onClick={() => this.props.logout()}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</Grid.Column>
			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		auth: state.auth,
		searchValue: state.search.search ? state.search.search : '',
	};
};

export default connect(
	mapStateToProps,
	{ search, logout },
)(Header);
