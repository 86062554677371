import React from 'react';
import { connect } from 'react-redux';
import { Progress, Grid, Container } from 'semantic-ui-react';
import { fetchCandidate } from '../../actions/candidatesActions';
import QuestionnaireHeader from './QuestionnaireHeader';
import Loader from '../Loader';
import logo from '../../img/wawlogo.svg';
import CoreTalentsDisclaimer from './CoreTalentsDisclaimer';

class QuestionnaireEnd extends React.Component {
	componentDidMount() {
		if (!this.props.candidate) {
			this.props.fetchCandidate(this.props.match.params.candidateId);
		}
	}

	render() {
		if (!this.props.candidate) return <Loader />;
		return (
			<>
				<Grid.Row>
					<QuestionnaireHeader candidate={this.props.candidate} />
				</Grid.Row>
				<Grid.Row>
					<Container fluid className="waw-questionnaire">
						<Progress percent={100} progress color="orange" size="large" />
						<Container fluid className="questionnaire-end">
							<img alt="logo" src={logo} />
							<br />
							<h1>Proficiat {this.props.candidate.firstname}, je bent klaar!</h1>
							<p>
								Je bent klaar met de vragenlijst. Onze analisten nemen zo snel
								mogelijk contact met je op om de resultaten te bespreken.
							</p>
							<p>Je mag deze pagina nu sluiten.</p>
							<p>
								<b>Bedankt!</b>
							</p>
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
						</Container>
						<CoreTalentsDisclaimer />
					</Container>
				</Grid.Row>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { candidate: state.candidates[ownProps.match.params.candidateId] };
};

export default connect(mapStateToProps, { fetchCandidate: fetchCandidate })(QuestionnaireEnd);
