import {
  FETCH_REPORTS,
  FETCH_REPORT,
  DELETE_REPORT,
  CREATE_REPORT,
  FETCH_CANDIDATE_REPORTS,
  FORM_ACTION_FAILED
} from "./types";
import { reports, candidates } from "../apis/waw";
import history from "../history";

export const fetchReports = () => async dispatch => {
  const response = await reports.get("/");
  dispatch({
    type: FETCH_REPORTS,
    payload: response.data
  });
};

export const fetchReport = id => async dispatch => {
  const response = await reports.get(`/${id}`);
  dispatch({
    type: FETCH_REPORT,
    payload: response.data
  });
};

export const fetchCandidateReports = id => async dispatch => {
  const response = await candidates.get(`/${id}/reports`);
  dispatch({
    type: FETCH_CANDIDATE_REPORTS,
    payload: response.data
  });
};

export const createReport = formValues => async dispatch => {
  try {
    const response = await reports.post("/", { ...formValues });
    dispatch({
      type: CREATE_REPORT,
      payload: response.data
    });
    history.push("/rapporten");
  } catch (err) {
    dispatch({
      type: FORM_ACTION_FAILED,
      payload: {
        error: err.response.data.error
      }
    });
  }
};

export const deleteReport = id => async dispatch => {
  await reports.delete(`/${id}`);
  dispatch({
    type: DELETE_REPORT,
    payload: id
  });
  history.push("/rapporten");
};
