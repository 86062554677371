import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import CoreTalentForm from './CoreTalentForm';
import { fetchCoreTalent, editCoreTalent } from '../../actions/coretalentsActions';
import Loader from '../Loader';
import Modal from '../Modal';
import history from '../../history';

class CoreTalentEdit extends React.Component {
	componentDidMount() {
		this.props.fetchCoreTalent(this.props.match.params.id);
	}

	onSubmit = formValues => {
		this.props.editCoreTalent(formValues, this.props.match.params.id);
	};

	renderForm = () => {
		return (
			<CoreTalentForm
				onSubmit={this.onSubmit}
				initialValues={_.pick(
					this.props.coretalent,
					'name',
					'description',
					'code',
					'keyword',
					'includeInReport',
				)}
			/>
		);
	};

	render() {
		if (!this.props.coretalent) return <Loader />;
		return (
			<Modal
				title="KernTalent aanpassen"
				content={this.renderForm()}
				onDismiss={() => history.push('/kerntalenten')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { coretalent: state.coretalents[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchCoreTalent: fetchCoreTalent, editCoreTalent: editCoreTalent },
)(CoreTalentEdit);
