import React from 'react';
import { List } from 'semantic-ui-react';

class CandidateLog extends React.Component {
	render() {
		const { icon, text } = this.props;
		return (
			<List.Item>
				{icon ? <List.Icon name={icon} /> : null}
				<List.Content>{text}</List.Content>
			</List.Item>
		);
	}
}

export default CandidateLog;
