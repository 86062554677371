import { reducer as formReducer } from 'redux-form';
import { FORM_ACTION_FAILED } from '../actions/types';

export default formReducer.plugin({
	clusterForm: (state, action) => {
		switch (action.type) {
			case FORM_ACTION_FAILED:
				return {
					...state,
					error: action.payload.error,
				};
			default:
				return state;
		}
	},
	combinationForm: (state, action) => {
		switch (action.type) {
			case FORM_ACTION_FAILED:
				return {
					...state,
					error: action.payload.error,
				};
			default:
				return state;
		}
	},
	candidatesForm: (state, action) => {
		switch (action.type) {
			case FORM_ACTION_FAILED:
				return {
					...state,
					error: action.payload.error,
				};
			default:
				return state;
		}
	},
	reportForm: (state, action) => {
		switch (action.type) {
			case FORM_ACTION_FAILED:
				return {
					...state,
					error: action.payload.error,
				};
			default:
				return state;
		}
	},
});
