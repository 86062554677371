import { FETCH_TOKEN, LOGOUT, LOGIN_FAILED } from './types';
import { auth } from '../apis/waw';
import jwt from 'jsonwebtoken';
import { removeState } from '../localStorage';

export const fetchToken = (username, password) => async dispatch => {
	try {
		const response = await auth.post('/login', {
			email: username,
			password: password,
		});
		const decoded = await jwt.decode(response.data);
		dispatch({
			type: FETCH_TOKEN,
			payload: {
				token: response.data,
				...decoded,
			},
		});
	} catch (err) {
		console.error('Caught error:', err);
		console.log(err.data);
		dispatch({
			type: LOGIN_FAILED,
			payload: {
				error: err.message,
			},
		});
	}
};

export const logout = () => async dispatch => {
	removeState();
	dispatch({
		type: LOGOUT,
	});
};
