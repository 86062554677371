import React from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal';
import { createUser } from '../../actions/usersActions';
import UserForm from './UserForm';
import history from '../../history';

class UserCreate extends React.Component {
	onSubmit = formValues => {
		this.props.createUser(formValues);
	};

	renderForm = () => {
		return <UserForm onSubmit={this.onSubmit} passwordRequired={true} />;
	};

	render() {
		return (
			<Modal
				title="Nieuwe gebruiker"
				content={this.renderForm()}
				onDismiss={() => history.push('/gebruikers')}
			/>
		);
	}
}

export default connect(
	null,
	{ createUser: createUser },
)(UserCreate);
