import {
	START_QUESTIONNAIRE,
	FETCH_STARTED_QUESTIONNAIRE,
	FETCH_NEXT_QUESTION,
	FETCH_PREVIOUS_QUESTION,
	ADD_ANSWER,
	ADD_DESCRIPTION,
} from './types';
import { questionnaire_progress, questionnaires } from '../apis/waw';

export const startQuestionnaire = candidateId => async dispatch => {
	const response = await questionnaire_progress.post(`/${candidateId}/start`);
	dispatch({
		type: START_QUESTIONNAIRE,
		payload: response.data,
	});
};

export const fetchStartedQuestionnaire = questionnaireId => async dispatch => {
	const response = await questionnaires.get(`/${questionnaireId}`);
	dispatch({
		type: FETCH_STARTED_QUESTIONNAIRE,
		payload: response.data,
	});
};

export const fetchNextQuestion = (questionnaireId, currentQuestionId) => async dispatch => {
	const response = await questionnaire_progress.get(
		`/${questionnaireId}/${currentQuestionId}/next`,
	);
	dispatch({
		type: FETCH_NEXT_QUESTION,
		payload: response.data,
	});
};

export const fetchPreviousQuestion = (questionnaireId, currentQuestionId) => async dispatch => {
	const response = await questionnaire_progress.get(
		`/${questionnaireId}/${currentQuestionId}/previous`,
	);
	dispatch({
		type: FETCH_PREVIOUS_QUESTION,
		payload: response.data,
	});
};

export const addAnswer = (questionnaireId, answer) => async dispatch => {
	const response = await questionnaire_progress.post(`/${questionnaireId}/answer`, answer);
	dispatch({
		type: ADD_ANSWER,
		payload: response.data,
	});
};

export const addDescripton = (questionnaireId, description) => async dispatch => {
	const response = await questionnaire_progress.put(`/${questionnaireId}/description`, {
		description: description,
	});
	dispatch({
		type: ADD_DESCRIPTION,
		payload: response.data,
	});
};
