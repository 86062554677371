import React from 'react';
import { Route } from 'react-router-dom';

import ClusterList from '../components/combinations/ClusterList';
import CombinationCreate from '../components/combinations/CombinationCreate';
import CombinationEdit from '../components/combinations/CombinationEdit';
import CombinationDelete from '../components/combinations/CombinationDelete';
import ClusterCreate from '../components/combinations/ClusterCreate';
import ClusterEdit from '../components/combinations/ClusterEdit';
import ClusterDelete from '../components/combinations/ClusterDelete';

const CombinationsRoutes = () => {
	return (
		<>
			<Route path="/combinaties" exact component={ClusterList} />
			<Route path="/combinaties/toevoegen/:id" exact component={CombinationCreate} />
			<Route path="/combinaties/aanpassen/:id" exact component={CombinationEdit} />
			<Route path="/combinaties/verwijderen/:id" exact component={CombinationDelete} />
			<Route path="/clusters/toevoegen" exact component={ClusterCreate} />
			<Route path="/clusters/aanpassen/:id" exact component={ClusterEdit} />
			<Route path="/clusters/verwijderen/:id" exact component={ClusterDelete} />
		</>
	);
};

export default CombinationsRoutes;
