import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchUser, deleteUser } from '../../actions/usersActions';
import Modal from '../Modal';
import Loader from '../Loader';
import history from '../../history';

class UserDelete extends React.Component {
	componentDidMount() {
		this.props.fetchUser(this.props.match.params.id);
	}

	renderContent() {
		if (!this.props.user) return <Loader />;
		return (
			<div>
				<p>
					Ben je zeker dat je gebruiker "
					<b>
						{this.props.user.firstname} {this.props.user.lastname}
					</b>
					" wil verwijderen?
				</p>
			</div>
		);
	}

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteUser(this.props.match.params.id)}
					className="ui button negative"
				>
					Verwijderen
				</button>
				<Link to="/gebruikers" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	render() {
		return (
			<Modal
				title="Gebruiker verwijderen"
				content={this.renderContent()}
				actions={this.renderActions()}
				onDissmiss={() => history.push('/gebruikers')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { user: state.users[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{
		fetchUser: fetchUser,
		deleteUser: deleteUser,
	},
)(UserDelete);
