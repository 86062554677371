import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Message } from 'semantic-ui-react';

import { fetchCandidate } from '../../actions/candidatesActions';
import Loader from '../Loader';
import logo from '../../img/wawlogo.svg';
import history from '../../history';
import { fetchQuestionnaire } from '../../actions/questionnairesActions';
import {
	STATE_QUESTIONNAIRE_COMPLETED,
	STATE_QUESTIONNAIRE_STARTED,
	STATE_RESULTS_SENT,
	STATE_ANALYSIS_COMPLETED,
} from '../CandidateStates';
import CoreTalentsDisclaimer from './CoreTalentsDisclaimer';

class QuestionnaireIntro extends React.Component {
	constructor() {
		super();
		this.state = {
			showIntro1: true,
			showIntro2: false,
			showInstructions: false,
			acceptedPrivacyStatement: false,
			showMessage: false,
		};
	}

	async componentDidMount() {
		if (!this.props.candidate) {
			await this.props.fetchCandidate(this.props.match.params.candidateId);
		}

		if (
			this.props.candidate.state === STATE_QUESTIONNAIRE_COMPLETED ||
			this.props.candidate.state === STATE_RESULTS_SENT ||
			this.props.candidate.state === STATE_ANALYSIS_COMPLETED
		) {
			return history.push(`/vragenlijst/einde/${this.props.candidate._id}`);
		}
		if (this.props.candidate.state === STATE_QUESTIONNAIRE_STARTED) {
			if (!this.props.candidate.questionnaire) {
				await this.props.fetchQuestionnaire(this.props.candidate.questionnaire);
			}
			if (
				this.props.candidate.questionnaire &&
				!this.props.candidate.questionnaire.description
			) {
				return history.push(`/vragenlijst/beschrijving/${this.props.candidate._id}`);
			}
			return history.push(`/vragenlijst/${this.props.candidate._id}`);
		}
	}

	next() {
		if (this.state.showIntro1) {
			this.setState({
				showIntro1: false,
				showIntro2: true,
			});
		} else if (!this.state.showIntro1 && this.state.showIntro2) {
			this.setState({
				showIntro2: false,
				showInstructions: true,
			});
		} else {
			if (!this.state.acceptedPrivacyStatement) {
				this.setState({ showMessage: true });
			} else {
				history.push(`/vragenlijst/beschrijving/${this.props.candidate._id}`);
			}
		}
	}

	previous() {
		if (this.state.showIntro2) {
			this.setState({
				showIntro1: true,
				showIntro2: false,
				showInstructions: false,
			});
		} else if (this.state.showInstructions) {
			this.setState({
				showIntro1: false,
				showIntro2: true,
				showInstructions: false,
			});
		}
	}

	render() {
		if (!this.props.candidate) return <Loader />;
		return (
			<div className="waw-questionnaire ">
				<div className="questionnaire-intro">
					<img alt="logo" src={logo} />
					{/* op vraag van Kristel verwijderd op 2/8/2019
						<h2>
						Welkom {candidate.firstname} {candidate.lastname}
					</h2> */}
					{this.state.showIntro1 && this.renderIntro(1)}
					{this.state.showIntro2 && this.renderIntro(2)}
					{this.state.showInstructions && this.renderIntro(3)}
					<div className="buttons">
						<div className="previous" onClick={() => this.previous()}>
							Vorige
						</div>
						<div onClick={() => this.next()} className="next">
							{!this.state.showInstructions && 'Volgende >'}
							{this.state.showInstructions && 'Start >'}
						</div>
					</div>
				</div>
				<CoreTalentsDisclaimer />
			</div>
		);
	}

	renderIntro(number) {
		switch (number) {
			case 1:
				return (
					<>
						<h2>
							Beste{' '}
							{this.props.candidate.firstname ? this.props.candidate.firstname : null}
							,
						</h2>
						<p>
							De KernTalentenmethode is dé methode om snel en accuraat je KernTalenten
							in kaart te brengen. Er zijn 23 KernTalenten, waaronder creativiteit,
							organisatietalent, zin voor initiatief, emotionele talenten op
							individueel niveau en op groepsniveau, rationaliteit, commercialiteit,
							naar buiten treden, etc.
						</p>
						<p>
							Samen vormen deze KernTalenten de blauwdruk van je persoonlijkheid: je
							aard, je potentieel en je intrinsieke motivatie.
						</p>
						<p>
							Bovenop je originele blauwdruk oefenen levensgebeurtenissen, cultuur,
							opvoeding, intelligentie, geslacht, enz. een enorme invloed uit op de
							ontwikkeling van jouw KernTalenten en persoonlijkheid.
						</p>
						<p>
							Een goed inzicht in jouw oorspronkelijke persoonlijkheid heb je
							broodnodig wanneer je een belangrijke keuze in je leven moet maken,
							zoals voor een studie of opleiding, een beslissing over een beroep of
							job of bij vragen in je relaties.
						</p>
					</>
				);
			case 2:
				return (
					<>
						<h1>De essentie van de KernTalenten methode</h1>
						<p>
							De essentie van de KernTalentenmethode is terug te vinden in je
							kindertijd. We gaan terug naar je kindertijd toen je tussen 4 en 12 jaar
							(lagere schooltijd, voor je puberteit) was en bevragen je over je
							favoriete kinderspelletjes. Als kind kies je onbewust voor specifiek
							speelgoed, spelletjes of activiteiten omdat die aansluiten bij je
							karakter, je mogelijkheden en je goesting om bepaalde dingen op een
							bepaalde manier te doen. Wat jij als kind wel of niet leuk vond, vormt
							de basis van jouw unieke KernTalentenprofiel. Je bent als mens uniek en
							niet in een 'type' of binnen een hokje samen te vatten.
						</p>
					</>
				);
			case 3:
				return (
					<>
						<h1>Instructies voor het invullen van de vragenlijst</h1>
						<p>
							Enkel als je de instructies goed volgt, zal het resultaat accuraat zijn.
							<br />
							Je krijgt de mogelijkheid om op een schaal van <b>0 tot 100</b> aan te
							duiden hoe graag je iets deed in je kindertijd tussen 4 en circa 12
							jaar, alleszins voor je puberteit.{' '}
							<b>Let altijd op die leeftijdsgrens!</b>
						</p>
						<ul>
							<li>
								Het heeft geen zin om overal hoge scores te geven om zo een ‘beter’
								resultaat te verkrijgen. Onze analisten halen de fout ingevulde
								scores er onverbiddelijk uit. Dit werkt dus eerder in je nadeel.
							</li>
							<li>
								Gebruik de volledige schaal: de activiteiten die <b>jij zelf</b> het
								allerliefste deed (en dus niet je vriendjes, ouders, broers/zussen,
								…,) scoor je uiterst rechts. De spelletjes die je helemaal niet
								graag deed, scoor je uiterst links. Daartussenin scoor je al
								naargelang hoe graag je het deed.
							</li>
							<li>
								Niet hoe vaak of hoe goed je iets deed,{' '}
								<b>wel hoe grààg je het deed, telt.</b>
							</li>
							<li>
								Als je het speelgoed of spel niet kent, niet gedaan hebt of het
								interesseerde je gewoonweg niet, kruis dan ‘niet van toepassing’
								aan.{' '}
							</li>
							<li>
								Soms krijg je enkele voorbeelden in één vraag. Geef dan je score op
								de activiteit die je het liefst deed.
							</li>
						</ul>
						<p>
							Tot slot:
							<br />
							Je kan elk moment teruggaan naar vorige schermen en aanpassingen doen.
							<br />
							Wanneer je tijdens het invullen van de lijst onderbroken wordt of
							wanneer je even pauzeert, kan je daarna gewoon verdergaan.
							<br />
							Op het einde van de vragenlijst kan je zelf correcties aanbrengen door
							activiteiten te vergelijken en scores aan te passen. Dit onderdeel is
							zeer belangrijk en vraagt de nodige aandacht!
							<br />
							Alles bij elkaar zal het invullen van de vragenlijst ongeveer tussen een
							half uur en een uur duren.
						</p>
						<p>Veel plezier!</p>
						<p>Het WaW-team</p>
						<Checkbox
							label={
								<label>
									Ik ga akkoord met de{' '}
									<a
										href="https://www.waw.jobs/privacy-statement"
										target="_blank"
										rel="noopener noreferrer"
									>
										privacy
									</a>
									- en{' '}
									<a
										href="https://www.waw.jobs/algemene-voorwaarden"
										target="_blank"
										rel="noopener noreferrer"
									>
										algemene voorwaarden
									</a>{' '}
									van WaW
								</label>
							}
							onChange={(e, data) => {
								const message = data.checked ? false : true;
								this.setState({
									acceptedPrivacyStatement: data.checked,
									showMessage: message,
								});
							}}
						/>
						{this.state.showMessage ? (
							<Message negative size="tiny">
								<Message.Header>
									Accepteer de algemene voorwaarden en privacy statement
								</Message.Header>
								<p>
									Gelieve de algemene voorwaarden en het privacy statement te
									lezen en accepteren voor u aan de test begint. U kan op de link
									klikken om de voorwaarden en het privacy statement te lezen. Zet
									het vinkje hierboven aan om te accepteren.
								</p>
							</Message>
						) : null}
					</>
				);

			default:
				return ' ';
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	return { candidate: state.candidates[ownProps.match.params.candidateId] };
};

export default connect(mapStateToProps, {
	fetchCandidate: fetchCandidate,
	fetchQuestionnaire,
})(QuestionnaireIntro);
