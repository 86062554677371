import React from 'react';
import { Route } from 'react-router-dom';

import CoreTalentList from '../components/coretalents/CoreTalentList';
import CoreTalentCreate from '../components/coretalents/CoreTalentCreate';
import CoreTalentEdit from '../components/coretalents/CoreTalentEdit';
import CoreTalentDelete from '../components/coretalents/CoreTalentDelete';

const CoreTalentsRoutes = () => {
	return (
		<>
			<Route path="/kerntalenten" exact component={CoreTalentList} />
			<Route path="/kerntalenten/toevoegen" exact component={CoreTalentCreate} />
			<Route path="/kerntalenten/aanpassen/:id" exact component={CoreTalentEdit} />
			<Route path="/kerntalenten/verwijderen/:id" exact component={CoreTalentDelete} />
		</>
	);
};

export default CoreTalentsRoutes;
