import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { fetchQuestions } from '../../actions/questionsActions';
import QuestionItem from './QuestionItem';
import TableToolbar from '../TableToolbar';
import Loader from '../Loader';
import { CAN_ADD_QUESTIONS } from '../../permissions';

class QuestionList extends React.Component {
	state = {
		column: null,
		direction: null,
	};

	handleSort = clickedColumn => () => {
		const { column, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'ascending',
			});
			return;
		}

		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	componentDidMount() {
		this.props.fetchQuestions();
	}

	render() {
		if (!this.props.questions) return <Loader />;
		const { column, direction } = this.state;
		let questions = _.sortBy(this.props.questions, [column, 'question']);
		questions = direction === 'ascending' ? questions : questions.reverse();
		return (
			<>
				<TableToolbar
					rightButtonText="Nieuwe vraag"
					to="/vragen/toevoegen"
					permission={CAN_ADD_QUESTIONS}
				/>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'question' ? direction : null}
								onClick={this.handleSort('question')}
							>
								Vraag
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'coretalent.name' ? direction : null}
								onClick={this.handleSort('coretalent.name')}
							>
								KernTalent
							</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{questions.map(question => (
							<QuestionItem key={question._id} question={question} />
						))}
					</Table.Body>
				</Table>
			</>
		);
	}
}

const mapStateToProps = state => {
	const searchFilter = (question, value) =>
		question.question.toLowerCase().includes(value.toLowerCase()) ||
		question.coretalent.description.toLowerCase().includes(value.toLowerCase()) ||
		question.coretalent.code.toLowerCase().includes(value.toLowerCase());
	const questions = Object.values(state.questions).filter(question =>
		searchFilter(question, state.search.search ? state.search.search : ''),
	);
	return { questions };
};

export default connect(
	mapStateToProps,
	{ fetchQuestions: fetchQuestions },
)(QuestionList);
