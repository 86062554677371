import React from 'react';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

class WysiwygEditor extends React.Component {
	constructor(props) {
		super(props);
		let text = '';
		if (props.text) {
			text = props.text;
		}
		if (this.props.meta && this.props.meta.initial) {
			text = this.props.meta.initial;
		}

		this.state = {
			editorState: EditorState.createEmpty(),
			text: text,
		};
	}

	componentDidMount = async () => {
		let edState;
		if (this.state.text /*this.props.meta.initial*/) {
			try {
				edState = EditorState.createWithContent(
					ContentState.createFromBlockArray(
						convertFromHTML(this.state.text /*this.props.meta.initial*/),
					),
				);
				this.setState({
					editorState: edState,
				});
			} catch (err) {
				edState = EditorState.createEmpty();
			}
		}
	};

	onEditorStateChange = editorState => {
		this.setState({
			editorState,
		});
	};

	render() {
		const { editorState, text } = this.state;
		return (
			<Editor
				editorState={editorState}
				onEditorStateChange={this.onEditorStateChange}
				editorClassName="waw-editor"
				wrapperClassName="waw-editor-wrapper"
				toolbar={{
					options: ['inline', 'fontSize', 'list', 'link'],
				}}
				onChange={data => {
					const inTextArea = draftToHtml(convertToRaw(editorState.getCurrentContent()));
					this.setState({
						inTextArea,
					});
					if (this.props.input) this.props.input.onChange(inTextArea);
				}}
				value={text}
			/>
		);
	}
}

export default WysiwygEditor;
