import _ from 'lodash';
import { FETCH_ROLE, FETCH_ROLES, DELETE_ROLE, EDIT_ROLE, CREATE_ROLE } from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_ROLES:
			//useing _.mapKeys here, to create objects from questions array => easier to use
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_ROLE:
			return { ...state, [action.payload._id]: action.payload };
		case CREATE_ROLE:
			return { ...state, [action.payload._id]: action.payload };
		case EDIT_ROLE:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_ROLE:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
