import React from 'react';
import { Route } from 'react-router-dom';

import Overview from '../components/candidateOverview/Overview';
import OverviewCoreTalentDetail from '../components/candidateOverview/OverviewCoreTalentDetail';

const CandidateOverviewRoutes = () => {
	return (
		<>
			{/*<Route path="/kandidaat-overzicht" component={NotAvailable} />*/}
			<Route path="/kandidaat-overzicht/:candidateId/:reportId" exact component={Overview} />
			<Route path="/kandidaat-overzicht/:candidateId" exact component={Overview} />
			<Route
				path="/kandidaat-overzicht/:candidateId/:reportId/detail/:talentCode"
				exact
				component={OverviewCoreTalentDetail}
			/>
			<Route
				path="/kandidaat-overzicht/:candidateId/detail/:talentCode"
				exact
				component={OverviewCoreTalentDetail}
			/>
		</>
	);
};

export default CandidateOverviewRoutes;
