import { FETCH_TEXT, FETCH_TEXTS, CHANGE_TEXT } from './types';
import { settings } from '../apis/waw';
import history from '../history';

export const fetchText = text => async dispatch => {
	const response = await settings.get(`/${text}`);
	dispatch({
		type: FETCH_TEXT,
		payload: response.data,
	});
};

export const fetchTexts = () => async dispatch => {
	const response = await settings.get('/');
	dispatch({
		type: FETCH_TEXTS,
		payload: response.data,
	});
};

export const editText = (text, newText) => async dispatch => {
	const response = await settings.post('/', { text, newText });
	dispatch({
		type: CHANGE_TEXT,
		payload: response.data,
	});
	history.push('/voorkeuren');
};
