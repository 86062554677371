import React from 'react';
const images = require.context('../img', true);

class Icon extends React.Component {
	render() {
		let image, height, width;
		height = '1.5em';
		width = '1.5em';
		if (this.props.png) image = images(`./${this.props.icon}.png`);
		else {
			image = images(`./${this.props.icon}.svg`);
		}

		if (this.props.height) height = this.props.height;
		if (this.props.width) width = this.props.width;

		let style = {
			width: width,
			height: height,
			margin: '0 .5rem 0 0',
			backgroundImage: `url(${image})`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
		};
		return <div style={style} />;
	}
}

export default Icon;
