import React from 'react';
import { connect } from 'react-redux';
import { editCluster, fetchCluster } from '../../actions/clustersActions';
import { fetchCombinationsOfCluster } from '../../actions/combinationsActions';
import { fetchCoreTalents } from '../../actions/coretalentsActions';
import ClusterForm from './ClusterForm';
import Modal from '../Modal';
import Loader from '../Loader';
import history from '../../history';

class ClusterEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,
		};
	}

	componentDidMount() {
		Promise.all([
			this.props.fetchCluster(this.props.match.params.id),
			this.props.fetchCombinationsOfCluster(this.props.match.params.id),
			this.props.fetchCoreTalents(),
		]).then(() => this.setState({ ready: true }));
	}

	onSubmit = formValues => {
		this.props.editCluster(this.props.match.params.id, formValues);
	};

	renderForm = () => {
		const initialValues = {
			name: this.props.cluster.name,
			coreTalents: this.props.cluster.coreTalents.map(talent => talent._id),
		};

		if (!this.state.ready) return <Loader />;
		return (
			<>
				<ClusterForm
					onSubmit={this.onSubmit}
					initialValues={initialValues}
					coreTalents={this.props.coreTalents}
					allowEditCoreTalents={this.props.combinations.length === 0}
				/>
			</>
		);
	};

	render() {
		if (!this.props.cluster) {
			return <Loader />;
		}
		return (
			<>
				<Modal
					title="Cluster aanpassen"
					content={this.renderForm()}
					onDismiss={() => history.push('/combinaties')}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		cluster: state.clusters[ownProps.match.params.id],
		combinations: Object.values(state.combinations).filter(
			combination => combination.cluster === ownProps.match.params.id,
		),
		coreTalents: Object.values(state.coretalents),
	};
};

export default connect(
	mapStateToProps,
	{
		editCluster: editCluster,
		fetchCluster: fetchCluster,
		fetchCombinationsOfCluster: fetchCombinationsOfCluster,
		fetchCoreTalents: fetchCoreTalents,
	},
)(ClusterEdit);
