import React from 'react';
import { connect } from 'react-redux';
import { Menu, Icon, Segment } from 'semantic-ui-react';
import RoleList from './roles/RoleList';
import TextList from './settings/TextList';
import { CAN_VIEW_ROLES, CAN_VIEW_SETTINGS } from '../permissions';

const ITEM_ROLES = 'roles';
const ITEM_TEXTS = 'texts';

class Preferences extends React.Component {
	constructor(props) {
		super(props);
		this.state = { activeItem: ITEM_ROLES };
	}

	handleItemClick = (e, { name }) => this.setState({ activeItem: name });

	renderSegment = () => {
		switch (this.state.activeItem) {
			case ITEM_ROLES:
				return <RoleList />;
			case ITEM_TEXTS:
				return <TextList />;
			default:
				return <div />;
		}
	};

	renderTab = (item, title, activeItem, permission, iconName) => {
		if (this.checkPermission(permission)) {
			return (
				<Menu.Item name={item} active={activeItem === item} onClick={this.handleItemClick}>
					<Icon name={iconName} />
					{title}
				</Menu.Item>
			);
		}
	};

	render() {
		const { activeItem } = this.state;
		return (
			<>
				<Menu compact attached tabular icon="labeled">
					{this.renderTab(
						ITEM_ROLES,
						'Rollen & Rechten',
						activeItem,
						CAN_VIEW_ROLES,
						'users',
					)}
					{this.renderTab(ITEM_TEXTS, 'Teksten', activeItem, CAN_VIEW_SETTINGS, 'book')}
				</Menu>

				<Segment attached="bottom">{this.renderSegment()}</Segment>
			</>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}

const mapStateToProps = state => {
	return { auth: state.auth };
};

export default connect(
	mapStateToProps,
	null,
)(Preferences);
