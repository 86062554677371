import React from 'react';
import Modal from '../Modal';
import { connect } from 'react-redux';
import history from '../../history';
import QuestionForm from '../questions/QuestionForm';
import Loader from '../Loader';
import { createSubQuestion } from '../../actions/subquestionsActions';
import { fetchQuestion } from '../../actions/questionsActions';

class SubQuestionCreate extends React.Component {
	onSubmit = ({ coretalent, isTextarea, primaryQuestion, question, shortQuestion }) => {
		const createObject = {
			coretalent: coretalent,
			isTextarea: isTextarea ? isTextarea : false,
			primaryQuestion: primaryQuestion ? primaryQuestion : this.props.match.params.id,
			question: question ? question.trim() : '',
			shortQuestion: shortQuestion ? shortQuestion.trim() : ''
		}
		this.props.createSubQuestion(createObject);
	};

	componentDidMount = () => this.props.fetchQuestion(this.props.match.params.id);

	renderForm = () => {
		if (!this.props.primaryQuestion) return <Loader />;
		return (
			<div>
				<h3>Subvraag voor:</h3>
				<p>
					<b>"{this.props.primaryQuestion.question}"</b>
				</p>
				<QuestionForm onSubmit={this.onSubmit} />
			</div>
		);
	};

	render() {
		return (
			<>
				<Modal
					title="Subvraag toevoegen"
					content={this.renderForm()}
					onDismiss={() => history.push('/vragen')}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { primaryQuestion: state.questions[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ createSubQuestion: createSubQuestion, fetchQuestion: fetchQuestion },
)(SubQuestionCreate);
