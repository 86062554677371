import React from 'react';
import { connect } from 'react-redux';
import { fetchToken } from '../actions/authActions';
import logo from '../img/wawlogo.svg';
import { Container, Grid, Button, Input } from 'semantic-ui-react';
import { clearErrors } from '../actions/errorsActions';
import history from '../history';
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			token: '',
		};
	}

	componentDidMount = async () => {
		await this.props.clearErrors();
	};

	handleSubmit = async () => {
		const { username, password } = this.state;
		if (username && password) {
			await this.props.fetchToken(username, password);
			history.push('/');
		}
	};

	render() {
		const { errors } = this.props;
		const { username, password } = this.state;
		return (
			<Container className="waw-login">
				<img alt="logo" src={logo} />
				<h1>WaW-KernTalenten</h1>
				<Grid centered>
					{errors.error && (
						<Grid.Row className="waw-error">
							{errors.error ? errors.error : 'Er ging iets mis!'}
						</Grid.Row>
					)}
					<Grid.Row>
						<Input
							label="Gebruikersnaam"
							placeholder="E-mail adres"
							value={username}
							onChange={({ target }) =>
								this.setState({
									username: target.value,
								})
							}
						/>
					</Grid.Row>
					<Grid.Row>
						<Input
							label="Wachtwoord"
							type="password"
							placeholder="Password"
							value={password}
							onChange={({ target }) =>
								this.setState({
									password: target.value,
								})
							}
						/>
					</Grid.Row>
					<Grid.Row>
						<Button onClick={this.handleSubmit} primary>
							Login
						</Button>
					</Grid.Row>
				</Grid>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return { token: state.auth.token, errors: state.errors };
};

export default connect(mapStateToProps, { fetchToken, clearErrors })(Login);
