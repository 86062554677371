import React from 'react';
import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';

import CandidateLog from './CandidateLog';
import Util from '../../util';
import { fetchUsers } from '../../actions/usersActions';
import Loader from '../Loader';

const UNKNOWN = 'Onbekend';

class CandidateLogs extends React.Component {
	constructor(props) {
		super(props);
		this.util = new Util();
	}

	async componentDidMount() {
		if (!this.props.users) await this.props.fetchUsers();
	}

	renderLogs() {
		const logs = [];
		const candidate = this.props.candidate;
		if (candidate.createdAt) {
			const user = candidate.createdBy ? this.props.users[candidate.createdBy] : undefined;
			const username = user ? `${user.firstname} ${user.lastname}` : UNKNOWN;
			const text = `Aangemaakt op ${this.util.formatDate(
				candidate.createdAt,
			)} door ${username}`;
			logs.push(<CandidateLog key={'created_at'} text={text} icon="plus" />);
		}

		if (candidate.linkSentOn) {
			const user = candidate.linkSentBy ? this.props.users[candidate.linkSentBy] : undefined;
			const username = user ? `${user.firstname} ${user.lastname}` : UNKNOWN;
			const text = `Link verzonden op ${this.util.formatDate(candidate.linkSentOn)} door 
                    ${username}`;
			logs.push(<CandidateLog key={'link_sent_on'} text={text} icon="linkify" />);
		} else {
			logs.push(<CandidateLog key={'link_sent_on'} text='De link werd nog niet verzonden.' icon="unlink" />);
		}

		if (candidate.questionnaire) {
			if (candidate.questionnaire.startedOn) {
				const text = `Gestart met het invullen van de vragenlijst op
						${this.util.formatDate(candidate.questionnaire.startedOn)}`;
				logs.push(<CandidateLog key={'questionnaire'} text={text} icon="circle outline" />);
			}

			if (candidate.questionnaire.completedOn) {
				const text = `Vragenlijst afgewerkt op
                        ${this.util.formatDate(candidate.questionnaire.completedOn)}`;
				logs.push(<CandidateLog key={'questionnaire_completed_on'} text={text} icon="check circle outline" />);
			}

			if (candidate.questionnaire.validatedOn) {
				const user = candidate.questionnaire.validatedBy
					? this.props.users[candidate.questionnaire.validatedBy]
					: undefined;
				const username = user ? `${user.firstname} ${user.lastname}` : UNKNOWN;
				const text = `Vragenlijst werd vrijgegeven op
                        ${this.util.formatDate(
					candidate.questionnaire.validatedOn,
				)} door ${username}`;
				logs.push(<CandidateLog key={'questionnaire_validatedOn'} text={text} icon="check" />);
			}
		} else {
			logs.push(
				<CandidateLog text={'Vragenlijst nog niet ingevuld.'} key={'not_filled_out'} icon="exclamation circle" />,
			);
		}

		if (candidate.sneakPreviewSentOn) {
			const user = candidate.sneakPreviewSentBy
				? this.props.users[candidate.sneakPreviewSentBy]
				: undefined;
			const username = user ? `${user.firstname} ${user.lastname}` : UNKNOWN;
			const text = `Sneak Preview werd verzonden op ${this.util.formatDate(
				candidate.sneakPreviewSentOn,
			)} door ${username}`;
			logs.push(<CandidateLog key={'sneak_preview_sent'} text={text} icon="eye" />);
		}

		if (candidate.resultLinkSentOn) {
			const user = candidate.resultLinkSentBy
				? this.props.users[candidate.resultLinkSentBy]
				: undefined;
			const username = user ? `${user.firstname} ${user.lastname}` : UNKNOWN;
			const text = `Resultaten werden verzonden op
                    ${this.util.formatDate(candidate.resultLinkSentOn)} door ${username}`;
			logs.push(<CandidateLog key={'result_link_sent_on'} text={text} icon="envelope outline" />);
		}

		return logs;
	}

	render = () => !this.props.users ? <Loader /> : <List>{this.renderLogs()}</List>;
}

const mapStateToProps = state => {
	return { users: state.users };
};

export default connect(
	mapStateToProps,
	{
		fetchUsers,
	},
)(CandidateLogs);
