import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { createReport } from '../../actions/reportsActions';
import { fetchCandidates } from '../../actions/candidatesActions';
import { fetchClusters } from '../../actions/clustersActions';
import { fetchUsers } from '../../actions/usersActions';
import { Step, Form, Button } from 'semantic-ui-react';
import { reduxForm } from 'redux-form';

import Step1 from './steps/ReportStep_1';
import Step2 from './steps/ReportStep_2';
import Step3 from './steps/ReportStep_3';
import Loader from '../Loader';

class ReportCreate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			analistId: undefined,
			candidateId: undefined,
			steps: [
				{
					key: 0,
					title: 'Kandidaat',
					description: 'Selecteer een kandidaat',
					active: true,
					completed: false,
				},
				{
					key: 1,
					title: 'Clusters',
					description: 'Selecteer clusters',
					active: false,
					completed: false,
				},
				{
					key: 2,
					title: 'Bevestig aanmaken',
					active: false,
					completed: false,
				},
			],
			step: 0,
			candidateError: '',
		};
	}

	onChangeReportName = text => {
		this.props.change('name', text);
	};

	onChangeEnding = text => {
		this.props.change('ending', text);
	};

	previous = () => {
		const current = this.state.step;
		if (current === 2) {
			let r = window.confirm(
				'Indien je teruggaat zal de persoonlijke afsluiter overschreven worden.',
			);
			if (!r) {
				return;
			}
		}
		const currentStep = this.state.steps[current];
		const previousStep = this.state.steps[current - 1];
		currentStep.active = false;
		previousStep.active = true;
		this.setState({
			step: current - 1,
			steps: this.state.steps,
		});
	};

	next = () => {
		const current = this.state.step;
		const currentStep = this.state.steps[current];
		const nextStep = this.state.steps[current + 1];
		currentStep.active = false;
		currentStep.completed = true;
		nextStep.active = true;
		this.setState({
			step: current + 1,
			steps: this.state.steps,
			analistId: this.props.formValues.values.analist._id,
			candidateId: this.props.formValues.values.candidate._id,
		});
	};

	onSubmit = formValues => {
		let clusters = [];
		formValues.clusters.forEach(clusterId => {
			clusters.push(
				this.props.fullClusters.find(c => {
					return c._id === clusterId;
				}),
			);
		});
		this.props.createReport(formValues);
	};

	checkIfValid = () => {
		if (this.state.step === 0 && this.props.formValues) {
			if (
				this.props.formValues.values.analist._id &&
				this.props.formValues.values.candidate._id
			) {
				return true;
			}
		} else if (this.state.step === 1 && this.props.formValues) {
			if (
				this.props.formValues.values.clusters !== undefined &&
				this.props.formValues.values.clusters.length > 0
			) {
				return true;
			}
		} else if (this.state.step === 2 && this.props.formValues) {
			if (
				(this.props.formValues.values.ending !== undefined &&
					this.props.formValues.values.ending !== '' &&
					this.props.formValues.values.name !== undefined) ||
				this.props.formValues.values.name !== ''
			) {
				return true;
			}
		} else {
			return false;
		}
	};

	renderForm() {
		switch (this.state.step) {
			case 0:
				return <Step1 candidateId={this.props.match.params.id} />;
			case 1:
				return <Step2 />;
			case 2:
				if (!this.state.analistId || !this.state.candidateId) return <Loader />;
				return (
					<Step3
						candidateId={this.state.candidateId}
						analistId={this.state.analistId}
						onChangeReportName={this.onChangeReportName}
						onChangeEnding={this.onChangeEnding}
					/>
				);
			default:
		}
	}

	render() {
		let valid = this.checkIfValid();
		return (
			<div className="waw-padding">
				<Step.Group items={this.state.steps} fluid ordered />
				<Form className="ui form">
					<div className="waw-error">{this.props.error}</div>
					{this.renderForm()}
					<div className="waw-flex waw-between">
						{this.state.step !== 0 ? (
							<Button
								secondary
								className="button ui secondary"
								onClick={this.previous}
							>
								Ga terug
							</Button>
						) : (
							''
						)}
						{this.state.step !== 2 ? (
							<Button primary onClick={this.next} disabled={!valid}>
								Volgende
							</Button>
						) : (
							''
						)}
						{this.state.step === 2 ? (
							<button
								className="button ui primary"
								onClick={this.props.handleSubmit(this.onSubmit)}
								disabled={!valid}
							>
								Aanmaken
							</button>
						) : (
							''
						)}
					</div>
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		candidates: Object.values(state.candidates).map(candidate => ({
			title: candidate.firstname + ' ' + candidate.lastname,
			firstname: candidate.firstname,
			lastname: candidate.lastname,
			id: candidate._id,
			scores: candidate.coreTalentsScores,
		})),
		fullClusters: Object.values(state.clusters),
		analists: Object.values(state.users).filter(
			user => user.role.canValidateTestResults === true,
		),
		formValues: state.form.reportForm,
		auth: state.auth,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{ change, createReport, fetchCandidates, fetchClusters, fetchUsers },
		dispatch,
	);
};

ReportCreate = reduxForm({
	form: 'reportForm',
	enableReinitialize: true,
})(ReportCreate);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ReportCreate);
