import { combineReducers } from 'redux';
import questionReducer from './questionReducer';
import candidatesReducer from './candidatesReducer';
import coretalentsReducer from './coretalentsReducer';
import subQuestionsReducer from './subquestionsReducer';
import usersReducer from './usersReducer';
import rolesReducer from './rolesReducer';
import questionnairesReducer from './questionnairesReducer';
import answersReducer from './answersReducer';
import ongoingQuestionnaire from './ongoingQuestionnaireReducer';
import clustersReducer from './clustersReducer';
import combinationsReducer from './combinationsReducer';
import reportsReducer from './reportsReducer';
import formReducer from './formReducer';
import searchReducer from './searchReducer';
import authReducer from './authReducer';
import errorsReducer from './errorsReducer';
import settingsReducer from './settingsReducer';

export default combineReducers({
	questions: questionReducer,
	subquestions: subQuestionsReducer,
	candidates: candidatesReducer,
	coretalents: coretalentsReducer,
	users: usersReducer,
	roles: rolesReducer,
	questionnaires: questionnairesReducer,
	answers: answersReducer,
	ongoingQuestionnaire: ongoingQuestionnaire,
	clusters: clustersReducer,
	combinations: combinationsReducer,
	reports: reportsReducer,
	form: formReducer,
	search: searchReducer,
	auth: authReducer,
	errors: errorsReducer,
	settings: settingsReducer,
});
