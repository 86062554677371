import React from 'react';
import { connect } from 'react-redux';

//import Loader from "../Loader";
import Modal from '../Modal';
import { createCoreTalent } from '../../actions/coretalentsActions';
import CoreTalentForm from './CoreTalentForm';
import history from '../../history';

class CoreTalentCreate extends React.Component {
	onSubmit = formValues => {
		this.props.createCoreTalent(formValues);
	};

	renderForm = () => {
		return <CoreTalentForm onSubmit={this.onSubmit} />;
	};

	render() {
		return (
			<Modal
				title="Nieuw KernTalent"
				content={this.renderForm()}
				onDismiss={() => history.push('/kerntalenten')}
			/>
		);
	}
}

export default connect(null, { createCoreTalent: createCoreTalent })(CoreTalentCreate);
