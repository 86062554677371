const CAN_VIEW_CANDIDATES = "canViewCandidates";
const CAN_ADD_CANDIDATES = "canAddCandidates";
const CAN_EDIT_CANDIDATES = "canEditCandidates";
const CAN_DELETE_CANDIDATES = "canDeleteCandidates";

const CAN_VIEW_CORETALENTS = "canViewCoreTalents";
const CAN_ADD_CORETALENTS = "canAddCoreTalents";
const CAN_EDIT_CORETALENTS = "canEditCoreTalents";
const CAN_DELETE_CORETALENTS = "canDeleteCoreTalents";

const CAN_VIEW_COMBINATIONS = "canViewCombinations";
const CAN_ADD_COMBINATIONS = "canAddCombinations";
const CAN_EDIT_COMBINATIONS = "canEditCombinations";
const CAN_DELETE_COMBINATIONS = "canDeleteCombinations";

const CAN_VIEW_QUESTIONS = "canViewQuestions";
const CAN_ADD_QUESTIONS = "canAddQuestions";
const CAN_EDIT_QUESTIONS = "canEditQuestions";
const CAN_DELETE_QUESTIONS = "canDeleteQuestions";

const CAN_VIEW_REPORTS = "canViewReports";
const CAN_ADD_REPORTS = "canAddReports";
const CAN_EDIT_REPORTS = "canEditReports";
const CAN_DELETE_REPORTS = "canDeleteReports";

const CAN_VIEW_USERS = "canViewUsers";
const CAN_ADD_USERS = "canAddUsers";
const CAN_EDIT_USERS = "canEditUsers";
const CAN_DELETE_USERS = "canDeleteUsers";

const CAN_VIEW_ROLES = "canViewRoles";
const CAN_ADD_ROLES = "canAddRoles";
const CAN_EDIT_ROLES = "canEditRoles";
const CAN_DELETE_ROLES = "canDeleteRoles";

const CAN_VIEW_SETTINGS = "canViewSettings";
const CAN_EDIT_SETTINGS = "canEditSettings";

const CAN_SEND_EMAILS = "canSendEmails";
const CAN_VALIDATE_QUESTIONNAIRES = "canValidateTestResults";

module.exports = {
  CAN_VIEW_CANDIDATES,
  CAN_ADD_CANDIDATES,
  CAN_EDIT_CANDIDATES,
  CAN_DELETE_CANDIDATES,
  CAN_VIEW_CORETALENTS,
  CAN_ADD_CORETALENTS,
  CAN_EDIT_CORETALENTS,
  CAN_DELETE_CORETALENTS,
  CAN_VIEW_COMBINATIONS,
  CAN_ADD_COMBINATIONS,
  CAN_EDIT_COMBINATIONS,
  CAN_DELETE_COMBINATIONS,
  CAN_VIEW_QUESTIONS,
  CAN_ADD_QUESTIONS,
  CAN_EDIT_QUESTIONS,
  CAN_DELETE_QUESTIONS,
  CAN_VIEW_REPORTS,
  CAN_ADD_REPORTS,
  CAN_EDIT_REPORTS,
  CAN_DELETE_REPORTS,
  CAN_VIEW_USERS,
  CAN_ADD_USERS,
  CAN_EDIT_USERS,
  CAN_DELETE_USERS,
  CAN_VIEW_ROLES,
  CAN_ADD_ROLES,
  CAN_EDIT_ROLES,
  CAN_DELETE_ROLES,
  CAN_VIEW_SETTINGS,
  CAN_EDIT_SETTINGS,
  CAN_SEND_EMAILS,
  CAN_VALIDATE_QUESTIONNAIRES
};
