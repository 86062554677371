import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import Icon from '../Icon';
import { CAN_DELETE_CORETALENTS, CAN_EDIT_CORETALENTS } from '../../permissions';

class CoreTalentItem extends React.Component {
	renderDeleteAction = coretalent => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_DELETE_CORETALENTS)) {
			return (
				<Link to={`/kerntalenten/verwijderen/${coretalent._id}`}>
					<Icon icon="delete" />
				</Link>
			);
		}
	};

	renderEditAction = coretalent => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_EDIT_CORETALENTS)) {
			return (
				<Link to={`/kerntalenten/aanpassen/${coretalent._id}`}>
					<Icon icon="edit" />
				</Link>
			);
		}
	};

	render() {
		const coretalent = this.props.coretalent;
		return (
			<Table.Row>
				<Table.Cell>{coretalent.code}</Table.Cell>
				<Table.Cell>{coretalent.name}</Table.Cell>
				<Table.Cell>{coretalent.description}</Table.Cell>
				<Table.Cell>{coretalent.includeInReport ? 'Ja' : 'Nee'}</Table.Cell>
				<Table.Cell>{coretalent.keyword}</Table.Cell>
				<Table.Cell collapsing>
					<div className="waw-table-actions">
						{this.renderEditAction(coretalent)}
						{coretalent.code === 'KT14' || coretalent.code === 'KT15'
							? null
							: this.renderDeleteAction(coretalent)}
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}
const mapStateToProps = state => {
	return { auth: state.auth };
};

export default connect(
	mapStateToProps,
	null,
)(CoreTalentItem);
