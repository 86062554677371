//Question actions
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_QUESTION = 'FETCH_QUESTION';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';

//Subquestion actions
export const FETCH_SUB_QUESTIONS = 'FETCH_SUB_QUESTIONS';
export const FETCH_SUB_QUESTION = 'FETCH_SUB_QUESTION';
export const CREATE_SUB_QUESTION = 'CREATE_SUB_QUESTION';
export const EDIT_SUB_QUESTION = 'EDIT_SUB_QUESTION';
export const DELETE_SUB_QUESTION = 'DELETE_SUB_QUESTION';
export const FETCH_SUB_QUESTIONS_FOR_PRIMARY = 'FETCH_SUB_QUESTIONS_FOR_PRIMARY';

//Candidates actions
export const FETCH_CANDIDATES = 'FETCH_CANDIDATES';
export const FETCH_CANDIDATE = 'FETCH_CANDIDATE';
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE';
export const EDIT_CANDIDATE = 'EDIT_CANDIDATE';
export const CREATE_CANDIDATE = 'CREATE_CANDIDATE';
export const ADJUST_SCORES = 'ADJUST_SCORES';

//Coretalents actions
export const FETCH_CORETALENTS = 'FETCH_CORETALENTS';
export const FETCH_CORETALENT = 'FETCH_CORETALENT';
export const CREATE_CORETALENT = 'CREATE_CORETALENT';
export const EDIT_CORETALENT = 'EDIT_CORETALENT';
export const DELETE_CORETALENT = 'DELETE_CORETALENT';

//User actions
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_PASSWORD = 'EDIT_USER_PASSWORD';

//Role actions
export const CREATE_ROLE = 'CREATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const EDIT_ROLE = 'EDIT_ROLE';
export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLES = 'FETCH_ROLES';

//Questionnaire actions (EDIT and CREATE should happen via other ways)
export const DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE';
export const FETCH_QUESTIONNAIRE = 'FETCH_QUESTIONNAIRE';
export const FETCH_QUESTIONNAIRES = 'FETCH_QUESTIONNAIRES';
export const VALIDATE_QUESTIONNAIRE = 'VALIDATE_QUESTIONNAIRE';

//Ongoing questionnaire
export const COMPLETE_QUESTIONNAIRE = 'COMPLETE_QUESTIONNAIRE';
export const START_QUESTIONNAIRE = 'START_QUESTIONNAIRE';
export const FETCH_STARTED_QUESTIONNAIRE = 'FETCH_STARTED_QUESTIONNAIRE';
export const FETCH_NEXT_QUESTION = 'FETCH_NEXT_QUESTION';
export const FETCH_PREVIOUS_QUESTION = 'FETCH_PREVIOUS_QUESTION';
export const ADD_ANSWER = 'ADD_ANSWER';
export const ADD_DESCRIPTION = 'ADD_DESCRIPTION';
export const END_QUESTIONNAIRE = 'END_QUESTIONNAIRE';

//Cluster actions
export const FETCH_CLUSTERS = 'FETCH_CLUSTERS';
export const FETCH_CLUSTER = 'FETCH_CLUSTER';
export const DELETE_CLUSTER = 'DELETE_CLUSTER';
export const EDIT_CLUSTER = 'EDIT_CLUSTER';
export const CREATE_CLUSTER = 'CREATE_CLUSTER';

//Combination actions
export const FETCH_COMBINATIONS_OF_CLUSTER = 'FETCH_COMBINATION_OF_CLUSTER';
export const FETCH_COMBINATION = 'FETCH_COMBINATION';
export const DELETE_COMBINATION = 'DELETE_COMBINATION';
export const EDIT_COMBINATION = 'EDIT_COMBINATION';
export const CREATE_COMBINATION = 'CREATE_COMBINATION';

//Report actions
export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const DELETE_REPORT = 'DELETE_REPORT';
export const CREATE_REPORT = 'CREATE_REPORT';
export const FETCH_CANDIDATE_REPORTS = 'FETCH_CANDIDATE_REPORTS';

// Errors
export const FORM_ACTION_FAILED = 'FORM_ACTION_FAILED';
export const CANDIDATE_NOT_FOUND = 'CANDIDATE_NOT_FOUND';
export const QUESTIONNAIRE_ERROR = 'QUESTIONNAIRE_ERROR';

//Search actions
export const SEARCH = 'SEARCH';

//AUTH
export const FETCH_TOKEN = 'FETCH_TOKEN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

//Settings
export const CHANGE_TEXT = 'CHANGE_TEXT';
export const FETCH_TEXT = 'FECTH_TEXT';
export const FETCH_TEXTS = 'FECTH_TEXTS';
