import React from 'react';
import coreTalentsLogo from '../../img/coretalents_logo.png';

export default function CoreTalentsDisclaimer() {
	return (
		<div
			style={{ margin: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
		>
			<div>
				<img width={170} src={coreTalentsLogo} alt={'CoreTalents logo'} />
			</div>
			<div style={{ fontSize: 15 }}>- Daniëlle Krekels &#169;</div>
		</div>
	);
}
