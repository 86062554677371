import {
	FETCH_COMBINATIONS_OF_CLUSTER,
	FETCH_COMBINATION,
	DELETE_COMBINATION,
	EDIT_COMBINATION,
	CREATE_COMBINATION,
	FORM_ACTION_FAILED,
} from './types';
import { combinations, clusters } from '../apis/waw';
import history from '../history';

export const fetchCombinationsOfCluster = id => async dispatch => {
	const response = await clusters.get(`/${id}/combinations`);
	dispatch({
		type: FETCH_COMBINATIONS_OF_CLUSTER,
		payload: response.data,
	});
};

export const fetchCombination = id => async dispatch => {
	const response = await combinations.get(`/${id}`);
	dispatch({
		type: FETCH_COMBINATION,
		payload: response.data,
	});
};

export const editCombination = (id, formValues) => async dispatch => {
	try {
		const response = await combinations.put(`/${id}`, { ...formValues });
		dispatch({
			type: EDIT_COMBINATION,
			payload: response.data,
		});
		history.push('/combinaties');
	} catch (err) {
		dispatch({
			type: FORM_ACTION_FAILED,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const createCombination = formValues => async dispatch => {
	try {
		const response = await combinations.post('/', { ...formValues });
		dispatch({
			type: CREATE_COMBINATION,
			payload: response.data,
		});
		history.push('/combinaties');
	} catch (err) {
		dispatch({
			type: FORM_ACTION_FAILED,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const deleteCombination = id => async dispatch => {
	await combinations.delete(`/${id}`);
	dispatch({
		type: DELETE_COMBINATION,
		payload: id,
	});
	history.push('/combinaties');
};
