import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchRoles } from '../../actions/rolesActions';
import { Table } from 'semantic-ui-react';

import RoleItem from './RoleItem';
import { CAN_ADD_ROLES, CAN_VIEW_ROLES } from '../../permissions';
import Loader from '../Loader';

class RoleList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			column: null,
			direction: null,
		};
	}

	componentDidMount() {
		this.props.fetchRoles();
	}

	handleSort = () => {};

	renderRoles = () => {
		const roles = this.props.roles;
		if (roles.length === 0) {
			return (
				<Table.Row>
					<Table.Cell colSpan="3">Nog geen rollen gedefinieerd</Table.Cell>
				</Table.Row>
			);
		} else {
			return roles.map(role => <RoleItem key={role._id} role={role} />);
		}
	};

	renderAddAction = () => {
		if (this.checkPermission(CAN_ADD_ROLES)) {
			return (
				<Link className="ui button basic" to="/rollen/toevoegen">
					<i className="plus icon" />
					Nieuwe Rol
				</Link>
			);
		}
		return null;
	};

	render() {
		if (!this.checkPermission(CAN_VIEW_ROLES)) {
			return null;
		}
		if (!this.props.roles) return <Loader />;

		const { column, direction } = this.state;

		return (
			<>
				{this.renderAddAction()}
				<hr />
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'name' ? direction : null}
								onClick={this.handleSort('name')}
							>
								Rol
							</Table.HeaderCell>
							<Table.HeaderCell>Rechten</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>{this.renderRoles()}</Table.Body>
				</Table>
			</>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}

const mapStateToProps = state => {
	return { roles: Object.values(state.roles), auth: state.auth };
};

export default connect(
	mapStateToProps,
	{ fetchRoles: fetchRoles },
)(RoleList);
