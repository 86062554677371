import React from 'react';
import { connect } from 'react-redux';
import CandidateForm from './CandidateForm';
import history from '../../history';
import { createCandidate } from '../../actions/candidatesActions';
import Modal from '../Modal';

class CandidateCreate extends React.Component {
	onSubmit = formValues => {
		this.props.createCandidate(formValues);
	};

	renderForm() {
		return <CandidateForm onSubmit={this.onSubmit} />;
	}

	render() {
		return (
			<Modal
				title="Nieuwe kandidaat"
				onDismiss={() => history.push('/kandidaten')}
				content={this.renderForm()}
			/>
		);
	}
}

export default connect(
	null,
	{ createCandidate: createCandidate },
)(CandidateCreate);
