import _ from "lodash";
import {
  FETCH_REPORTS,
  FETCH_REPORT,
  DELETE_REPORT,
  CREATE_REPORT,
  FETCH_CANDIDATE_REPORTS
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_REPORTS:
      //useing _.mapKeys here, to create objects from questions array => easier to use
      return { ...state, ..._.mapKeys(action.payload, "_id") };
    case FETCH_CANDIDATE_REPORTS:
      return { ...state, ..._.mapKeys(action.payload, "_id") };
    case FETCH_REPORT:
      return { ...state, [action.payload._id]: action.payload };
    case CREATE_REPORT:
      return { ...state, [action.payload._id]: action.payload };
    case DELETE_REPORT:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
