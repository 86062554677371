import _ from 'lodash';
import {
	FETCH_CANDIDATES,
	FETCH_CANDIDATE,
	DELETE_CANDIDATE,
	EDIT_CANDIDATE,
	CREATE_CANDIDATE,
	ADJUST_SCORES,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_CANDIDATES:
			//useing _.mapKeys here, to create objects from questions array => easier to use
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_CANDIDATE:
			return { ...state, [action.payload._id]: action.payload };
		case EDIT_CANDIDATE:
			return { ...state, [action.payload._id]: action.payload };
		case CREATE_CANDIDATE:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_CANDIDATE:
			return _.omit(state, action.payload);
		case ADJUST_SCORES:
			return { ...state, [action.payload._id]: action.payload };
		default:
			return state;
	}
};
