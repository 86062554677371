import React from 'react';
import { connect } from 'react-redux';

import Modal from '../Modal';
import history from '../../history';

class OverviewCoreTalentDetail extends React.Component {
	render() {
		let coreTalent;
		if (this.props.match.params.talentCode) {
			coreTalent = this.props.coretalents.find(coretalent => {
				return this.props.match.params.talentCode === coretalent.code;
			});
		}
		if (!coreTalent) {
			return (
				<Modal
					title="Er ging iets fout!"
					content="We konden deze actie niet uitvoeren, onze excuses!"
					onDismiss={() => {
						const candidateId = this.props.match.params.candidateId;
						const reportId = this.props.match.params.reportId;
						if (reportId) {
							history.push(`/kandidaat-overzicht/${candidateId}/${reportId}`);
						} else {
							history.push(`/kandidaat-overzicht/${candidateId}`);
						}
					}}
				/>
			);
		} else {
			return (
				<Modal
					title={coreTalent.name}
					content={coreTalent.description}
					onDismiss={() => {
						const candidateId = this.props.match.params.candidateId;
						const reportId = this.props.match.params.reportId;
						if (reportId) {
							history.push(`/kandidaat-overzicht/${candidateId}/${reportId}`);
						} else {
							history.push(`/kandidaat-overzicht/${candidateId}`);
						}
					}}
				/>
			);
		}
	}
}

const mapStateToProps = state => {
	return { coretalents: Object.values(state.coretalents) };
};

export default connect(mapStateToProps)(OverviewCoreTalentDetail);
