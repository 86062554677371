import React from 'react';
import { connect } from 'react-redux';
import { Grid, Responsive, Container } from 'semantic-ui-react';
import Slider from 'react-rangeslider';
import { fetchSubQuestionsForPrimaryQuestion } from '../../actions/subquestionsActions';
import { addAnswer } from '../../actions/ongoingQuestionnaireActions';
import Loader from '../Loader';
import '../../css/slider.css';
import { fetchStartedQuestionnaire } from '../../actions/ongoingQuestionnaireActions';

class Question extends React.Component {
	constructor(props) {
		super(props);

		const sliders = new Map();
		const checkBoxes = new Map();

		this.state = {
			sliders: sliders,
			checkBoxes: checkBoxes,
			questionnaireId: this.props.ongoingQuestionnaire._id,
		};
	}

	async componentDidMount() {
		const { questionnaire, ongoingQuestionnaire, subquestions, candidate, question } = this.props;
		let localQuestionnaire = null;
		if (!ongoingQuestionnaire || ongoingQuestionnaire === undefined) {
			await this.props.fetchStartedQuestionnaire(candidate.questionnaire._id);
			localQuestionnaire = questionnaire;
		} else {
			localQuestionnaire = ongoingQuestionnaire;
		}

		await this.props.fetchSubQuestionsForPrimaryQuestion(question._id);
		const sliders = new Map();
		const checkBoxes = new Map();

		const localSubquestions = subquestions;
		for (let i = 0; i < localSubquestions.length; i++) {
			let previousAnswer;
			if (localQuestionnaire.answeredQuestions) {
				previousAnswer = localQuestionnaire.answeredQuestions.find(answeredQuestion => {
					return answeredQuestion.subQuestionId === localSubquestions[i]._id;
				});
			}

			const initialSliderValue = previousAnswer ? previousAnswer.score : 50;
			const initialCheckboxValue = previousAnswer ? previousAnswer.neverDoneThat : false;

			sliders.set(localSubquestions[i]._id, initialSliderValue);
			checkBoxes.set(localSubquestions[i]._id, initialCheckboxValue);
		}
		let previousAnswer;
		if (localQuestionnaire.answeredQuestions) {
			previousAnswer = localQuestionnaire.answeredQuestions.find(answeredQuestion => {
				return answeredQuestion.primaryQuestionId === question._id;
			});
		}

		const initialSliderValue = previousAnswer ? previousAnswer.score : 50;
		const initialCheckboxValue = previousAnswer ? previousAnswer.neverDoneThat : false;

		sliders.set(question._id, initialSliderValue);
		checkBoxes.set(question._id, initialCheckboxValue);

		this.setState({
			sliders: sliders,
			checkBoxes: checkBoxes,
		});
	}

	handleSlide(value, id) {
		const { sliders, checkBoxes } = this.state;
		let localSliders = sliders;
		localSliders.set(id, value);
		//When the slider is moved, the 'neverDoneThat' checkbox should be set to false again
		let localCheckBoxes = checkBoxes;
		localCheckBoxes.set(id, false);
		this.setState({
			sliders: localSliders,
			checkBoxes: localCheckBoxes,
		});
	}

	handleCheck(event, id) {
		let cbs = this.state.checkBoxes;
		cbs.set(id, event.target.checked);
		this.setState({
			checkBoxes: cbs,
		});
	}

	postAnswer = async questionnaireId => {
		const { question, subquestions } = this.props;
		let answer = {
			primaryQuestionId: question._id,
			primaryQuestion: question.question,
			primaryQuestionShortQuestion: question.shortQuestion,
			score: '',
			coreTalentCode: '',
			coreTalentName: '',
			answers: []
		};

		if (subquestions.length > 0) {
			subquestions.forEach(sq => {
				const neverDoneThat = this.state.checkBoxes.get(sq._id);
				let score = 0;
				if (!neverDoneThat) {
					score = this.state.sliders.get(sq._id);
				}
				const obj = {
					subQuestionId: sq._id,
					question: sq.question,
					shortQuestion: sq.shortQuestion,
					coreTalentCode: sq.coretalent.code,
					coreTalentName: sq.coretalent.name,
					neverDoneThat: neverDoneThat,
					score: score,
				};

				answer.answers.push(obj);
			});
		} else {
			const localQuestion = question;
			const neverDoneThat = this.state.checkBoxes.get(localQuestion._id);
			//Used to be just 'let score;' .
			let score = 0;
			if (!neverDoneThat) {
				score = this.state.sliders.get(localQuestion._id);
			}

			const obj = {
				question: localQuestion.question,
				coreTalentCode: localQuestion.coretalent.code,
				coreTalentName: localQuestion.coretalent.name,
				neverDoneThat: neverDoneThat,
				score: score,
			};

			answer.answers.push(obj);
		}

		await this.props.addAnswer(questionnaireId, answer);
	};

	renderSubquestions() {
		let i = 0;
		return this.props.subquestions.map(sq => {
			let c = i % 2 === 0 ? 'even' : 'odd';
			i++;
			return (
				<div className={c} key={sq._id}>
					<div className="subquestion">
						<Responsive as={Grid} minWidth={700}>
							<Grid.Column width={7}>
								<p>{sq.question}</p>
							</Grid.Column>
							<Grid.Column width={7}>
								<Slider
									min={0}
									max={100}
									labels={{
										0: 'Helemaal niet leuk',
										50: 'Nogal leuk',
										90: 'Super leuk',
									}}
									value={this.state.sliders.get(sq._id)}
									onChange={value => {
										this.handleSlide(value, sq._id);
									}}
								/>
								<div className="sliderTick sliderLeftTick" />
								<div className="sliderTick sliderRightTick" />
							</Grid.Column>
							<Grid.Column width={2} floated="right">
								<div className="neverDoneThat-cb">
									<input
										name="neverDoneThat"
										type="checkbox"
										value={this.state.checkBoxes.get(sq._id)}
										onChange={e => this.handleCheck(e, sq._id)}
										checked={this.state.checkBoxes.get(sq._id)}
									/>
									<label htmlFor="neverDoneThat">Niet van toepassing</label>
								</div>
								<div className="clear" />
							</Grid.Column>
						</Responsive>
						{/** Mobile */}
						<Responsive as={Grid} maxWidth={700} width={16}>
							<Grid.Row>
								<p id="question">{sq.question}</p>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16}>
									<Slider
										min={0}
										max={100}
										labels={{
											0: 'Helemaal niet leuk',
											50: 'Nogal leuk',
											90: 'Super leuk',
										}}
										value={this.state.sliders.get(sq._id)}
										onChange={value => {
											this.handleSlide(value, sq._id);
										}}
									/>
									<div className="sliderTick sliderLeftTick" />
									<div className="sliderTick sliderRightTick" />
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<div className="neverDoneThat-cb">
									<input
										name="neverDoneThat"
										type="checkbox"
										value={this.state.checkBoxes.get(sq._id)}
										onChange={e => this.handleCheck(e, sq._id)}
										checked={this.state.checkBoxes.get(sq._id)}
									/>
									<label htmlFor="neverDoneThat">Niet van toepassing</label>
								</div>
								<div className="clear" />
							</Grid.Row>
						</Responsive>
					</div>
				</div>
			);
		});
	}

	renderStandardQuestion = () => {
		let q = this.props.question;
		return (
			<div className="subquestion">
				<Responsive as={Grid} minWidth={700} className="singleQuestion">
					<Grid.Row>
						<Grid.Column>
							<div className="sliderTicks" />
							<Slider
								min={0}
								max={100}
								labels={{
									0: 'Helemaal niet leuk',
									50: 'Nogal leuk',
									96: 'Super leuk',
								}}
								value={this.state.sliders.get(q._id)}
								onChange={value => {
									this.handleSlide(value, q._id);
								}}
							/>
							<div className="sliderTick sliderLeftTick" />
							<div className="sliderTick sliderRightTick" />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<div className="neverDoneThat-cb">
							<input
								name="neverDoneThat"
								type="checkbox"
								value={this.state.checkBoxes.get(q._id)}
								onChange={e => this.handleCheck(e, q._id)}
								checked={this.state.checkBoxes.get(q._id)}
							/>
							<label htmlFor="neverDoneThat"> Niet van toepassing</label>
						</div>
					</Grid.Row>
				</Responsive>
				<Responsive as={Grid} maxWidth={700} className="singleQuestion">
					<Grid.Row>
						<Grid.Column>
							<div className="sliderTicks" />
							<Slider
								min={0}
								max={100}
								value={this.state.sliders.get(q._id)}
								onChange={value => {
									this.handleSlide(value, q._id);
								}}
							/>
							<div className="sliderTick sliderLeftTick" />
							<div className="sliderTick sliderRightTick" />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<div className="neverDoneThat-cb">
							<input
								name="neverDoneThat"
								type="checkbox"
								value={this.state.checkBoxes.get(q._id)}
								onChange={e => this.handleCheck(e, q._id)}
								checked={this.state.checkBoxes.get(q._id)}
							/>
							<label htmlFor="neverDoneThat"> Niet van toepassing</label>
						</div>
					</Grid.Row>
				</Responsive>
			</div>
		);
	};

	adjustForAmountOfSubquestions() {
		if (this.props.question.subQuestions.length !== 0) {
			return this.renderSubquestions();
		} else {
			return this.renderStandardQuestion();
		}
	}

	render() {
		if (!this.props.candidate) return <Loader />;
		if (!this.props.question.subQuestions) return <Loader />;
		return (
			<>
				<Container fluid>{this.adjustForAmountOfSubquestions()}</Container>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let subquestions = [];
	ownProps.question.subQuestions.forEach(sq => {
		if (state.subquestions[sq]) {
			subquestions.push(state.subquestions[sq]);
		}
	});

	return {
		candidate: state.candidates[ownProps.candidate._id],
		subquestions: subquestions,
		//questionnaire: state.questionnaires[ownProps.candidate.questionnaire._id],
		questionnaire: state.ongoingQuestionnaire,
	};
};

export default connect(
	mapStateToProps,
	{
		addAnswer: addAnswer,
		fetchSubQuestionsForPrimaryQuestion: fetchSubQuestionsForPrimaryQuestion,
		fetchStartedQuestionnaire: fetchStartedQuestionnaire,
	},
	null,
	{ forwardRef: true },
)(Question);
