import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import history from '../../history';
import Icon from '../Icon';
import { CAN_DELETE_CANDIDATES, CAN_EDIT_CANDIDATES } from '../../permissions';
import Util from '../../util';

import {
	STATE_LINK_NOT_SENT,
	STATE_LINK_SENT,
	STATE_QUESTIONNAIRE_STARTED,
	STATE_QUESTIONNAIRE_COMPLETED,
	STATE_SNEAK_PREVIEW_SENT,
	STATE_ANALYSIS_COMPLETED,
	STATE_RESULTS_SENT,
} from '../CandidateStates';

class CandidateItem extends React.Component {
	constructor(props) {
		super(props);
		this.util = new Util();
	}

	navigateToDetail() {
		history.push(`/kandidaten/detail/${this.props.candidate._id}`);
	}

	renderEditAction = candidate => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_EDIT_CANDIDATES)) {
			return (
				<Link to={`/kandidaten/aanpassen/${candidate._id}`}>
					<Icon icon="edit" />
				</Link>
			);
		}
	};

	renderDeleteAction = candidate => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_DELETE_CANDIDATES)) {
			return (
				<Link to={`/kandidaten/verwijderen/${candidate._id}`}>
					<Icon icon="delete" />
				</Link>
			);
		}
	};

	renderState = () => {
		const candidate = this.props.candidate;
		if (candidate) {
			switch (candidate.state) {
				case STATE_LINK_NOT_SENT:
					return (
						<p>
							Link <b>niet</b> verzonden
						</p>
					);
				case STATE_LINK_SENT:
					return 'Link verzonden';
				case STATE_QUESTIONNAIRE_STARTED:
					return 'Begonnen aan vragenlijst';
				case STATE_QUESTIONNAIRE_COMPLETED:
					return 'Vragenlijst afgewerkt';
				case STATE_SNEAK_PREVIEW_SENT:
					return 'Sneak Preview verzonden';
				case STATE_ANALYSIS_COMPLETED:
					return 'Verwerkt door analist';
				case STATE_RESULTS_SENT:
					return 'Rapport verzonden';
				default:
					return 'Onbekend';
			}
		}
	};

	render() {
		const candidate = this.props.candidate;
		return (
			<Table.Row>
				<Table.Cell onClick={() => this.navigateToDetail()}>
					{candidate.firstname + ' ' + candidate.lastname}
				</Table.Cell>
				<Table.Cell onClick={() => this.navigateToDetail()}>
					{this.renderState()}
				</Table.Cell>
				<Table.Cell onClick={() => this.navigateToDetail()}>
					{this.props.user
						? this.props.user.firstname + ' ' + this.props.user.lastname
						: 'Geen gebruiker'}
				</Table.Cell>
				<Table.Cell onClick={() => this.navigateToDetail()}>
					{candidate.createdAt ? this.util.formatDate(candidate.createdAt) : 'Geen datum'}
				</Table.Cell>
				<Table.Cell collapsing>
					<div className="waw-table-actions">
						{this.renderEditAction(candidate)}
						{this.renderDeleteAction(candidate)}
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}
}

const mapStateToProps = state => {
	return {
		auth: state.auth,
	};
};

export default connect(
	mapStateToProps,
	null,
)(CandidateItem);
