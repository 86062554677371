import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../Modal';
import Loader from '../Loader';
import { deleteCluster, fetchCluster } from '../../actions/clustersActions';
import history from '../../history';

class ClusterDelete extends React.Component {
	componentDidMount() {
		this.props.fetchCluster(this.props.match.params.id);
	}

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteCluster(this.props.match.params.id)}
					className="ui negative button"
				>
					Verwijderen
				</button>
				<Link to="/combinaties" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	renderContent() {
		return (
			<>
				<div>
					<p>
						<b>
							Ben je zeker dat je de cluster "{this.props.cluster.name}" wil
							verwijderen?
						</b>
					</p>
					<p>
						<b>
							Al de combinaties die deze cluster bevat zullen ook verwijderd worden!
						</b>
					</p>
				</div>
			</>
		);
	}

	render() {
		if (!this.props.cluster) return <Loader />;
		return (
			<Modal
				onSubmit={this.onSubmit}
				title="Cluster verwijderen"
				content={this.renderContent()}
				actions={this.renderActions()}
				onDismiss={() => history.push('/combinaties')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { cluster: state.clusters[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchCluster: fetchCluster, deleteCluster: deleteCluster },
)(ClusterDelete);
