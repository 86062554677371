import React from 'react';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';
import Loader from '../Loader';

class CandidateDetailCoreTalents extends React.Component {
	constructor() {
		super();
		this.state = {
			column: null,
			data: null,
			direction: null,
		};
	}

	componentDidMount = () => {
		this.setState({
			data: this.props.candidate.coreTalentsScores,
		});
	};

	handleSort = clickedColumn => () => {
		const { column, data, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	renderCoreTalents = data => {
		let coreTalentsScores = data;
		if (coreTalentsScores && coreTalentsScores.length !== 0) {
			return coreTalentsScores.map(coretalent => {
				if (coretalent.code === 'KT24') return null;
				return (
					<Table.Row key={coretalent._id}>
						<Table.Cell>
							<b>{coretalent.code}</b>
						</Table.Cell>
						<Table.Cell>
							{coretalent.name ? coretalent.name : 'Niet gevonden'}
						</Table.Cell>
						<Table.Cell>
							{coretalent.score === -1 ? 'Niet van toepassing' : coretalent.score}
						</Table.Cell>
					</Table.Row>
				);
			});
		} else {
			return (
				<Table.Row>
					<td colSpan="2">Test nog niet helemaal ingevuld</td>
				</Table.Row>
			);
		}
	};

	render() {
		if (!this.props.candidate) return <Loader />;
		const { column, data, direction } = this.state;
		return (
			<div>
				<h2 className="printOnly">
					{`${this.props.candidate.firstname} ${this.props.candidate.lastname}`}
				</h2>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'code' ? direction : null}
								onClick={this.handleSort('code')}
							>
								KernTalent code
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'name' ? direction : null}
								onClick={this.handleSort('name')}
							>
								KernTalent
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'score' ? direction : null}
								onClick={this.handleSort('score')}
							>
								Score
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>{this.renderCoreTalents(data)}</Table.Body>
				</Table>
			</div>
		);
	}
}

export default CandidateDetailCoreTalents;
