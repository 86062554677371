import React from 'react';
import { connect } from 'react-redux';
import Loader from '../Loader';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { fetchCoreTalents } from '../../actions/coretalentsActions';
import TableToolbar from '../TableToolbar';
import CoreTalentItem from './CoreTalentItem';
import { CAN_ADD_CORETALENTS } from '../../permissions';

class CoreTalentList extends React.Component {
	state = {
		column: null,
		direction: null,
	};

	handleSort = clickedColumn => () => {
		const { column, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'ascending',
			});
			return;
		}

		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	componentDidMount() {
		this.props.fetchCoreTalents();
	}

	render() {
		if (!this.props.coretalents) return <Loader />;
		const { column, direction } = this.state;
		let coretalents = _.sortBy(this.props.coretalents, [column, 'code']).reverse();
		coretalents = direction === 'ascending' ? coretalents : coretalents.reverse();
		return (
			<>
				<TableToolbar
					rightButtonText="Nieuw KernTalent"
					to="/kerntalenten/toevoegen"
					permission={CAN_ADD_CORETALENTS}
				/>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'code' ? direction : null}
								onClick={this.handleSort('code')}
							>
								Code
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'name' ? direction : null}
								onClick={this.handleSort('name')}
							>
								KernTalent
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'description' ? direction : null}
								onClick={this.handleSort('description')}
							>
								Beschrijving
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'includeInReport' ? direction : null}
								onClick={this.handleSort('includeInReport')}
							>
								Opgenomen in rapport
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'keyword' ? direction : null}
								onClick={this.handleSort('keyword')}
							>
								Kernwoord
							</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{coretalents.map(coretalent => (
							<CoreTalentItem key={coretalent._id} coretalent={coretalent} />
						))}
					</Table.Body>
				</Table>
			</>
		);
	}
}

const mapStateToProps = state => {
	const searchFilter = (talent, value) =>
		talent.code.toLowerCase().includes(value.toLowerCase()) ||
		talent.name.toLowerCase().includes(value.toLowerCase()) ||
		talent.description.toLowerCase().includes(value.toLowerCase());
	const coretalents = Object.values(state.coretalents).filter(talent =>
		searchFilter(talent, state.search.search ? state.search.search : ''),
	);
	return { coretalents };
};

export default connect(
	mapStateToProps,
	{ fetchCoreTalents: fetchCoreTalents },
)(CoreTalentList);
