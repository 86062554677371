import React from 'react';
import { Checkbox } from 'semantic-ui-react';

class CheckboxInput extends React.Component {
	constructor(props) {
		super(props);
		let defaultSelected = props.selected;
		if (props.meta && props.meta.initial !== undefined) {
			defaultSelected = props.meta.initial;
		}

		this.state = {
			defaultSelected: defaultSelected,
		};
	}

	render() {
		return (
			<Checkbox
				label={this.props.label}
				defaultChecked={this.state.defaultSelected}
				onChange={(e, data) => this.props.input.onChange(data.checked)}
			/>
		);
	}
}

export default CheckboxInput;
