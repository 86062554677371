import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Grid, Button, Form } from 'semantic-ui-react';
import WysiwygEditor from '../inputs/WysiwygEditor';
import { editText } from '../../actions/settingsActions';

class TextEdit extends React.Component {
	onSubmit = formValues => {
		this.props.editText(this.props.match.params.key, formValues.newText);
	};

	render() {
		/* **vragenlijst_link**, **resultaten_link** **kandidaat_voornaam**, **kandidaat_achternaam**, **gebruiker_voornaam**, **gebruiker_achternaam**   **analist_voornaam**, **analist_achternaam** */
		return (
			<Grid padded>
				<Grid.Row>
					<Grid.Column>
						<h1>Tekst aanpassen</h1>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<p>
							Pas hieronder de tekst aan. <b>Opgelet:</b> deze tekst verschijnt in
							rapporten of in mails naar kandidaten.
							<br />
							Voeg de volgende termen toe (MET sterretjes!) in de tekst, de applicatie
							zal ze dan waar nodig vervangen door de echte waarde:
						</p>
						<ul>
							<li>
								<b>**vragenlijst_link**</b>: wordt vervangen door de link naar een
								vragenlijst.
							</li>
							<li>
								<b>**resultaten_link**</b>: wordt vervangen door de link naar de
								pagina waar een kandidaat zijn resultaten kan zien en een rapport
								kan downloaden.
							</li>
							<li>
								<b>**kandidaat_voornaam**</b>: wordt vervangen door de voornaam van
								de kandidaat.
							</li>
							<li>
								<b>**kandidaat_achternaam**</b>: wordt vervangen door de achternaam
								van de kandidaat.
							</li>
							<li>
								<b>**gebruiker_voornaam**</b>: wordt vervangen door de voornaam van
								de ingelogde gebruiker.
							</li>
							<li>
								<b>**gebruiker_achternaam**</b>: wordt vervangen door de achternaam
								van de ingelogde gebruiker
							</li>
							<li>
								<b>**analist_voornaam**</b>: (alleen van toepassing voor de tekst in
								een rapport) wordt vervangen door de voornaam van de analist die het
								rapport aangemaakt heeft
							</li>
							<li>
								<b>**analist_achternaam**</b>: (alleen van toepassing voor de tekst
								in een rapport) wordt vervangen door de achternaam van de analist
								die het rapport aangemaakt heeft.
							</li>
						</ul>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
							<Field
								name="newText"
								component={WysiwygEditor}
								text={this.props.text}
							/>
							<br />
							<Button primary type="submit">
								Opslaan
							</Button>
						</Form>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	let key = ownProps.match.params.key;
	return { text: state.settings[key] };
};

TextEdit = reduxForm({
	form: 'editApplicationText',
})(TextEdit);

export default connect(
	mapStateToProps,
	{ editText },
)(TextEdit);
