import React from 'react';
import { deleteQuestion, fetchQuestion } from '../../actions/questionsActions';
import Modal from '../Modal';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../Loader';
import history from '../../history';

class QuestionDelete extends React.Component {
	componentDidMount() {
		this.props.fetchQuestion(this.props.match.params.id);
	}

	renderContent = () => {
		if (!this.props.question) return <Loader />;
		return (
			<div>
				<p>
					Ben je zeker dat je de vraag:
					<b> "{this.props.question.question}"</b> wil verwijderen?
				</p>
			</div>
		);
	};

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteQuestion(this.props.match.params.id)}
					className="ui negative button"
				>
					Verwijderen
				</button>
				<Link to="/" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	render() {
		return (
			<>
				<Modal
					title="Vraag verwijderen"
					content={this.renderContent()}
					actions={this.renderActions()}
					onDismiss={() => history.push('/vragen')}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { question: state.questions[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchQuestion: fetchQuestion, deleteQuestion: deleteQuestion },
)(QuestionDelete);
