import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchRole, deleteRole } from '../../actions/rolesActions';
import Loader from '../Loader';
import Modal from '../Modal';
import history from '../../history';

class RoleDelete extends React.Component {
	componentDidMount() {
		this.props.fetchRole(this.props.match.params.id);
	}

	renderContent() {
		return (
			<>
				<p>
					Ben je zeker dat je rol <b>{this.props.role.name}</b> wil verwijderen?
				</p>
			</>
		);
	}

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteRole(this.props.match.params.id)}
					className="ui negative button"
				>
					Verwijderen
				</button>
				<Link to="/voorkeuren" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	render() {
		if (!this.props.role) return <Loader />;
		return (
			<Modal
				title="Rol verwijderen"
				content={this.renderContent()}
				actions={this.renderActions()}
				onDismiss={() => history.push('/voorkeuren')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { role: state.roles[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchRole: fetchRole, deleteRole: deleteRole },
)(RoleDelete);
