import React from 'react';
import { connect } from 'react-redux';
import { createQuestion } from '../../actions/questionsActions';
import QuestionForm from './QuestionForm';
import Modal from '../Modal';
import history from '../../history';

class QuestionCreate extends React.Component {
	onSubmit = ({ coretalent, isTextarea, question, shortQuestion }) => {
		const createObject = {
			coretalent: coretalent,
			isTextarea: isTextarea ? isTextarea : false,
			question: question ? question.trim() : "",
			shortQuestion: shortQuestion ? shortQuestion.trim() : ""
		};
		this.props.createQuestion(createObject);
	};

	renderForm = () => <QuestionForm onSubmit={this.onSubmit} />;

	render() {
		return (
			<>
				<Modal
					title="Nieuwe vraag"
					content={this.renderForm()}
					onDismiss={() => history.push('/vragen')}
				/>
			</>
		);
	}
}

export default connect(
	null,
	{ createQuestion: createQuestion },
)(QuestionCreate);
