import React from 'react';

import logo from '../../img/wawlogo.svg';
import whiteLogo from '../../img/wawlogo_white.svg';
import { Container, Responsive } from 'semantic-ui-react';

class QuestionnaireHeader extends React.Component {
	renderCandidate = () => {
		if (!this.props.candidate) {
			return <div className="name">Kandidaat: Laden ...</div>;
		} else {
			return (
				<div className="name">
					Kandidaat: {this.props.candidate.firstname} {this.props.candidate.lastname}
				</div>
			);
		}
	};

	render() {
		return (
			<>
				<Responsive as={Container} fluid minWidth={700} className="questionnaire-header">
					<div className="logo">
						<img alt="logo" src={logo} /> KernTalenten
					</div>
					{this.renderCandidate()}
				</Responsive>
				<Responsive
					as={Container}
					fluid
					maxWidth={700}
					className="questionnaire-header-mobile"
				>
					<div className="logo">
						<img alt="logo" src={whiteLogo} />
					</div>
				</Responsive>
			</>
		);
	}
}

export default QuestionnaireHeader;
