import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Progress, Grid, Container, Sticky } from 'semantic-ui-react';
import { fetchCandidate } from '../../actions/candidatesActions';
import {
	startQuestionnaire,
	fetchStartedQuestionnaire,
	fetchNextQuestion,
	fetchPreviousQuestion,
} from '../../actions/ongoingQuestionnaireActions';
import { fetchQuestion } from '../../actions/questionsActions';
import { fetchSubQuestionsForPrimaryQuestion } from '../../actions/subquestionsActions';

import Question from './Question';
import QuestionnaireHeader from './QuestionnaireHeader';
import history from '../../history';

import Loader from '../Loader';
import { questionnaire_progress } from '../../apis/waw';

import '../../css/progress.css';
import QuestionnaireError from './QuestionnaireError';
import CoreTalentsDisclaimer from './CoreTalentsDisclaimer';
class Questionnaire extends React.Component {
	contextRef = createRef();

	constructor(props) {
		super(props);
		this.state = {
			currentQuestion: null,
			completion: 0,
		};
	}

	async componentDidMount() {
		await this.props.fetchCandidate(this.props.match.params.candidateId);
		if (this.props.candidate) {
			let questionId;
			if (!this.props.candidate.questionnaire) {
				//TODO: this probably never happens
				await this.props.startQuestionnaire(this.props.candidate._id);
			} else {
				await this.props.fetchStartedQuestionnaire(this.props.candidate.questionnaire._id);
			}

			questionId = this.props.questionnaire.firstQuestion;
			if (
				this.props.questionnaire.orderOfAnsweredQuestions &&
				this.props.questionnaire.orderOfAnsweredQuestions.length > 0
			) {
				const lastIndex = this.props.questionnaire.orderOfAnsweredQuestions.length - 1;
				questionId = this.props.questionnaire.orderOfAnsweredQuestions[lastIndex];
			}

			await this.props.fetchQuestion(questionId);

			//At this point, there should only be one question in the store.
			// TODO: This seems hacky: refactor this.
			this.setState({
				currentQuestion: this.props.questions[0],
			});
		}
	}

	goToNextQuestion = async () => {
		this.calculateProgress();
		await this.refs.currentQuestion.postAnswer(this.props.candidate.questionnaire._id);

		await this.props.fetchNextQuestion(
			this.props.candidate.questionnaire._id,
			this.state.currentQuestion._id,
		);

		if (this.props.nextQuestion === 'END') {
			const response = await questionnaire_progress.post(
				`/${this.props.candidate.questionnaire._id}/overview`,
			);
			if (response.status === 200) {
				history.push(`/vragenlijst/overzicht/${this.props.candidate._id}`);
			}
		} else {
			this.setState({
				currentQuestion: this.props.nextQuestion,
			});
		}
	};

	goToPreviousQuestion = async () => {
		await this.props.fetchPreviousQuestion(
			this.props.candidate.questionnaire._id,
			this.state.currentQuestion._id,
		);

		if (this.props.previousQuestion === 'BEGIN') {
			history.push(`/vragenlijst/beschrijving/${this.props.candidate._id}`);
		} else {
			this.setState({
				currentQuestion: this.props.previousQuestion,
			});
		}
	};

	render() {
		if (this.props.errors.error) {
			return <QuestionnaireError errorMessage={this.props.errors.error} />;
		}
		if (!this.props.questionnaire) return <Loader />;
		if (!this.state.currentQuestion) return <Loader />;
		if (!this.props.candidate) return <Loader />;
		return (
			<>
				<Grid.Row>
					<QuestionnaireHeader candidate={this.props.candidate} />
				</Grid.Row>
				<Grid.Row>
					<Container fluid className="waw-questionnaire">
						<Progress
							percent={this.state.completion}
							progress
							color="orange"
							size="large"
						/>
						<Sticky context={this.props.mainContextRef}>
							<Container fluid className="primaryQuestion">
								<h2>
									<b>{this.state.currentQuestion.question}</b>
								</h2>
								{this.state.currentQuestion.subQuestions.length !== 0 ? (
									<h2>Hoe leuk vond ik de volgende aspecten hiervan?</h2>
								) : null}
							</Container>
						</Sticky>
						<Container fluid className="question">
							<Question
								key={this.state.currentQuestion._id}
								candidate={this.props.candidate}
								question={this.state.currentQuestion}
								ongoingQuestionnaire={this.props.questionnaire}
								ref="currentQuestion"
								contextRef={this.contextRef}
							/>
							<div className="questionnaire-buttons">
								<div
									className="previous"
									onClick={() => this.goToPreviousQuestion()}
								>
									Vorige
								</div>
								<div className="next" onClick={() => this.goToNextQuestion()}>
									Volgende &gt;
								</div>
							</div>
						</Container>
						<CoreTalentsDisclaimer />
					</Container>
				</Grid.Row>
			</>
		);
	}

	calculateProgress = () => {
		if (this.props.questionnaire) {
			const answeredQuestions = this.props.questionnaire.orderOfAnsweredQuestions;
			if (answeredQuestions) {
				const answered = answeredQuestions.length;
				const total = this.props.questionnaire.totalQuestions;
				const progress = (answered / total) * 100;
				this.setState({ completion: Math.floor(progress) });
			}
		}
	};
}

const mapStateToProps = (state, ownProps) => {
	return {
		candidate: state.candidates[ownProps.match.params.candidateId],
		questionnaire: state.ongoingQuestionnaire,
		nextQuestion: state.ongoingQuestionnaire.nextQuestion,
		previousQuestion: state.ongoingQuestionnaire.previousQuestion,
		questions: Object.values(state.questions),
		errors: state.errors,
	};
};

export default connect(mapStateToProps, {
	fetchCandidate: fetchCandidate,
	fetchQuestion: fetchQuestion,
	fetchStartedQuestionnaire: fetchStartedQuestionnaire,
	startQuestionnaire: startQuestionnaire,
	fetchNextQuestion: fetchNextQuestion,
	fetchPreviousQuestion: fetchPreviousQuestion,
	fetchSubQuestionsForPrimaryQuestion: fetchSubQuestionsForPrimaryQuestion,
})(Questionnaire);
