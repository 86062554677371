import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { fetchUsers } from '../../actions/usersActions';
import Loader from '../Loader';
import TableToolbar from '../TableToolbar';
import UserItem from './UserItem';
import { CAN_ADD_USERS, CAN_VIEW_USERS } from '../../permissions';
import history from '../../history';

class UserList extends React.Component {
	constructor() {
		super();
		this.state = {
			column: null,
			direction: null,
		};
	}

	handleSort = clickedColumn => () => {
		const { column, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'ascending',
			});
			return;
		}

		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	componentDidMount = async () => {
		await this.props.fetchUsers();
	};

	renderUsers(users) {
		return users.map(user => <UserItem key={user._id} user={user} />);
	}

	checkThisPageViewPermission = () => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(permission => permission === CAN_VIEW_USERS)) {
			return true;
		}
		return false;
	};

	render() {
		if (!this.checkThisPageViewPermission()) {
			history.push('/');
			return null;
		}
		if (!this.props.users) return <Loader />;

		const { column, direction } = this.state;
		let users = this.props.users;
		if (column) {
			users = _.orderBy(this.props.users, [user => user[column].toLowerCase(), 'lastname']);
		}

		users = direction === 'ascending' ? users : users.reverse();
		return (
			<>
				<TableToolbar
					rightButtonText="Nieuwe gebruiker"
					to="/gebruikers/toevoegen"
					permission={CAN_ADD_USERS}
				/>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'firstname' ? direction : null}
								onClick={this.handleSort('firstname')}
							>
								Naam
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'email' ? direction : null}
								onClick={this.handleSort('email')}
							>
								E-mail
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'role' ? direction : null}
								onClick={this.handleSort('role')}
							>
								Rol
							</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{users.length > 0 ? (
							this.renderUsers(users)
						) : (
							<Table.Row>
								<Table.Cell colSpan={4}>Geen gebruikers</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>
				</Table>
			</>
		);
	}
}

const mapStateToProps = state => {
	const searchFilter = (user, value) => {
		if (user.firstname && user.lastname) {
			return (
				user.lastname.toLowerCase().includes(value.toLowerCase()) ||
				(user.firstname + ' ' + user.lastname)
					.toLowerCase()
					.includes(value.toLowerCase()) ||
				user.email.toLowerCase().includes(value.toLowerCase())
			);
		} else if (user.firstname && !user.lastname) {
			return (
				user.firstname.toLowerCase().includes(value.toLowerCase()) ||
				user.email.toLowerCase().includes(value.toLowerCase())
			);
		} else {
			return user.email.toLowerCase().includes(value.toLowerCase());
		}
	};

	const users = Object.values(state.users).filter(user =>
		searchFilter(user, state.search.search ? state.search.search : ''),
	);
	return { users: users, auth: state.auth };
};

export default connect(
	mapStateToProps,
	{
		fetchUsers: fetchUsers,
	},
)(UserList);
