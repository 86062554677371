import React from 'react';
import { Dropdown } from 'semantic-ui-react';

class SelectInput extends React.Component {
	constructor(props) {
		super(props);
		let selected = this.props.meta.initial ? this.props.meta.initial._id : 0;
		if (!this.props.meta.initial) {
			selected = this.props.selected;
		}
		if (selected) props.input.onChange(selected);
		this.state = {
			selected: selected,
		};
	}

	render() {
		return (
			<Dropdown
				selection
				//{...this.props.input}
				onChange={(param, data) => this.props.input.onChange(data.value)}
				options={this.props.options}
				defaultValue={this.state.selected}
				placeholder={this.props.label}
			/>
		);
	}
}

export default SelectInput;
