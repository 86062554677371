import _ from 'lodash';
import { FETCH_TOKEN, LOGOUT, LOGIN_FAILED } from '../actions/types';
import errorsReducer from './errorsReducer';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_TOKEN:
			return action.payload;
		case LOGOUT:
			return _.omit(state, 'token');
		case LOGIN_FAILED:
			return { ...state, error: errorsReducer(state.auth, LOGIN_FAILED) };
		default:
			return state;
	}
};
