import React from 'react';
import { Search } from 'semantic-ui-react';

class SearchInput extends React.Component {
	constructor(props) {
		super(props);

		let option = props.options.find(option => option.id === props.input.value);

		if (this.props.initial) {
			option = props.options.find(option => option.id === this.props.initial);
		}

		this.state = {
			results: [],
			value: option ? option.title : '',
		};
	}

	componentDidMount() {
		this.props.input.onChange(this.props.initial);
	}

	handleSearchChange = (e, { value }) => {
		let results;
		if (this.props.filterMethod) {
			results = this.props.options.filter(option => this.props.filterMethod(option, value));
		} else {
			results = this.props.options.filter(option =>
				//option.title.toLowerCase().startsWith(value.toLowerCase()),
				option.title.toLowerCase().includes(value.toLowerCase()),
			);
		}

		this.setState({
			results,
			value,
		});
		if (this.props.input.value) this.props.input.onChange(null);
	};

	handleResultSelect = (e, { result }) => {
		this.setState({
			value: result.title,
		});
		this.props.input.onChange(result.id);
	};

	handleBlur = (e, data) => {
		if (!this.props.input.value)
			this.setState({
				value: '',
			});
	};

	render() {
		const { value, results } = this.state;
		return (
			<Search
				onResultSelect={this.handleResultSelect}
				onSearchChange={this.handleSearchChange}
				onBlur={this.handleBlur}
				results={results}
				value={value}
				selectFirstResult={true}
				noResultsMessage="Geen resultaten gevonden. Zorg dat de resultaten vrij gegeven zijn door een analist."
			/>
		);
	}
}

export default SearchInput;
