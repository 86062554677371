import {
	CREATE_SUB_QUESTION,
	EDIT_SUB_QUESTION,
	FETCH_SUB_QUESTION,
	FETCH_SUB_QUESTIONS,
	FETCH_SUB_QUESTIONS_FOR_PRIMARY,
	DELETE_SUB_QUESTION,
} from './types';
import { subquestions } from '../apis/waw';
import history from '../history';

export const fetchSubQuestions = () => async dispatch => {
	const response = await subquestions.get('/');
	dispatch({
		type: FETCH_SUB_QUESTIONS,
		payload: response.data,
	});
};

export const fetchSubQuestionsForPrimaryQuestion = pqId => async dispatch => {
	const response = await subquestions.get('/pq/' + pqId);
	dispatch({
		type: FETCH_SUB_QUESTIONS_FOR_PRIMARY,
		payload: response.data,
	});
};

export const fetchSubQuestion = id => async dispatch => {
	const response = await subquestions.get(`/${id}`);
	dispatch({
		type: FETCH_SUB_QUESTION,
		payload: response.data,
	});
};

export const createSubQuestion = formValues => async dispatch => {
	const response = await subquestions.post('/', { ...formValues });
	dispatch({
		type: CREATE_SUB_QUESTION,
		payload: response.data,
	});
	history.push('/vragen');
};

export const editSubQuestion = (id, formValues) => async dispatch => {
	const response = await subquestions.put(`/${id}`, { ...formValues });
	dispatch({
		type: EDIT_SUB_QUESTION,
		payload: response.data,
	});
	history.push('/vragen');
};

export const deleteSubQuestion = id => async dispatch => {
	await subquestions.delete(`/${id}`);
	dispatch({
		type: DELETE_SUB_QUESTION,
		payload: id,
	});
	history.push('/vragen');
};
