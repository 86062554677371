import React from 'react';
import { connect } from 'react-redux';
import CandidateForm from './CandidateForm';
import { fetchCandidate, editCandidate } from '../../actions/candidatesActions';
import Modal from '../Modal';
import Loader from '../Loader';
import history from '../../history';

class CandidateEdit extends React.Component {
	componentDidMount() {
		this.props.fetchCandidate(this.props.match.params.id);
	}

	onSubmit = formValues => {
		this.props.editCandidate(formValues, this.props.match.params.id);
	};

	renderForm() {
		const candidate = this.props.candidate;
		const initialValues = {
			firstname: candidate.firstname,
			lastname: candidate.lastname,
			email: candidate.email,
			info: candidate.info,
		};
		return <CandidateForm onSubmit={this.onSubmit} initialValues={initialValues} />;
	}

	render() {
		if (!this.props.candidate) return <Loader />;
		return (
			<Modal
				title="Kandidaat aanpassen"
				content={this.renderForm()}
				onDismiss={() => history.push('/kandidaten')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { candidate: state.candidates[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchCandidate: fetchCandidate, editCandidate: editCandidate },
)(CandidateEdit);
