import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { editSubQuestion, fetchSubQuestion } from '../../actions/subquestionsActions';
import QuestionForm from '../questions/QuestionForm';
import Modal from '../Modal';
import Loader from '../Loader';
import history from '../../history';

class SubQuestionEdit extends React.Component {
	componentDidMount() {
		this.props.fetchSubQuestion(this.props.match.params.id);
	}

	onSubmit = ({
		coretalent,
		isTextarea,
		question,
		shortQuestion
	}) => {
		const { subquestion, match } = this.props;
		const updateObject = {
			coretalent: coretalent,
			isTextarea: isTextarea ? isTextarea : false,
			question: question ? question.trim() : '',
			shortQuestion: shortQuestion ? shortQuestion.trim() : '',
			primaryQuestion: subquestion.primaryQuestion._id
		}
		this.props.editSubQuestion(match.params.id, updateObject);
	};

	renderForm = () => {
		const { subquestion } = this.props;
		if (!subquestion) return <Loader />;
		return (
			<>
				<h3>Subvraag bij:</h3>
				<p>
					<b>"{subquestion.primaryQuestion.question}"</b>
				</p>
				<QuestionForm
					onSubmit={this.onSubmit}
					initialValues={_.pick(
						subquestion,
						'question',
						'shortQuestion',
						'coretalent',
					)}
				/>
			</>
		);
	};

	render() {
		if (!this.props.subquestion) return <Loader />;
		return (
			<>
				<Modal
					title="Subvraag aanpassen"
					content={this.renderForm()}
					onDismiss={() => history.push('/vragen')}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { subquestion: state.subquestions[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ editSubQuestion: editSubQuestion, fetchSubQuestion: fetchSubQuestion },
)(SubQuestionEdit);
