import _ from 'lodash';
import {
	FETCH_CORETALENTS,
	CREATE_CORETALENT,
	EDIT_CORETALENT,
	FETCH_CORETALENT,
	DELETE_CORETALENT,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_CORETALENTS:
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_CORETALENT:
			return { ...state, [action.payload._id]: action.payload };
		case CREATE_CORETALENT:
			return { ...state, [action.payload._id]: action.payload };
		case EDIT_CORETALENT:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_CORETALENT:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
