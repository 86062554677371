import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { fetchClusters } from '../../../actions/clustersActions';
import Loader from '../../Loader';
import MultipleSelectInput from '../../inputs/MultipleSelectInput';

class Step2 extends React.Component {
	componentDidMount() {
		this.props.fetchClusters();
	}

	render() {
		if (!this.props.clusters || this.props.clusters.length === 0) return <Loader />;
		return (
			<Form.Field>
				<label>Clusters</label>
				<Field
					name="clusters"
					component={MultipleSelectInput}
					options={this.transformClusters()}
				/>
			</Form.Field>
		);
	}

	transformClusters = () => {
		let options = [];
		this.props.clusters.forEach(cluster => {
			options.push({
				key: cluster._id,
				text: cluster.name,
				value: cluster._id,
			});
		});
		return options;
	};
}

const mapStateToProps = (state, ownProps) => {
	return { clusters: Object.values(state.clusters) };
};

export default connect(
	mapStateToProps,
	{ fetchClusters },
)(Step2);
