import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { fetchReports } from '../../actions/reportsActions';
import Loader from '../Loader';
import TableToolbar from '../TableToolbar';
import ReportItem from './ReportItem';
import { CAN_ADD_REPORTS } from '../../permissions';

class ReportList extends React.Component {
	state = {
		column: null,
		direction: null,
	};

	handleSort = clickedColumn => () => {
		const { column, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'ascending',
			});
			return;
		}

		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	componentDidMount() {
		this.props.fetchReports();
	}

	renderList() {
		return this.props.reports.map(report => <ReportItem key={report._id} report={report} />);
	}

	render() {
		if (!this.props.reports) return <Loader />;
		const { column, direction } = this.state;
		let reports = _.sortBy(this.props.reports, [column, 'name']);
		reports = direction === 'ascending' ? reports : reports.reverse();
		return (
			<>
				<TableToolbar
					rightButtonText="Nieuw rapport"
					to="/rapporten/toevoegen"
					permission={CAN_ADD_REPORTS}
				/>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'name' ? direction : null}
								onClick={this.handleSort('name')}
							>
								Naam
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'candidate.firstname' ? direction : null}
								onClick={this.handleSort('candidate.firstname')}
							>
								Kandidaat
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'createdAt' ? direction : null}
								onClick={this.handleSort('createdAt')}
							>
								Aangemaakt op
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'analist.firstname' ? direction : null}
								onClick={this.handleSort('analist.firstname')}
							>
								Analist
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'destination' ? direction : null}
								onClick={this.handleSort('destination')}
							>
								Bestemming
							</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{reports.length > 0 ? (
							reports.map(report => <ReportItem key={report._id} report={report} />)
						) : (
							<Table.Row>
								<Table.Cell colSpan="5">Nog geen rapporten</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>
				</Table>
			</>
		);
	}
}

const mapStateToProps = state => {
	const searchFilter = (report, value) =>
		report.name.toLowerCase().includes(value.toLowerCase()) ||
		report.candidate.lastname.toLowerCase().includes(value.toLowerCase()) ||
		(report.candidate.firstname + ' ' + report.candidate.lastname)
			.toLowerCase()
			.includes(value.toLowerCase());
	const reports = Object.values(state.reports).filter(report =>
		searchFilter(report, state.search.search ? state.search.search : ''),
	);
	return { reports };
};

export default connect(
	mapStateToProps,
	{ fetchReports: fetchReports },
)(ReportList);
