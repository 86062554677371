import React from 'react';
import { connect } from 'react-redux';
import { createRole } from '../../actions/rolesActions';
import RoleForm from './RoleForm';
import Modal from '../Modal';
import history from '../../history';

class RoleCreate extends React.Component {
	onSubmit = formValues => {
		this.props.createRole(formValues);
	};

	renderForm = () => {
		return <RoleForm onSubmit={this.onSubmit} />;
	};

	render() {
		return (
			<Modal
				title="Nieuwe rol"
				content={this.renderForm()}
				onDismiss={() => {
					history.push('/voorkeuren');
				}}
			/>
		);
	}
}

export default connect(
	null,
	{ createRole: createRole },
)(RoleCreate);
