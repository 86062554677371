import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'semantic-ui-react';
import CheckboxInput from '../inputs/CheckboxInput';
class CoreTalentForm extends React.Component {
	onSubmit = formValues => {
		this.props.onSubmit(formValues);
	};

	render() {
		const code = this.props.initialValues ? this.props.initialValues.code : undefined;
		return (
			<Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<Form.Field>
					<label>Naam</label>
					<Field name="name" component="input" />
				</Form.Field>

				<Form.Field>
					<label>Beschrijving</label>
					<Field name="description" component="textarea" />
				</Form.Field>

				{code && (code === 'KT14' || code === 'KT15') ? null : (
					<Form.Field>
						<label>Code</label>
						<Field name="code" component="input" />
					</Form.Field>
				)}
				<Form.Field>
					<label>Kernwoord</label>
					<Field name="keyword" component="input" />
				</Form.Field>
				<Form.Field>
					<Field
						name="includeInReport"
						component={CheckboxInput}
						label="Moet in rapport opgenomen worden"
						selected={true}
					/>
				</Form.Field>
				<Button type="submit" primary>
					Opslaan
				</Button>
			</Form>
		);
	}
}

export default reduxForm({
	form: 'coretalentForm',
})(CoreTalentForm);
