import React from 'react';
import _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { fetchRoles } from '../../actions/rolesActions';
import { editPassword } from '../../actions/usersActions';
import SelectInput from '../inputs/SelectInput';
import Loader from '../Loader';
import { CAN_EDIT_USERS } from '../../permissions';

class UserForm extends React.Component {
	componentDidMount() {
		this.props.fetchRoles();
	}

	onSubmit = async formValues => {
		if (formValues.password && this.props.queryId) {
			await this.props.editPassword(formValues.password, this.props.queryId);
		}
		if (this.props.passwordRequired) {
			this.props.onSubmit(formValues);
		} else {
			this.props.onSubmit(_.omit(formValues, 'password'));
		}
	};

	renderPasswordField() {
		const tokenId = this.props.auth.id;
		let queryId = undefined;
		if (this.props.queryId) {
			queryId = this.props.queryId;
		}
		const permissions = this.props.auth.permissions;

		const permissionGranted = permissions.find(permission => permission === CAN_EDIT_USERS);
		if (permissionGranted || tokenId === queryId) {
			return (
				<div className="field">
					<label>Password</label>
					<div>
						<Field
							name="password"
							component="input"
							required={
								this.props.passwordRequired ? this.props.passwordRequired : false
							}
						/>
					</div>
				</div>
			);
		}
	}

	renderRolesField() {
		if (this.props.roles.length === 0) {
			return (
				<div className="field">
					<label>Rol</label>
					<div>
						Er zijn nog geen rollen gedefinieerd. Ga naar 'Voorkeuren' -> 'Rollen &
						Rechten'
					</div>
				</div>
			);
		} else {
			let options = [];
			this.props.roles.forEach(role => {
				if (role.name !== 'SYSTEM_ADMIN') {
					options.push({
						key: role._id,
						text: role.name,
						value: role._id,
					});
				}
			});

			return (
				<div className="field">
					<label>Rol</label>
					<div>
						<Field name="role" component={SelectInput} options={options} required />
					</div>
				</div>
			);
		}
	}

	render() {
		if (!this.props.roles) return <Loader />;
		return (
			<form className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<div className="field">
					<label>Voornaam</label>
					<div>
						<Field name="firstname" component="input" required />
					</div>
				</div>
				<div className="field">
					<label>Familienaam</label>
					<div>
						<Field name="lastname" component="input" required />
					</div>
				</div>
				<div className="field">
					<label>E-mail adres</label>
					<div>
						<Field name="email" component="input" required />
					</div>
				</div>
				<div className="field">
					<label>Telefoonnnummer</label>
					<div>
						<Field name="phone" component="input" />
					</div>
				</div>
				{this.checkPermission(CAN_EDIT_USERS) ? this.renderRolesField() : null}
				{this.renderPasswordField()}
				<button type="submit" className="ui button primary">
					Opslaan
				</button>
			</form>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}

const mapStateToProps = state => {
	return { roles: Object.values(state.roles), auth: state.auth };
};

UserForm = reduxForm({
	form: 'usersForm',
})(UserForm);

export default connect(
	mapStateToProps,
	{ fetchRoles: fetchRoles, editPassword: editPassword },
)(UserForm);
