import {
	FETCH_CANDIDATES,
	FETCH_CANDIDATE,
	DELETE_CANDIDATE,
	EDIT_CANDIDATE,
	CREATE_CANDIDATE,
	ADJUST_SCORES,
	FORM_ACTION_FAILED,
	CANDIDATE_NOT_FOUND,
} from './types';
import { candidates } from '../apis/waw';
import history from '../history';

export const fetchCandidates = () => async dispatch => {
	const response = await candidates.get('/');
	dispatch({
		type: FETCH_CANDIDATES,
		payload: response.data,
	});
};

export const fetchCandidate = id => async dispatch => {
	try {
		const response = await candidates.get(`/${id}`);
		dispatch({
			type: FETCH_CANDIDATE,
			payload: response.data,
		});
	} catch (err) {
		dispatch({
			type: CANDIDATE_NOT_FOUND,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const editCandidate = (formValues, id) => async dispatch => {
	try {
		const response = await candidates.put(`/${id}`, { ...formValues });
		dispatch({
			type: EDIT_CANDIDATE,
			payload: response.data,
		});
		history.push('/kandidaten');
	} catch (err) {
		dispatch({
			type: FORM_ACTION_FAILED,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const createCandidate = formValues => async dispatch => {
	try {
		const response = await candidates.post('/', { ...formValues });
		dispatch({
			type: CREATE_CANDIDATE,
			payload: response.data,
		});
		history.push('/kandidaten');
	} catch (err) {
		dispatch({
			type: FORM_ACTION_FAILED,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const deleteCandidate = id => async dispatch => {
	await candidates.delete(`/${id}`);
	dispatch({
		type: DELETE_CANDIDATE,
		payload: id,
	});
	history.push('/kandidaten');
};

export const adjustScores = (id, formValues) => async dispatch => {
	const response = await candidates.put(`/${id}/adjustedScores`, formValues);
	dispatch({
		type: ADJUST_SCORES,
		payload: response.data,
	});
};
