import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import Icon from '../Icon';
import { CAN_DELETE_USERS, CAN_EDIT_USERS } from '../../permissions';

class UserItem extends React.Component {
	render() {
		const user = this.props.user;
		if (user) {
			return (
				<Table.Row>
					<Table.Cell>
						{user.firstname} {user.lastname ? user.lastname : null}
					</Table.Cell>
					<Table.Cell>{user.email ? user.email : 'Geen email'}</Table.Cell>
					<Table.Cell>{user.role ? user.role.name : 'Geen rol'}</Table.Cell>
					<Table.Cell collapsing>{this.renderActions(user)}</Table.Cell>
				</Table.Row>
			);
		}
	}

	renderActions = user => {
		//This causes problems when there are users without a role in the database.
		// Is only the case when upgrading, should not happen in production
		//if (user.role && user.role.name !== "SYSTEM_ADMIN") {
		return (
			<div className="waw-table-actions">
				{this.renderEditAction(user)}
				{this.renderDeleteAction(user)}
			</div>
		);
		//}
	};

	renderEditAction = user => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_EDIT_USERS)) {
			return (
				<Link to={`/gebruikers/aanpassen/${user._id}`}>
					<Icon icon="edit" />
				</Link>
			);
		}
	};

	renderDeleteAction = user => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_DELETE_USERS)) {
			return (
				<Link to={`/gebruikers/verwijderen/${user._id}`}>
					<Icon icon="delete" />
				</Link>
			);
		}
	};
}

const mapStateToProps = state => {
	return { auth: state.auth };
};
export default connect(
	mapStateToProps,
	null,
)(UserItem);
