import React from 'react';
import { connect } from 'react-redux';
import { createCluster } from '../../actions/clustersActions';
import { fetchCoreTalents } from '../../actions/coretalentsActions';
import ClusterForm from './ClusterForm';
import Modal from '../Modal';
import Loader from '../Loader';
import history from '../../history';

class ClusterEdit extends React.Component {
	componentDidMount() {
		this.props.fetchCoreTalents();
	}

	onSubmit = formValues => {
		this.props.createCluster(formValues);
	};

	renderForm = () => {
		if (this.props.coreTalents.length === 0) return <Loader />;
		return (
			<>
				<ClusterForm
					onSubmit={this.onSubmit}
					coreTalents={this.props.coreTalents}
					allowEditCoreTalents={true}
				/>
			</>
		);
	};

	render() {
		return (
			<>
				<Modal
					title="Cluster toevoegen"
					content={this.renderForm()}
					onDismiss={() => history.push('/combinaties')}
				/>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		coreTalents: Object.values(state.coretalents),
	};
};

export default connect(
	mapStateToProps,
	{ createCluster: createCluster, fetchCoreTalents: fetchCoreTalents },
)(ClusterEdit);
