import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { fetchReports } from '../../actions/reportsActions';
import Loader from '../Loader';
import ReportItem from '../reports/ReportItem';

class CandidateDetailReports extends React.Component {
	componentDidMount() {
		//TODO: this function fetches all reports, and filters them in the mapStateToProps function,
		// this implementation can be improved by using the fetchCandidateReports action (backend query required)
		this.props.fetchReports(this.props.candidate._id);
	}

	renderReports() {
		if (!this.props.reports) {
			return (
				<Table.Row>
					<Loader />
				</Table.Row>
			);
		} else {
			if (this.props.reports.length === 0)
				return (
					<Table.Row>
						<Table.Cell colSpan={5}>Nog geen rapporten voor deze kandidaat</Table.Cell>
					</Table.Row>
				);
			return this.props.reports.map(report => {
				return <ReportItem key={report._id} report={report} showCandidateName={false} />;
			});
		}
	}

	render() {
		return (
			<Table sortable celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Rapport</Table.HeaderCell>
						<Table.HeaderCell>Datum aangemaakt</Table.HeaderCell>
						<Table.HeaderCell>Analist</Table.HeaderCell>
						<Table.HeaderCell>Bestemming</Table.HeaderCell>
						<Table.HeaderCell>Acties</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>{this.renderReports()}</Table.Body>
			</Table>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const candidateReports = Object.values(state.reports).filter(
		report => report.candidate._id === ownProps.candidate._id,
	);
	return { reports: candidateReports };
};

export default connect(
	mapStateToProps,
	{ fetchReports },
)(CandidateDetailReports);
