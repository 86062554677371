import React from 'react';
import { Dropdown } from 'semantic-ui-react';

class MultipleSelectInput extends React.Component {
	render() {
		return (
			<Dropdown
				fluid
				multiple
				search
				selection
				onChange={(param, data) => this.props.input.onChange(data.value)}
				placeholder={this.props.placeholder}
				options={this.props.options}
			/>
		);
	}
}

export default MultipleSelectInput;
