import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { fetchCandidates } from '../../actions/candidatesActions';
import { fetchUsers } from '../../actions/usersActions';

import Loader from '../Loader';
import TableToolbar from '../TableToolbar';
import CandidateItem from './CandidateItem';
import { CAN_ADD_CANDIDATES } from '../../permissions';

class CandidateList extends React.Component {
	constructor() {
		super();
		this.state = {
			column: null,
			direction: null,
		};
	}

	handleSort = clickedColumn => () => {
		const { column, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'ascending',
			});
			return;
		}

		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	async componentDidMount() {
		await Promise.all([this.props.fetchCandidates(), this.props.fetchUsers()]);
	}

	render() {
		if (!this.props.candidates) return <Loader />;
		const { column, direction } = this.state;

		let candidates = this.props.candidates;
		if (column) {
			candidates = _.orderBy(this.props.candidates, [
				candidate => {
					if (candidate[column]) return candidate[column].toLowerCase();
				},
			]);
		}
		candidates = direction === 'ascending' ? candidates : candidates.reverse();
		return (
			<>
				<TableToolbar
					rightButtonText="Nieuwe kandidaat"
					to="/kandidaten/toevoegen"
					permission={CAN_ADD_CANDIDATES}
				/>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'firstname' ? direction : null}
								onClick={this.handleSort('firstname')}
							>
								Kandidaat
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'state' ? direction : null}
								onClick={this.handleSort('state')}
							>
								Status
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'createdBy' ? direction : null}
								onClick={this.handleSort('createdy')}
							>
								Aangemaakt door
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'createdAt' ? direction : null}
								onClick={this.handleSort('createdAt')}
							>
								Aangemaakt op
							</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{candidates.map(candidate => (
							<CandidateItem
								key={candidate._id}
								candidate={candidate}
								user={this.props.users[candidate.createdBy]}
							/>
						))}
					</Table.Body>
				</Table>
			</>
		);
	}
}

const mapStateToProps = state => {
	const searchFilter = (candidate, value) =>
		candidate.lastname.toLowerCase().includes(value.toLowerCase()) ||
		(candidate.firstname + ' ' + candidate.lastname)
			.toLowerCase()
			.includes(value.toLowerCase());
	let candidates = Object.values(state.candidates).sort((a, b) =>
		a.firstname > b.firstname ? 1 : a.firstname < b.firstname ? -1 : 0,
	);
	candidates = candidates.filter(candidate =>
		searchFilter(candidate, state.search.search ? state.search.search : ''),
	);

	return { candidates, users: state.users };
};

export default connect(
	mapStateToProps,
	{ fetchCandidates: fetchCandidates, fetchUsers: fetchUsers },
)(CandidateList);
