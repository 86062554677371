import React from 'react';
import { deleteReport, fetchReport } from '../../actions/reportsActions';
import Modal from '../Modal';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../Loader';
import history from '../../history';

class ReportDelete extends React.Component {
	componentDidMount() {
		this.props.fetchReport(this.props.match.params.id);
	}

	renderContent = () => {
		if (!this.props.report) return <Loader />;
		return (
			<div>
				<p>
					Ben je zeker dat je rapport:
					<b> "{this.props.report.name}"</b> wil verwijderen?
				</p>
			</div>
		);
	};

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteReport(this.props.match.params.id)}
					className="ui negative button"
				>
					Verwijderen
				</button>
				<Link to="/rapporten" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	render() {
		return (
			<>
				<Modal
					title="Rapport verwijderen"
					content={this.renderContent()}
					actions={this.renderActions()}
					onDismiss={() => history.push('/rapporten')}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { report: state.reports[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchReport: fetchReport, deleteReport: deleteReport },
)(ReportDelete);
