import _ from 'lodash';
import {
	FETCH_QUESTIONNAIRES,
	FETCH_QUESTIONNAIRE,
	DELETE_QUESTIONNAIRE,
	VALIDATE_QUESTIONNAIRE,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_QUESTIONNAIRES:
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_QUESTIONNAIRE:
			return { ...state, [action.payload._id]: action.payload };
		case VALIDATE_QUESTIONNAIRE:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_QUESTIONNAIRE:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
