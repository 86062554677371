import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../Modal';
import Loader from '../Loader';
import { deleteCoreTalent, fetchCoreTalent } from '../../actions/coretalentsActions';
import history from '../../history';

class CoreTalentDelete extends React.Component {
	componentDidMount() {
		this.props.fetchCoreTalent(this.props.match.params.id);
	}

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteCoreTalent(this.props.match.params.id)}
					className="ui negative button"
				>
					Verwijderen
				</button>
				<Link to="/kerntalenten" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	renderContent() {
		return (
			<>
				<div>
					<p>
						<b>
							Ben je zeker dat je het kerntalent "{this.props.coretalent.name}" wil
							verwijderen?
						</b>
					</p>
				</div>
			</>
		);
	}

	render() {
		if (!this.props.coretalent) return <Loader />;
		return (
			<Modal
				onSubmit={this.onSubmit}
				title="KernTalent verwijderen"
				content={this.renderContent()}
				actions={this.renderActions()}
				onDismiss={() => history.push('/kerntalenten')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { coretalent: state.coretalents[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchCoreTalent: fetchCoreTalent, deleteCoreTalent: deleteCoreTalent },
)(CoreTalentDelete);
