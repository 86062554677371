import React from 'react';
import { Route } from 'react-router-dom';

import SubQuestionDelete from '../components/subquestions/SubQuestionDelete';
import subQuestionEdit from '../components/subquestions/SubQuestionEdit';

const SubQuestionRoutes = () => {
	return (
		<>
			<Route path="/subvragen/aanpassen/:id" exact component={subQuestionEdit} />
			<Route path="/subvragen/verwijderen/:id" exact component={SubQuestionDelete} />
		</>
	);
};

export default SubQuestionRoutes;
