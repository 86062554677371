import _ from 'lodash';
import {
	FETCH_QUESTIONS,
	FETCH_QUESTION,
	DELETE_QUESTION,
	EDIT_QUESTION,
	CREATE_QUESTION,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_QUESTIONS:
			//useing _.mapKeys here, to create objects from questions array => easier to use
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_QUESTION:
			return { ...state, [action.payload._id]: action.payload };
		case CREATE_QUESTION:
			return { ...state, [action.payload._id]: action.payload };
		case EDIT_QUESTION:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_QUESTION:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
