import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchSubQuestion, deleteSubQuestion } from '../../actions/subquestionsActions';
import history from '../../history';
import Loader from '../Loader';
import Modal from '../Modal';

class SubQuestionDelete extends React.Component {
	componentDidMount() {
		this.props.fetchSubQuestion(this.props.match.params.id);
	}

	renderContent() {
		return (
			<div>
				<p>
					Ben je zeker dat je de subvraag <b>"{this.props.subquestion.question}"</b> wil
					verwijderen?
				</p>
			</div>
		);
	}

	renderActions() {
		return (
			<>
				<button
					className="ui button negative"
					onClick={() => this.props.deleteSubQuestion(this.props.match.params.id)}
				>
					verwijderen
				</button>
				<Link className="ui button" to="/vragen">
					Annuleren
				</Link>
			</>
		);
	}

	render() {
		if (!this.props.subquestion) return <Loader />;
		return (
			<Modal
				title="Subvraag verwijderen"
				content={this.renderContent()}
				actions={this.renderActions()}
				onDismiss={() => history.push('/vragen')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { subquestion: state.subquestions[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchSubQuestion: fetchSubQuestion, deleteSubQuestion: deleteSubQuestion },
)(SubQuestionDelete);
