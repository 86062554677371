import React from 'react';
import { connect } from 'react-redux';

import { fetchCandidate } from '../../actions/candidatesActions';
import { addDescripton } from '../../actions/ongoingQuestionnaireActions';
import { startQuestionnaire } from '../../actions/ongoingQuestionnaireActions';
import Loader from '../Loader';
import history from '../../history';

import logo from '../../img/wawlogo.svg';
import CoreTalentsDisclaimer from './CoreTalentsDisclaimer';

class QuestionnaireDescription extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			description: '',
		};
	}

	async componentDidMount() {
		await this.props.startQuestionnaire(this.props.match.params.candidateId);
		if (this.props.questionnaire && this.props.questionnaire.description) {
			this.setState({
				description: this.props.questionnaire.description,
			});
		}
		if (!this.props.candidate) {
			this.props.fetchCandidate(this.props.match.params.candidateId);
		}
	}

	goToQuestions = () => {
		this.postDesciption();
		history.push(`/vragenlijst/${this.props.match.params.candidateId}`);
	};

	postDesciption = async () => {
		let questionnaireId = this.props.questionnaire._id;
		await this.props.addDescripton(questionnaireId, this.state.description);
	};

	render() {
		if (!this.props.candidate) return <Loader />;

		return (
			<>
				<div className="questionnaire-header">
					<div className="logo">
						<img alt="logo" src={logo} /> KernTalenten
					</div>
					<div className="name">
						Kandidaat: {this.props.candidate.firstname} {this.props.candidate.lastname}
					</div>
				</div>
				<div className="waw-questionnaire">
					<div className="questionnaire-intro">
						<img alt="logo" src={logo} />
						<h1>Deel 1: Jouw activiteiten als kind</h1>
						<p>
							Noteer hier de activiteiten, spelletjes, speelgoed en interesses die je
							heel leuk vond als kind <b>voor de puberteit</b>.
						</p>
						<p>
							Let op die leeftijdsgrens: wat je later, als puber, graag deed, nemen we
							niet mee in deze vragenlijst.
						</p>
						<form className="ui form">
							<textarea
								className="field"
								type="textarea"
								cols="80"
								rows="15"
								value={this.state.description}
								onChange={event =>
									this.setState({
										description: event.target.value,
									})
								}
								required
							/>
						</form>

						<div className="buttons">
							<div
								className="previous"
								onClick={() =>
									history.push(
										`/vragenlijst/start/${this.props.match.params.candidateId}`,
									)
								}
							>
								Vorige
							</div>
							<div onClick={() => this.goToQuestions()} className="next">
								Volgende >
							</div>
						</div>
					</div>
					<CoreTalentsDisclaimer />
				</div>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		candidate: state.candidates[ownProps.match.params.candidateId],
		questionnaire: state.ongoingQuestionnaire,
	};
};

export default connect(mapStateToProps, {
	fetchCandidate: fetchCandidate,
	startQuestionnaire: startQuestionnaire,
	addDescripton: addDescripton,
})(QuestionnaireDescription);
