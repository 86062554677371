import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import axios from 'axios';

import App from './components/App';
import reducers from './reducers';
import { loadState, saveState } from './localStorage';

const composeEnhancers = /*window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||*/ compose;
const persistedState = loadState();
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(reduxThunk)));

store.subscribe(() => {
	saveState({
		auth: store.getState().auth,
	});
	axios.defaults.headers.common['Authorization'] = store.getState().auth.token;
});

axios.defaults.headers.common['Authorization'] = store.getState().auth.token;

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.querySelector('#root'),
);
