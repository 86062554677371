import axios from 'axios';

const baseUrl = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000';

export const questions = axios.create({
	baseURL: `${baseUrl}/api/questions`,
});

export const candidates = axios.create({
	baseURL: `${baseUrl}/api/candidates`,
});

export const coretalents = axios.create({
	baseURL: `${baseUrl}/api/coretalents`,
});

export const subquestions = axios.create({
	baseURL: `${baseUrl}/api/subquestions`,
});

export const users = axios.create({
	baseURL: `${baseUrl}/api/users`,
});

export const roles = axios.create({
	baseURL: `${baseUrl}/api/roles`,
});

export const questionnaires = axios.create({
	baseURL: `${baseUrl}/api/questionnaires`,
});

export const questionnaire_progress = axios.create({
	baseURL: `${baseUrl}/api/questionnaire/progress`,
});

export const combinations = axios.create({
	baseURL: `${baseUrl}/api/combinations`,
});

export const clusters = axios.create({
	baseURL: `${baseUrl}/api/clusters`,
});

export const reports = axios.create({
	baseURL: `${baseUrl}/api/reports`,
});

export const auth = axios.create({
	baseURL: `${baseUrl}/api/auth`,
});

export const email = axios.create({
	baseURL: `${baseUrl}/api/email`,
});

export const settings = axios.create({
	baseURL: `${baseUrl}/api/settings`,
});
