import {
	FETCH_CORETALENTS,
	FETCH_CORETALENT,
	CREATE_CORETALENT,
	EDIT_CORETALENT,
	DELETE_CORETALENT,
} from './types';
import { coretalents } from '../apis/waw';
import history from '../history';

export const fetchCoreTalents = () => async dispatch => {
	const response = await coretalents.get('/');
	dispatch({
		type: FETCH_CORETALENTS,
		payload: response.data,
	});
};

export const fetchCoreTalent = id => async dispatch => {
	const response = await coretalents.get(`/${id}`);
	dispatch({
		type: FETCH_CORETALENT,
		payload: response.data,
	});
};

export const createCoreTalent = formValues => async dispatch => {
	const response = await coretalents.post('/', { ...formValues });
	dispatch({
		type: CREATE_CORETALENT,
		payload: response.data,
	});
	history.push('/kerntalenten');
};

export const editCoreTalent = (formValues, id) => async dispatch => {
	const response = await coretalents.put(`/${id}`, { ...formValues });
	dispatch({
		type: EDIT_CORETALENT,
		payload: response.data,
	});
	history.push('/kerntalenten');
};

export const deleteCoreTalent = id => async dispatch => {
	await coretalents.delete(`/${id}`);
	dispatch({
		type: DELETE_CORETALENT,
		payload: id,
	});
	history.push('/kerntalenten');
};
