import React from 'react';
import { connect } from 'react-redux';
import { Grid, Container } from 'semantic-ui-react';

import { fetchCluster } from '../../actions/clustersActions';
import { createCombination } from '../../actions/combinationsActions';
import CombinationFrom from './CombinationForm';
import Loader from '../Loader';
//import history from "../../history";

class CombinationCreate extends React.Component {
	componentDidMount() {
		this.props.fetchCluster(this.props.match.params.id);
	}

	onSubmit = formValues => {
		this.props.createCombination(formValues);
	};

	render() {
		if (!this.props.cluster)
			return (
				<Grid padded>
					<Loader />
				</Grid>
			);
		const initialValues = {
			cluster: this.props.cluster._id,
			coreTalents: this.props.cluster.coreTalents.map(talent => ({
				_id: talent._id,
				talent: talent.name,
				code: talent.code,
				strength: 'sterk',
			})),
		};
		return (
			<Grid padded>
				<Container fluid>
					<CombinationFrom onSubmit={this.onSubmit} initialValues={initialValues} />
				</Container>
			</Grid>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		cluster: state.clusters[ownProps.match.params.id],
	};
};

export default connect(
	mapStateToProps,
	{ fetchCluster: fetchCluster, createCombination: createCombination },
)(CombinationCreate);
