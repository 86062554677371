class Util {
	formatDate = date => {
		return new Intl.DateTimeFormat('nl-BE', {
			year: 'numeric',
			month: 'long',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}).format(new Date(date));
	};
}

export default Util;
