import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../css/TableToolbar.css';
import Icon from './Icon';

class TableToolbar extends React.Component {
	renderButton = () => {
		const permissions = this.props.auth.permissions;
		const neededPermission = this.props.permission;
		if (
			neededPermission &&
			permissions &&
			permissions.find(permission => permission === neededPermission)
		) {
			return (
				<Link className="ui button basic" to={this.props.to}>
					<Icon icon="plus" />
					<p>{this.props.rightButtonText}</p>
				</Link>
			);
		}
	};

	render() {
		return <div className="tableOptions">{this.renderButton()}</div>;
	}
}
const mapStateToProps = state => {
	return { auth: state.auth };
};

export default connect(
	mapStateToProps,
	null,
)(TableToolbar);
