import {
	CREATE_USER,
	DELETE_USER,
	FETCH_USER,
	FETCH_USERS,
	EDIT_USER,
	EDIT_USER_PASSWORD,
} from './types';
import { users } from '../apis/waw';
import history from '../history';

export const createUser = formValues => async dispatch => {
	const response = await users.post('/', formValues);
	dispatch({
		type: CREATE_USER,
		payload: response.data,
	});
	history.push('/gebruikers');
};

export const fetchUsers = () => async dispatch => {
	const response = await users.get('/');
	dispatch({
		type: FETCH_USERS,
		payload: response.data,
	});
};

export const fetchUser = id => async dispatch => {
	const response = await users.get(`/${id}`);
	dispatch({
		type: FETCH_USER,
		payload: response.data,
	});
};

export const editUser = (formValues, id) => async dispatch => {
	const response = await users.put(`/${id}`, { ...formValues });
	dispatch({
		type: EDIT_USER,
		payload: response.data,
	});
	history.push('/gebruikers');
};

export const editPassword = (newPassword, id) => async dispatch => {
	const response = await users.put(`/${id}/password`, {
		password: newPassword,
	});
	dispatch({
		type: EDIT_USER_PASSWORD,
		payload: response.data,
	});
};

export const deleteUser = id => async dispatch => {
	await users.delete(`/${id}`);
	dispatch({
		type: DELETE_USER,
		payload: id,
	});
	history.push('/gebruikers');
};
