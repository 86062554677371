import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import Loader from '../Loader';
import { fetchSubQuestionsForPrimaryQuestion } from '../../actions/subquestionsActions';
import Icon from '../Icon';

class SubQuestionList extends React.Component {
	componentDidMount() {
		this.props.fetchSubQuestionsForPrimaryQuestion(this.props.primaryQuestion._id);
	}

	render() {
		if (!this.props.subquestions) return <Loader />;
		return this.props.subquestions.map(sq => (
			<Table.Row key={sq._id} className="waw-table-sub-row">
				<Table.Cell>{sq.question}</Table.Cell>
				<Table.Cell>{sq.coretalent ? sq.coretalent.name : 'Geen KernTalent'}</Table.Cell>
				<Table.Cell collapsing>
					<div className="waw-table-actions">
						<Link to={`/subvragen/aanpassen/${sq._id}`}>
							<Icon icon="edit" />
						</Link>
						<Link to={`/subvragen/verwijderen/${sq._id}`}>
							<Icon icon="delete" />
						</Link>
					</div>
				</Table.Cell>
			</Table.Row>
		));
	}
}

const mapStateToProps = (state, ownProps) => {
	let subquestionsWithRightID = Object.values(state.subquestions).filter(
		subquestion => subquestion.primaryQuestion === ownProps.primaryQuestion._id,
	);
	return { subquestions: subquestionsWithRightID };
};

export default connect(
	mapStateToProps,
	{ fetchSubQuestionsForPrimaryQuestion: fetchSubQuestionsForPrimaryQuestion },
)(SubQuestionList);
