const STATE_LINK_NOT_SENT = 'link_not_sent';
const STATE_LINK_SENT = 'link_sent';
const STATE_QUESTIONNAIRE_STARTED = 'questionnaire_started';
const STATE_QUESTIONNAIRE_COMPLETED = 'questionnaire_completed';
const STATE_ANALYSIS_COMPLETED = 'analysis_completed';
const STATE_RESULTS_SENT = 'results_sent';
const STATE_SNEAK_PREVIEW_SENT = 'sneak_preview_sent';

module.exports = {
	STATE_LINK_NOT_SENT,
	STATE_LINK_SENT,
	STATE_QUESTIONNAIRE_STARTED,
	STATE_QUESTIONNAIRE_COMPLETED,
	STATE_SNEAK_PREVIEW_SENT,
	STATE_ANALYSIS_COMPLETED,
	STATE_RESULTS_SENT,
};
