import React from 'react';
import { connect } from 'react-redux';
import { Grid, Container } from 'semantic-ui-react';

import { editCombination, fetchCombination } from '../../actions/combinationsActions';
import CombinationFrom from './CombinationForm';
//import Modal from "../Modal";
import Loader from '../Loader';
//import history from "../../history";

class CombinationEdit extends React.Component {
	componentDidMount() {
		this.props.fetchCombination(this.props.match.params.id);
	}

	onSubmit = formValues => {
		this.props.editCombination(this.props.match.params.id, formValues);
	};

	render() {
		return (
			<Grid padded>
				{this.props.combination ? (
					<Container fluid>
						<CombinationFrom
							onSubmit={this.onSubmit}
							initialValues={this.props.combination}
						/>
					</Container>
				) : (
					<Loader />
				)}
			</Grid>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		combination: state.combinations[ownProps.match.params.id],
	};
};

export default connect(
	mapStateToProps,
	{ editCombination: editCombination, fetchCombination: fetchCombination },
)(CombinationEdit);
