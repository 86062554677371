import React from 'react';
import { Grid, Confirm } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchReport } from '../../actions/reportsActions';
import { email } from '../../apis/waw';

import EmailForm from '../EmailForm';
import Loader from '../Loader';
import history from '../../history';
import { fetchTexts } from '../../actions/settingsActions';
import { CAN_SEND_EMAILS } from '../../permissions';

class ReportEmail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			host: null,
			errorContent: 'null',
			errorHeader: 'Er ging iets fout!',
			errorButton: 'Ok',
			open: false,
		};
	}

	async componentDidMount() {
		await Promise.all([
			this.props.fetchReport(this.props.match.params.id),
			this.props.fetchTexts(),
		]);

		const hostResponse = await email.get('host');
		const host = hostResponse.data.host;
		this.setState({ host: host });
	}

	show = () => this.setState({ open: true });
	handleConfirm = () => this.setState({ open: false });
	handleCancel = () => this.setState({ open: false });

	onSubmit = async formValues => {
		if (this.checkForErrors(formValues)) return;

		const values = {
			to: formValues.to,
			text: formValues.text,
			subject: formValues.subject,
		};
		const result = await email.post('/report', values);

		if (result.status !== 200) {
			this.setState({
				errorHeader: 'Erg ging iets mis!',
				errorContent: `Er ging iets mis tijdens het verzenden van deze e-mail, kijk je alles nog even na? Als dit blijft gebeuren, contacteer dan een administrator. (Foutcode: ${
					result.status
				})`,
			});
			this.show();
		}
		history.push('/rapporten');
	};

	renderText = () => {
		const report = this.props.report;
		const link = `${this.state.host}/api/reports/${report._id}/download`;
		let text = this.props.mail_client;

		if (true) {
			text = this.replaceText(text, link);
		}
		return text;
	};

	replaceText = (text, link) => {
		/* **vragenlijst_link**, **resultaten_link** **kandidaat_voornaam**, **kandidaat_achternaam**, **gebruiker_voornaam**, **gebruiker_achternaam**   */
		return text
			.replace('**vragenlijst_link**', `<a href="${link}" target="_blank">${link}</a>`)
			.replace('**resultaten_link**', `<a href="${link}" target="_blank">${link}</a>`)
			.replace('**kandidaat_voornaam**', this.props.report.candidate.firstname)
			.replace('**kandidaat_achternaam**', this.props.report.candidate.lastname)
			.replace('**gebruiker_voornaam**', this.props.auth.firstname)
			.replace('**gebruiker_achternaam**', this.props.auth.lastname);
	};

	renderSubject = () => {
		return `KernTalenten rapport: ${this.props.report.candidate.firstname} ${
			this.props.report.candidate.lastname
		}`;
	};

	render() {
		if (!this.checkPermission(CAN_SEND_EMAILS))
			return <div>Je hebt niet genoeg permissies om deze pagina te bekijken.</div>;
		if (!this.props.report || !this.state.host) return <Loader />;
		return (
			<>
				<Grid padded>
					<Grid.Row>
						<Grid.Column width={16}>
							<h1>Verzend een rapport</h1>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16}>
							<EmailForm
								onSubmit={this.onSubmit}
								initialValues={{
									text: this.renderText(),
									subject: this.renderSubject(),
								}}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Confirm
					open={this.state.open}
					header={this.state.errorHeader}
					content={this.state.errorContent}
					cancelButton="Sluiten"
					confirmButton={this.state.errorButton}
					onConfirm={this.handleConfirm}
					onCancel={this.handleConfirm}
				/>
			</>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};

	checkForErrors(values) {
		if (!values.to) {
			this.setState({
				errorHeader: 'Oeps!',
				errorContent: 'Je vergat een ontvanger in te vullen.',
				errorButton: 'Dat klopt, dom van mij!',
			});
			this.show();
			return true;
		}

		if (!values.subject) {
			this.setState({
				errorHeader: 'Oeps!',
				errorContent: 'Je vergat een onderwerp in te vullen.',
				errorButton: 'Dat klopt, dom van mij!',
			});
			this.show();
			return true;
		}

		if (!values.text || values.text.length <= '<p> </p>'.length) {
			this.setState({
				errorHeader: 'Oeps!',
				errorContent:
					'Deze e-mail bevat geen tekst, en dus ook geen link naar het rapport!',
				errorButton: 'Dat klopt, dom van mij!',
			});
			this.show();
			return true;
		}
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		report: state.reports[ownProps.match.params.id],
		auth: state.auth,
		mail_client: state.settings.mail_client,
	};
};

export default connect(
	mapStateToProps,
	{
		fetchReport,
		fetchTexts,
	},
)(ReportEmail);
