import React from 'react';
import { Route } from 'react-router-dom';

import ReportList from '../components/reports/ReportList';
import ReportCreate from '../components/reports/ReportCreate';
import ReportDelete from '../components/reports/ReportDelete';
import ReportEmail from '../components/reports/ReportEmail';

const ReportsRoutes = () => {
	return (
		<>
			<Route path="/rapporten" exact component={ReportList} />
			<Route path="/rapporten/toevoegen" exact component={ReportCreate} />
			<Route path="/rapporten/toevoegen/:id" exact component={ReportCreate} />
			<Route path="/rapporten/verwijderen/:id" exact component={ReportDelete} />
			<Route path="/rapporten/verzenden/:id" exact component={ReportEmail} />
		</>
	);
};

export default ReportsRoutes;
