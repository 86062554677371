import React from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { fetchCandidate } from '../../actions/candidatesActions';
import { email } from '../../apis/waw';

import EmailForm from '../EmailForm';
import Loader from '../Loader';
import history from '../../history';
import { fetchTexts } from '../../actions/settingsActions';
import {
	STATE_QUESTIONNAIRE_COMPLETED,
	STATE_ANALYSIS_COMPLETED,
	STATE_RESULTS_SENT,
	STATE_SNEAK_PREVIEW_SENT,
} from '../CandidateStates';
import { CAN_SEND_EMAILS } from '../../permissions';

class CandidateEmail extends React.Component {
	async componentDidMount() {
		this.props.fetchCandidate(this.props.match.params.id);
		await this.props.fetchTexts();
	}

	onSubmit = async formValues => {
		const values = {
			to: formValues.to,
			text: formValues.text,
			subject: formValues.subject,
			candidate: this.props.candidate,
		};
		//TODO; call API to send the mail --> this will update a candidate. So we might have to add this to the candidate actions and update the current candidate
		await email.post('/', values);
		history.push(`/kandidaten/detail/${this.props.candidate._id}`);
	};

	renderText = () => {
		if (!this.props.candidate || !this.props.auth) return;
		let text = 'leeg';
		let link;
		if (
			this.props.candidate.state === STATE_QUESTIONNAIRE_COMPLETED ||
			this.props.candidate.state === STATE_SNEAK_PREVIEW_SENT
		) {
			link = this.props.candidate.resultLink;
			text = this.replaceText(this.props.mail_sneak_preview, link);
		} else if (
			this.props.candidate.state === STATE_ANALYSIS_COMPLETED ||
			this.props.candidate.state === STATE_RESULTS_SENT
		) {
			link = this.props.candidate.resultLink;
			text = this.replaceText(this.props.mail_results, link);
		} else {
			link = this.props.candidate.questionnaireLink;
			text = this.replaceText(this.props.mail_link, link);
		}
		return text;
	};

	replaceText = (text, link) => {
		/* **vragenlijst_link**, **resultaten_link** **kandidaat_voornaam**, **kandidaat_achternaam**, **gebruiker_voornaam**, **gebruiker_achternaam**   */
		return text
			.replace('**vragenlijst_link**', `<a href="${link}" target="_blank">${link}</a>`)
			.replace('**resultaten_link**', `<a href="${link}" target="_blank">${link}</a>`)
			.replace('**kandidaat_voornaam**', this.props.candidate.firstname)
			.replace('**kandidaat_achternaam**', this.props.candidate.lastname)
			.replace('**gebruiker_voornaam**', this.props.auth.firstname)
			.replace('**gebruiker_achternaam**', this.props.auth.lastname);
	};

	renderSubject = () => {
		if (!this.props.candidate || !this.props.auth) return;
		if (this.props.candidate.state === STATE_QUESTIONNAIRE_COMPLETED) {
			return 'Sneak Preview Talentscan';
		} else if (
			this.props.candidate.state === STATE_ANALYSIS_COMPLETED ||
			this.props.candidate.state === STATE_RESULTS_SENT
		) {
			return 'Jouw WaW Talentscan resultaten';
		} else {
			return 'Jouw Talentscan';
		}
	};

	render() {
		if (!this.props.candidate) return <Loader />;
		if (!this.checkPermission(CAN_SEND_EMAILS))
			return <div>Je hebt niet genoeg permissies om deze pagina te bekijken.</div>;
		if (
			!this.props.mail_link ||
			this.props.mail_link === undefined ||
			!this.props.mail_results ||
			this.props.mail_results === undefined
		) {
			this.props.fetchTexts();
			return <Loader />;
		}

		return (
			<Grid padded>
				<Grid.Row>
					<Grid.Column width={16}>
						<h1>
							Verzend link naar {this.props.candidate.firstname}{' '}
							{this.props.candidate.lastname}
						</h1>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<EmailForm
							candidate={this.props.candidate}
							user={this.props.auth}
							onSubmit={this.onSubmit}
							initialValues={{
								text: this.renderText(),
								subject: this.renderSubject(),
								to: this.props.candidate.email,
							}}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}
const mapStateToProps = (state, ownProps) => {
	return {
		candidate: state.candidates[ownProps.match.params.id],
		auth: state.auth,
		mail_link: state.settings.mail_link,
		mail_results: state.settings.mail_results,
		mail_sneak_preview: state.settings.mail_sneak_preview,
	};
};

export default connect(
	mapStateToProps,
	{
		fetchCandidate,
		fetchTexts,
	},
)(CandidateEmail);
