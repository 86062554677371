import React from 'react';
import { Form as SemanticUIForm } from 'semantic-ui-react';
import { Input, Button, Grid, Icon } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import WysiwygEditor from './inputs/WysiwygEditor';

class EmailForm extends React.Component {
	onSubmit = formValues => {
		this.props.onSubmit(formValues);
	};

	render() {
		return (
			<SemanticUIForm onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<Grid>
					<Grid.Row>
						<Grid.Column>
							<Field
								name="to"
								props={{
									label: 'Ontvanger',
								}}
								component={Input}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Field
								name="subject"
								props={{
									label: 'Onderwerp',
								}}
								component={Input}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Field
								name="text"
								props={{
									rows: 12,
								}}
								component={WysiwygEditor}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16}>
							<Button color="orange">
								<Icon name="send" /> Verzenden
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</SemanticUIForm>
		);
	}
}

EmailForm = reduxForm({
	form: 'emailForm',
})(EmailForm);

export default EmailForm;
