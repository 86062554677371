import _ from 'lodash';
import {
	CREATE_SUB_QUESTION,
	FETCH_SUB_QUESTION,
	EDIT_SUB_QUESTION,
	DELETE_SUB_QUESTION,
	FETCH_SUB_QUESTIONS_FOR_PRIMARY,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case CREATE_SUB_QUESTION:
			return { ...state, [action.payload._id]: action.payload };
		case FETCH_SUB_QUESTIONS_FOR_PRIMARY:
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_SUB_QUESTION:
			return { ...state, [action.payload._id]: action.payload };
		case EDIT_SUB_QUESTION:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_SUB_QUESTION:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
