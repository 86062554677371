import {
	FETCH_CLUSTERS,
	FETCH_CLUSTER,
	DELETE_CLUSTER,
	EDIT_CLUSTER,
	CREATE_CLUSTER,
	FORM_ACTION_FAILED,
} from './types';
import { clusters } from '../apis/waw';
import history from '../history';

export const fetchClusters = () => async dispatch => {
	const response = await clusters.get('/');
	dispatch({
		type: FETCH_CLUSTERS,
		payload: response.data,
	});
};

export const fetchCluster = id => async dispatch => {
	const response = await clusters.get(`/${id}`);
	dispatch({
		type: FETCH_CLUSTER,
		payload: response.data,
	});
};

export const editCluster = (id, formValues) => async dispatch => {
	try {
		const response = await clusters.put(`/${id}`, { ...formValues });
		dispatch({
			type: EDIT_CLUSTER,
			payload: response.data,
		});
		history.push('/combinaties');
	} catch (err) {
		dispatch({
			type: FORM_ACTION_FAILED,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const createCluster = formValues => async dispatch => {
	try {
		const response = await clusters.post('/', { ...formValues });
		dispatch({
			type: CREATE_CLUSTER,
			payload: response.data,
		});
		history.push('/combinaties');
	} catch (err) {
		dispatch({
			type: FORM_ACTION_FAILED,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const deleteCluster = id => async dispatch => {
	await clusters.delete(`/${id}`);
	dispatch({
		type: DELETE_CLUSTER,
		payload: id,
	});
	history.push('/combinaties');
};
