import React from 'react';

class Loader extends React.Component {
	render() {
		return (
			<>
				<div className="ui active inverted dimmer">
					<div className="ui text loader">Laden</div>
				</div>
				<p />
			</>
		);
	}
}

export default Loader;
