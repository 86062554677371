import {
	FETCH_QUESTIONS,
	CREATE_QUESTION,
	EDIT_QUESTION,
	FETCH_QUESTION,
	DELETE_QUESTION,
} from './types';
import { questions } from '../apis/waw';
import history from '../history';

export const fetchQuestions = () => async dispatch => {
	const response = await questions.get('/');
	dispatch({
		type: FETCH_QUESTIONS,
		payload: response.data,
	});
};

export const fetchQuestion = (id, fetchSubQuestions = false) => async dispatch => {
	let config;
	if (fetchSubQuestions) config = { params: { sq: true } };
	const response = await questions.get(`/${id}`, config);
	dispatch({
		type: FETCH_QUESTION,
		payload: response.data,
	});
};

export const createQuestion = formValues => async (dispatch, getState) => {
	const response = await questions.post('/', { ...formValues });
	dispatch({
		type: CREATE_QUESTION,
		payload: response.data,
	});
	history.push('/vragen');
};

export const editQuestion = (id, formValues) => async (dispatch, getState) => {
	const response = await questions.put(`/${id}`, { ...formValues });
	dispatch({
		type: EDIT_QUESTION,
		payload: response.data,
	});
	history.push('/vragen');
};

export const deleteQuestion = id => async dispatch => {
	await questions.delete(`/${id}`);
	dispatch({ type: DELETE_QUESTION, payload: id });
	history.push('/vragen');
};
