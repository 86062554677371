import React from 'react';
import { connect } from 'react-redux';
import { Progress, Confirm, Grid, Container, Responsive, Popup } from 'semantic-ui-react';
import { fetchCandidate } from '../../actions/candidatesActions';
import QuestionnaireOverview from './QuestionnaireOverview';
import QuestionnaireHeader from './QuestionnaireHeader';
import { candidates, questionnaire_progress } from '../../apis/waw';
import Loader from '../Loader';
import history from '../../history';
import CoreTalentsDisclaimer from './CoreTalentsDisclaimer';

class QuestionnaireCompletion extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			shouldResort: false,
		};
	}

	async componentDidMount() {
		await this.props.fetchCandidate(this.props.match.params.candidateId);
	}

	show = () => this.setState({ open: true });
	handleConfirm = () => {
		this.setState({ open: false });
		this.completeQuestionnaire();
	};
	handleCancel = () => this.setState({ open: false });

	render() {
		if (!this.props.candidate) return <Loader />;
		return (
			<>
				<Grid.Row>
					<QuestionnaireHeader candidate={this.props.candidate} />
				</Grid.Row>
				<Grid.Row>
					<Container fluid className="waw-questionnaire">
						<Progress percent={100} progress color="orange" size="large" />
						<div className="overview">{this.renderText()}</div>
						<Container fluid>
							<QuestionnaireOverview
								candidate={this.props.candidate}
								ref="currentOverview"
								update={this.update}
							/>
							<Responsive as={Grid} padded centered width={16} minWidth={700}>
								<Grid.Row>
									<Grid.Column width={8}>
										<div
											className="resort"
											onClick={() => {
												return this.refs.currentOverview.reSort();
											}}
										>
											Opnieuw sorteren
										</div>
									</Grid.Column>
									<Grid.Column width={8}>
										{this.renderConfirmButton(false)}
									</Grid.Column>
								</Grid.Row>
							</Responsive>
							<Responsive as={Grid} padded centered width={16} maxWidth={700}>
								<Grid.Row>
									<Grid.Column width={8}>
										<div
											className="resort mobile-button"
											onClick={() => this.refs.currentOverview.reSort()}
										>
											Opnieuw sorteren
										</div>
									</Grid.Column>
									<Grid.Column width={8}>
										{this.renderConfirmButton(true)}
									</Grid.Column>
								</Grid.Row>
							</Responsive>
						</Container>
						<CoreTalentsDisclaimer />
					</Container>
				</Grid.Row>
				<Confirm
					className="confirmQuestionnaire"
					open={this.state.open}
					header="Staan je lievelingsactiviteiten bovenaan?"
					content="Staan alle activiteiten in de juiste volgorde, gerangschikt van hoog naar laag? Indien dat niet het geval is, verschuif dan de balkjes en gebruik de knop 'opnieuw sorteren' om de nieuwe rangschikking te zien!"
					onCancel={this.handleCancel}
					onConfirm={this.handleConfirm}
					cancelButton="Ik moet nog wat aanpassen"
					confirmButton="Alles staat juist!"
				/>
			</>
		);
	}

	renderConfirmButton = mobile => {
		if (this.state.shouldResort) {
			const cls = mobile ? 'finish gray mobile-button' : 'finish gray';
			return (
				<Popup
					content="Sorteer eerst opnieuw en kijk je rangschikking na."
					trigger={<div className={cls}>Bevestigen ></div>}
				/>
			);
		} else {
			const cls = mobile ? 'finish orange mobile-button' : 'finish orange';
			return (
				<div className={cls} onClick={() => this.show()}>
					Bevestigen >
				</div>
			);
		}
	};

	update = () => {
		if (!this.refs.currentOverview) return;
		const shouldResort = this.refs.currentOverview.state.shouldResort;
		this.setState({ shouldResort: shouldResort });
	};

	renderText = () => {
		return (
			<>
				<h1>Rangschik je leukste activiteiten van hoog naar laag</h1>
				<p>
					Dit is het <strong>belangrijkste onderdeel</strong> van de vragenlijst.
					Corrigeer eventueel de scores per activiteit en geef de hoogste score aan wat je
					het allerliefste deed.
				</p>

				<p>
					Druk onderaan op de knop '<b>opnieuw sorteren</b>' om de nieuwe rangschikking te
					zien.
				</p>
			</>
		);
	};

	completeQuestionnaire = async () => {
		let sliders = this.refs.currentOverview.state.sliders;
		let scores = this.refs.currentOverview.state.coreTalentsScores;

		scores.forEach(score => {
			score.score = sliders.get(score._id);
		});

		await candidates.put(`${this.props.candidate._id}/updatetalents`, Object.values(scores));
		await questionnaire_progress.post(`/${this.props.candidate.questionnaire._id}/complete`);

		history.push(`/vragenlijst/einde/${this.props.candidate._id}`);
	};
}

const mapStateToProps = (state, ownProps) => {
	return {
		candidate: state.candidates[ownProps.match.params.candidateId],
	};
};

export default connect(mapStateToProps, {
	fetchCandidate: fetchCandidate,
})(QuestionnaireCompletion);
