import React from 'react';
import { Route } from 'react-router-dom';

import QuestionnaireIntro from '../components/questionnaire/QuestionnaireIntro';
import Questionnaire from '../components/questionnaire/Questionnaire';
import QuestionnaireDescription from '../components/questionnaire/QuestionnaireDescription';
import QuestionnaireCompletion from '../components/questionnaire/QuestionnaireCompletion';
import QuestionnaireEnd from '../components/questionnaire/QuestionnaireEnd';
import QuestionnaireError from '../components/questionnaire/QuestionnaireError';

class QuestionnaireRoutes extends React.Component {
	render() {
		return (
			<>
				<Route
					path="/vragenlijst/:candidateId"
					exact
					render={props => (
						<Questionnaire {...props} mainContextRef={this.props.mainContextRef} />
					)}
				/>
				<Route
					path="/vragenlijst/start/:candidateId"
					exact
					component={QuestionnaireIntro}
				/>
				<Route
					path="/vragenlijst/beschrijving/:candidateId"
					exact
					component={QuestionnaireDescription}
				/>
				<Route
					path="/vragenlijst/overzicht/:candidateId"
					exact
					component={QuestionnaireCompletion}
				/>
				<Route path="/vragenlijst/einde/:candidateId" exact component={QuestionnaireEnd} />
				<Route path="/vragenlijst/fout" exact component={QuestionnaireError} />
			</>
		);
	}
}

export default QuestionnaireRoutes;
