import React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-rangeslider';
import { Grid, Responsive } from 'semantic-ui-react';
import '../../css/slider.css';
import Loader from '../Loader';
import { fetchCandidate } from '../../actions/candidatesActions';

class QuestionnaireOverview extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sliders: new Map(),
			coreTalentsScores: [],
			shouldResort: false,
		};
	}

	async componentDidMount() {
		await this.props.fetchCandidate(this.props.candidate._id);

		const sliders = new Map();
		this.props.candidate.coreTalentsScores.forEach(cts => {
			sliders.set(cts._id, cts.score);
		});

		this.props.candidate.coreTalentsScores.sort((a, b) => {
			return b.score - a.score;
		});

		this.setState({
			coreTalentsScores: this.props.candidate.coreTalentsScores,
			sliders: sliders,
		});
	}

	reSort = async () => {
		let talentScores = this.state.coreTalentsScores;
		talentScores.forEach(talentScore => {
			talentScore.score = this.state.sliders.get(talentScore._id);
		});

		talentScores.sort((a, b) => {
			return b.score - a.score;
		});

		await this.setState({
			coreTalentsScores: talentScores,
			shouldResort: false,
		});
		this.props.update();
	};

	handleSlide = async (value, id) => {
		let sliders = this.state.sliders;
		sliders.set(id, value);

		await this.setState({
			shouldResort: true,
			sliders: sliders,
		});
		this.props.update();
	};

	renderQuestion = coreTalentScore => {
		const primaryQuestion = coreTalentScore.primaryQuestionShortQuestion
			? coreTalentScore.primaryQuestionShortQuestion
			: coreTalentScore.primaryQuestion;
		const subQuestion = coreTalentScore.shortQuestion
			? coreTalentScore.shortQuestion
			: coreTalentScore.subQuestion;
		return (
			<p>
				<b>{primaryQuestion}: </b>
				{subQuestion}
			</p>
		);
	};

	renderScores = () => {
		let i = 0;
		return this.state.coreTalentsScores.map(coreTalentScore => {
			let c = i % 2 === 0 ? 'even' : 'odd';
			i++;
			return (
				<div className={c} key={coreTalentScore._id}>
					<div className="overview-questions">
						<Responsive as={Grid} minWidth={700}>
							<Grid.Column width={10}>
								{this.renderQuestion(coreTalentScore)}
							</Grid.Column>
							<Grid.Column width={6}>
								<Slider
									min={0}
									max={100}
									labels={{
										0: 'Helemaal niet leuk',
										50: 'Nogal leuk',
										90: 'Super leuk',
									}}
									value={this.state.sliders.get(coreTalentScore._id)}
									onChange={e => {
										this.handleSlide(e, coreTalentScore._id);
									}}
								/>
								<div className="sliderTick sliderLeftTick" />
								<div className="sliderTick sliderRightTick" />
							</Grid.Column>
							<div className="clear" />
						</Responsive>
						<Responsive as={Grid} maxWidth={700}>
							<Grid.Row width={10}>
								<p>
									<b>{coreTalentScore.primaryQuestion}: </b>
									{coreTalentScore.subQuestion}
								</p>
							</Grid.Row>
							<Grid.Row width={6}>
								<Grid.Column>
									<Slider
										min={0}
										max={100}
										value={this.state.sliders.get(coreTalentScore._id)}
										onChange={e => {
											this.handleSlide(e, coreTalentScore._id);
										}}
									/>
									<div className="sliderTick sliderLeftTick" />
									<div className="sliderTick sliderRightTick" />
								</Grid.Column>
							</Grid.Row>
							<div className="clear" />
						</Responsive>
					</div>
				</div>
			);
		});
	};

	render() {
		if (!this.state.sliders) return <Loader />;
		if (!this.state.coreTalentsScores) return <Loader />;
		return <div className="subquestions">{this.renderScores()}</div>;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		candidate: state.candidates[ownProps.candidate._id],
	};
};

export default connect(
	mapStateToProps,
	{
		fetchCandidate: fetchCandidate,
	},
	null,
	{ forwardRef: true },
)(QuestionnaireOverview);
