import { FETCH_TEXT, FETCH_TEXTS, CHANGE_TEXT } from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case CHANGE_TEXT:
			return { ...state };
		case FETCH_TEXT:
			return { ...state, [action.payload.text]: action.payload };
		case FETCH_TEXTS:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
