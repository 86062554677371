import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../Modal';
import Loader from '../Loader';
import { deleteCombination, fetchCombination } from '../../actions/combinationsActions';
import history from '../../history';

class CombinationDelete extends React.Component {
	componentDidMount() {
		this.props.fetchCombination(this.props.match.params.id);
	}

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteCombination(this.props.match.params.id)}
					className="ui negative button"
				>
					Verwijderen
				</button>
				<Link to="/combinaties" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	renderContent() {
		return (
			<>
				<div>
					<p>
						<b>
							Ben je zeker dat je de combinatie "{this.props.combination.name}" wil
							verwijderen?
						</b>
					</p>
				</div>
			</>
		);
	}

	render() {
		if (!this.props.combination) return <Loader />;
		return (
			<Modal
				onSubmit={this.onSubmit}
				title="Combinatie verwijderen"
				content={this.renderContent()}
				actions={this.renderActions()}
				onDismiss={() => history.push('/combinaties')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { combination: state.combinations[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchCombination: fetchCombination, deleteCombination: deleteCombination },
)(CombinationDelete);
