import React from 'react';
import { Route } from 'react-router-dom';

import QuestionList from '../components/questions/QuestionList';
import QuestionCreate from '../components/questions/QuestionCreate';
import QuestionEdit from '../components/questions/QuestionEdit';
import QuestionDelete from '../components/questions/QuestionDelete';

import SubQuestionCreate from '../components/subquestions/SubQuestionCreate';

const QuestionRoutes = () => {
	return (
		<>
			<Route path="/vragen" exact component={QuestionList} />
			<Route path="/vragen/toevoegen" exact component={QuestionCreate} />
			<Route path="/vragen/aanpassen/:id" exact component={QuestionEdit} />
			<Route path="/vragen/verwijderen/:id" exact component={QuestionDelete} />
			<Route path="/vragen/subvraag/:id" exact component={SubQuestionCreate} />
		</>
	);
};

export default QuestionRoutes;
