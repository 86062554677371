import React from 'react';
import { Route } from 'react-router-dom';
import UserList from '../components/users/UserList';
import UserCreate from '../components/users/UserCreate';
import UserDelete from '../components/users/UserDelete';
import UserEdit from '../components/users/UserEdit';

const UsersRoutes = () => {
	return (
		<>
			<Route path="/gebruikers" exact component={UserList} />
			<Route path="/gebruikers/toevoegen" exact component={UserCreate} />
			<Route path="/gebruikers/aanpassen/:id" exact component={UserEdit} />
			<Route path="/gebruikers/verwijderen/:id" exact component={UserDelete} />
			<Route path="/gebruikers/:id" />
		</>
	);
};
export default UsersRoutes;
