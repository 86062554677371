import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Loader from '../Loader';

class CandidateDetailAnswersForm extends React.Component {
	onSubmit = formValues => {
		const answers = this.changedFields(formValues).map(answer => ({
			_id: answer._id,
			analistAdjustedScore: answer.analistAdjustedScore,
		}));
		this.props.onSubmit(answers);
	};

	changedFields = formValues => {
		const values = formValues ? formValues.answers : this.props.formValues.values.answers;
		const initial = this.props.formValues.initial.answers;
		return values.filter(
			(value, key) => !(value.analistAdjustedScore === initial[key].analistAdjustedScore),
		);
	};

	renderAnswers = ({ fields, meta: { error } }) => {
		const answers = fields.getAll();
		return (
			<Table.Body>
				{fields.map((answer, index) => {
					return (
						<Table.Row key={index}>
							<Table.Cell>{answers[index].coreTalentCode}</Table.Cell>
							<Table.Cell>
								<b>{answers[index].primaryQuestion}</b>: {answers[index].question}
							</Table.Cell>
							<Table.Cell>
								{answers[index].neverDoneThat
									? 'Niet van toepassing'
									: answers[index].score}
								{answers[index].scoreBeforeOverviewAdjustment
									? ` (${answers[index].scoreBeforeOverviewAdjustment})`
									: null}
							</Table.Cell>
							<Table.Cell>
								<Field
									name={`${answer}.analistAdjustedScore`}
									className="waw-table-input no-spinners"
									component="input"
									type="number"
									onChange={this.fieldChanged}
									parse={value => (value ? Number(value) : null)}
									min="-1"
									max="100"
								/>
							</Table.Cell>
						</Table.Row>
					);
				})}
			</Table.Body>
		);
	};

	render() {
		if (!this.props.formValues) return <Loader />;
		const changedFields = this.changedFields();
		return (
			<>
				<form
					onSubmit={this.props.handleSubmit(this.onSubmit)}
					className={`${this.props.dirty ? 'waw-bottom-active' : ''}`}
				>
					<Table sortable celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>KernTalent</Table.HeaderCell>
								<Table.HeaderCell>Vraag</Table.HeaderCell>
								<Table.HeaderCell>Antwoord</Table.HeaderCell>
								<Table.HeaderCell>Aanpassing</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<FieldArray name="answers" component={this.renderAnswers} />
					</Table>
					<div className="waw-bottom-fixed">
						<div>
							{`${changedFields.length} ${
								changedFields.length > 1 ? 'wijzigingen' : 'wijziging'
							} nog niet opgeslagen`}
						</div>
						<div>
							<button type="submit" className="ui button primary">
								Opslaan
							</button>
						</div>
					</div>
				</form>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		formValues: state.form.answersForm,
	};
};

CandidateDetailAnswersForm = reduxForm({
	form: 'answersForm',
	enableReinitialize: true,
})(CandidateDetailAnswersForm);

export default connect(mapStateToProps)(CandidateDetailAnswersForm);
