import React from 'react';
import { connect } from 'react-redux';
import { fetchRole, editRole } from '../../actions/rolesActions';
import Loader from '../Loader';
import RoleForm from './RoleForm';
import Modal from '../Modal';
import history from '../../history';

class RoleEdit extends React.Component {
	componentDidMount() {
		this.props.fetchRole(this.props.match.params.id);
	}

	onSubmit = formValues => {
		this.props.editRole(formValues, this.props.match.params.id);
	};

	renderForm = () => {
		return <RoleForm onSubmit={this.onSubmit} initialValues={this.props.role} />;
	};

	render() {
		if (!this.props.role) return <Loader />;
		return (
			<Modal
				title="Rol aanpassen"
				content={this.renderForm()}
				onDismiss={() => {
					history.push('/voorkeuren');
				}}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { role: state.roles[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchRole: fetchRole, editRole: editRole },
)(RoleEdit);
