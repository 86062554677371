import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import _ from 'lodash';
import CheckboxInput from '../inputs/CheckboxInput';
import {
	CAN_VIEW_CANDIDATES,
	CAN_ADD_CANDIDATES,
	CAN_EDIT_CANDIDATES,
	CAN_DELETE_CANDIDATES,
	CAN_VIEW_CORETALENTS,
	CAN_ADD_CORETALENTS,
	CAN_EDIT_CORETALENTS,
	CAN_DELETE_CORETALENTS,
	CAN_VIEW_COMBINATIONS,
	CAN_ADD_COMBINATIONS,
	CAN_EDIT_COMBINATIONS,
	CAN_DELETE_COMBINATIONS,
	CAN_VIEW_QUESTIONS,
	CAN_ADD_QUESTIONS,
	CAN_EDIT_QUESTIONS,
	CAN_DELETE_QUESTIONS,
	CAN_VIEW_REPORTS,
	CAN_ADD_REPORTS,
	CAN_EDIT_REPORTS,
	CAN_DELETE_REPORTS,
	CAN_VIEW_USERS,
	CAN_ADD_USERS,
	CAN_EDIT_USERS,
	CAN_DELETE_USERS,
	CAN_VIEW_ROLES,
	CAN_ADD_ROLES,
	CAN_EDIT_ROLES,
	CAN_DELETE_ROLES,
	CAN_VIEW_SETTINGS,
	CAN_EDIT_SETTINGS,
	CAN_SEND_EMAILS,
	CAN_VALIDATE_QUESTIONNAIRES,
} from '../../permissions';

class RoleForm extends React.Component {
	onSubmit = formValues => {
		this.props.onSubmit(_.omit(formValues, '_id', '__v'));
	};

	render() {
		return (
			<form className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<div className="waw-error">{this.props.error}</div>
				<div className="field">
					<label>Naam</label>
					<Field name="name" component="input" type="text" required />
				</div>
				<Grid>
					<Grid.Row>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan kandidaten:</label>
								{this.renderCandidatesCBs()}
							</div>
						</Grid.Column>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan KernTalenten:</label>
								{this.renderCoreTalentsCBs()}
							</div>
						</Grid.Column>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan combinaties:</label>
								{this.renderCombinationsCBs()}
							</div>
						</Grid.Column>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan vragen:</label>
								{this.renderQuestionsCBs()}
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan rapporten:</label>
								{this.renderReportsCBs()}
							</div>
						</Grid.Column>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan gebruikers:</label>
								{this.renderUsersCBs()}
							</div>
						</Grid.Column>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan rollen:</label>
								{this.renderRolesCBs()}
							</div>
						</Grid.Column>
						<Grid.Column width={4}>
							<div className="field">
								<label>Kan voorkeuren:</label>
								{this.renderSettingsCBs()}
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<div className="waw-flex waw-end">
					<button type="submit" className="ui button primary">
						Opslaan
					</button>
				</div>
			</form>
		);
	}

	renderCandidatesCBs = () => {
		return (
			<>
				<div className="field">
					<Field name={CAN_VIEW_CANDIDATES} component={CheckboxInput} label="Bekijken" />
				</div>
				<div className="field">
					<Field name={CAN_ADD_CANDIDATES} component={CheckboxInput} label="Toevoegen" />
				</div>
				<div className="field">
					<Field name={CAN_EDIT_CANDIDATES} component={CheckboxInput} label="Bewerken" />
				</div>
				<div className="field">
					<Field
						name={CAN_DELETE_CANDIDATES}
						component={CheckboxInput}
						label="Verwijderen"
					/>
				</div>
				<div className="field">
					<Field
						name={CAN_SEND_EMAILS}
						component={CheckboxInput}
						label="E-mails versturen"
					/>
				</div>
			</>
		);
	};

	renderCoreTalentsCBs = () => {
		return (
			<>
				<div className="field">
					<Field name={CAN_VIEW_CORETALENTS} component={CheckboxInput} label="Bekijken" />
				</div>
				<div className="field">
					<Field name={CAN_ADD_CORETALENTS} component={CheckboxInput} label="Toevoegen" />
				</div>
				<div className="field">
					<Field name={CAN_EDIT_CORETALENTS} component={CheckboxInput} label="Bewerken" />
				</div>
				<div className="field">
					<Field
						name={CAN_DELETE_CORETALENTS}
						component={CheckboxInput}
						label="Verwijderen"
					/>
				</div>
			</>
		);
	};

	renderCombinationsCBs = () => {
		return (
			<>
				<div className="field">
					<Field
						name={CAN_VIEW_COMBINATIONS}
						component={CheckboxInput}
						label="Bekijken"
					/>
				</div>
				<div className="field">
					<Field
						name={CAN_ADD_COMBINATIONS}
						component={CheckboxInput}
						label="Toevoegen"
					/>
				</div>
				<div className="field">
					<Field
						name={CAN_EDIT_COMBINATIONS}
						component={CheckboxInput}
						label="Bewerken"
					/>
				</div>
				<div className="field">
					<Field
						name={CAN_DELETE_COMBINATIONS}
						component={CheckboxInput}
						label="Verwijderen"
					/>
				</div>
			</>
		);
	};

	renderQuestionsCBs = () => {
		return (
			<>
				<div className="field">
					<Field name={CAN_VIEW_QUESTIONS} component={CheckboxInput} label="Bekijken" />
				</div>
				<div className="field">
					<Field name={CAN_ADD_QUESTIONS} component={CheckboxInput} label="Toevoegen" />
				</div>
				<div className="field">
					<Field name={CAN_EDIT_QUESTIONS} component={CheckboxInput} label="Bewerken" />
				</div>
				<div className="field">
					<Field
						name={CAN_DELETE_QUESTIONS}
						component={CheckboxInput}
						label="Verwijderen"
					/>
				</div>
			</>
		);
	};

	renderReportsCBs = () => {
		return (
			<>
				<div className="field">
					<Field name={CAN_VIEW_REPORTS} component={CheckboxInput} label="Bekijken" />
				</div>
				<div className="field">
					<Field name={CAN_ADD_REPORTS} component={CheckboxInput} label="Toevoegen" />
				</div>
				<div className="field">
					<Field name={CAN_EDIT_REPORTS} component={CheckboxInput} label="Bewerken" />
				</div>
				<div className="field">
					<Field
						name={CAN_DELETE_REPORTS}
						component={CheckboxInput}
						label="Verwijderen"
					/>
				</div>
				<div className="field">
					<Field
						name={CAN_VALIDATE_QUESTIONNAIRES}
						component={CheckboxInput}
						label="Testresultaten vrijgeven"
					/>
				</div>
			</>
		);
	};

	renderUsersCBs = () => {
		return (
			<>
				<div className="field">
					<Field name={CAN_VIEW_USERS} component={CheckboxInput} label="Bekijken" />
				</div>
				<div className="field">
					<Field name={CAN_ADD_USERS} component={CheckboxInput} label="Toevoegen" />
				</div>
				<div className="field">
					<Field name={CAN_EDIT_USERS} component={CheckboxInput} label="Bewerken" />
				</div>
				<div className="field">
					<Field name={CAN_DELETE_USERS} component={CheckboxInput} label="Verwijderen" />
				</div>
			</>
		);
	};

	renderRolesCBs = () => {
		return (
			<>
				<div className="field">
					<Field name={CAN_VIEW_ROLES} component={CheckboxInput} label="Bekijken" />
				</div>
				<div className="field">
					<Field name={CAN_ADD_ROLES} component={CheckboxInput} label="Toevoegen" />
				</div>
				<div className="field">
					<Field name={CAN_EDIT_ROLES} component={CheckboxInput} label="Bewerken" />
				</div>
				<div className="field">
					<Field name={CAN_DELETE_ROLES} component={CheckboxInput} label="Verwijderen" />
				</div>
			</>
		);
	};
	renderSettingsCBs = () => {
		return (
			<>
				<div className="field">
					<Field name={CAN_VIEW_SETTINGS} component={CheckboxInput} label="Bekijken" />
				</div>

				<div className="field">
					<Field name={CAN_EDIT_SETTINGS} component={CheckboxInput} label="Bewerken" />
				</div>
			</>
		);
	};
}

RoleForm = reduxForm({
	form: 'roleForm',
})(RoleForm);

export default RoleForm;
