import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { editQuestion, fetchQuestion } from '../../actions/questionsActions';
import QuestionForm from './QuestionForm';
import Modal from '../Modal';
import Loader from '../Loader';
import history from '../../history';

class QuestionEdit extends React.Component {
	componentDidMount() {
		this.props.fetchQuestion(this.props.match.params.id);
	}

	onSubmit = ({ coretalent, isTextarea, question, shortQuestion }) => {
		const { match } = this.props;
		const updateObject = {
			coretalent: coretalent,
			isTextarea: isTextarea ? isTextarea : false,
			question: question ? question.trim() : '',
			shortQuestion: shortQuestion ? shortQuestion.trim() : ''
		};
		this.props.editQuestion(match.params.id, updateObject);
	};

	renderForm = () => {
		return (
			<QuestionForm
				onSubmit={this.onSubmit}
				initialValues={_.pick(
					this.props.question,
					'question',
					'coretalent',
					'shortQuestion',
					'isTextarea'
				)}
			/>
		);
	};

	render() {
		if (!this.props.question) return <Loader />;
		return (
			<>
				<Modal
					title="Vraag aanpassen"
					content={this.renderForm()}
					onDismiss={() => history.push('/vragen')}
				/>
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { question: state.questions[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ editQuestion: editQuestion, fetchQuestion: fetchQuestion },
)(QuestionEdit);
