import _ from 'lodash';
import {
	FETCH_COMBINATIONS_OF_CLUSTER,
	FETCH_COMBINATION,
	DELETE_COMBINATION,
	EDIT_COMBINATION,
	CREATE_COMBINATION,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case FETCH_COMBINATIONS_OF_CLUSTER:
			return { ...state, ..._.mapKeys(action.payload, '_id') };
		case FETCH_COMBINATION:
			return { ...state, [action.payload._id]: action.payload };
		case EDIT_COMBINATION:
			return { ...state, [action.payload._id]: action.payload };
		case CREATE_COMBINATION:
			return { ...state, [action.payload._id]: action.payload };
		case DELETE_COMBINATION:
			return _.omit(state, action.payload);
		default:
			return state;
	}
};
