import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { fetchCoreTalents } from '../../actions/coretalentsActions';
import Loader from '../Loader';

const required = value => (value || typeof value === 'number' ? undefined : 'Dit veld mag niet leeg zijn!')

class QuestionForm extends React.Component {
	constructor(props) {
		super(props);
		let ta = this.props.initialValues ? this.props.initialValues.isTextarea : false;
		this.state = {
			isTextarea: ta,
		};
	}

	onSubmit = formValues => {
		if (formValues.coretalent._id) {
			const id = formValues.coretalent._id;
			formValues.coretalent = id;
		}
		formValues.isTextarea = this.state.isTextarea;
		this.props.onSubmit(formValues);
	};

	componentDidMount() {
		this.props.fetchCoreTalents();
	}

	renderTextarea = ({
		input,
		label,
		type,
		meta: { touched, error, warning }
	}) => (
			<div>
				<div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
					<label>{label}</label>
				</div>
				<div>
					<textarea {...input} placeholder={label} type={type} />
					{touched &&
						((error && <span style={{ color: 'red' }}>{error}</span>) ||
							(warning && <span>{warning}</span>))}
				</div>
			</div>
		);

	renderSelect = ({
		input,
		label,
		type,
		meta: { touched, error, warning }
	}) => (
			<div>
				<div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
					<label>{label}</label>
				</div>
				<div>
					<select {...input}
						style={{ width: '500px' }}
						placeholder={label}
						type={type} >
						<option value={input.value._id} key={input.value._id}>
							{input.value.name}
						</option>
						{this.props.coretalents.map(coretalent => {
							if (coretalent._id === input.value._id) return null;
							return (
								<option value={coretalent._id} key={coretalent._id}>
									{coretalent.name}
								</option>
							);
						})}
					</select>
					{touched &&
						((error && <span style={{ color: 'red' }}>{error}</span>) ||
							(warning && <span>{warning}</span>))}
				</div>
			</div>
		)

	render() {
		if (!this.props.coretalents) return <Loader />;
		const hidden = {
			visibility: 'hidden',
		};
		return (
			<form className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<Field name="question" label="Vraag" type="text" component={this.renderTextarea} validate={[required]} />
				<Field name="shortQuestion" label="Verkorte versie" component={this.renderTextarea} />
				<Field name="coretalent" component={this.renderSelect} label="KernTalent" validate={[required]} />
				<Field name="isTextarea" component="input" value="false" style={hidden} />
				<br />
				<button type="submit" className="ui button primary">
					Opslaan
				</button>
			</form>
		);
	}
}

const mapStateToProps = state => {
	return { coretalents: Object.values(state.coretalents) };
};

QuestionForm = reduxForm({
	form: 'questionForm',
})(QuestionForm);

QuestionForm = connect(
	mapStateToProps,
	{ fetchCoreTalents: fetchCoreTalents },
)(QuestionForm);

export default QuestionForm;
