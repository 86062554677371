import React from 'react';
import { connect } from 'react-redux';
import { fetchRoles } from '../../actions/rolesActions';
class RoleDetail extends React.Component {
	render() {
		// TODO:
		return null;
	}
}

const mapStateToProps = state => {};

export default connect(
	mapStateToProps,
	{ fetchRoles: fetchRoles },
)(RoleDetail);
