import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CombinationList from './CombinationList';
import Icon from '../Icon';
import {
	CAN_DELETE_COMBINATIONS,
	CAN_EDIT_COMBINATIONS,
	CAN_ADD_COMBINATIONS,
} from '../../permissions';

class ClusterItem extends React.Component {
	constructor() {
		super();
		this.state = {
			showCombinations: false,
		};
	}

	renderCombinations() {
		this.setState({ showCombinations: !this.state.showCombinations });
	}

	renderEditAction = cluster => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_EDIT_COMBINATIONS)) {
			return (
				<Link to={`/clusters/aanpassen/${cluster._id}`}>
					<Icon icon="edit" />
				</Link>
			);
		}
	};

	renderDeleteAction = cluster => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_DELETE_COMBINATIONS)) {
			return (
				<Link to={`/clusters/verwijderen/${cluster._id}`}>
					<Icon icon="delete" />
				</Link>
			);
		}
	};

	renderAddCombinationAction = cluster => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_ADD_COMBINATIONS)) {
			return (
				<Link to={`/combinaties/toevoegen/${cluster._id}`}>
					<Icon icon="plus" />
				</Link>
			);
		}
	};

	render() {
		const cluster = this.props.cluster;
		return (
			<>
				<Table.Row>
					<Table.Cell onClick={() => this.renderCombinations()}>
						{cluster.name}
					</Table.Cell>
					<Table.Cell onClick={() => this.renderCombinations()}>
						<div className="waw-flex">
							{cluster.coreTalents.map(talent => (
								<div key={cluster._id + talent._id} className="waw-flex-1">
									{talent.code}
								</div>
							))}
						</div>
					</Table.Cell>
					<Table.Cell collapsing>
						<div className="waw-table-actions">
							{this.renderAddCombinationAction(cluster)}
							{this.renderEditAction(cluster)}
							{this.renderDeleteAction(cluster)}
						</div>
					</Table.Cell>
				</Table.Row>
				{this.state.showCombinations && (
					<CombinationList clusterId={this.props.cluster._id} />
				)}
			</>
		);
	}
}
const mapStateToProps = state => {
	return { auth: state.auth };
};

export default connect(
	mapStateToProps,
	null,
)(ClusterItem);
