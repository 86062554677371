import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchUser, editUser } from '../../actions/usersActions';
import Modal from '../Modal';
import Loader from '../Loader';
import history from '../../history';
import UserForm from './UserForm';

class UserEdit extends React.Component {
	componentDidMount() {
		this.props.fetchUser(this.props.match.params.id);
	}

	onSubmit = formValues => {
		this.props.editUser(formValues, this.props.match.params.id);
	};

	renderContent() {
		if (!this.props.user) return <Loader />;
		return (
			<UserForm
				onSubmit={this.onSubmit}
				initialValues={_.pick(
					this.props.user,
					'firstname',
					'lastname',
					'email',
					'phone',
					'role',
				)}
				passwordRequired={false}
				queryId={this.props.match.params.id}
			/>
		);
	}

	render() {
		return (
			<Modal
				title="Gebruiker aanpassen"
				content={this.renderContent()}
				onDismiss={() => history.push('/gebruikers')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { user: state.users[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{
		fetchUser: fetchUser,
		editUser: editUser,
	},
)(UserEdit);
