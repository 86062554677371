import {
	LOGIN_FAILED,
	FORM_ACTION_FAILED,
	CLEAR_ERRORS,
	CANDIDATE_NOT_FOUND,
	QUESTIONNAIRE_ERROR,
} from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case LOGIN_FAILED:
			return { ...state, ...action.payload };
		case FORM_ACTION_FAILED:
			return { ...state, ...action.payload };
		case CANDIDATE_NOT_FOUND:
			return { ...state, ...action.payload };
		case QUESTIONNAIRE_ERROR:
			return { ...state, ...action.payload };
		case CLEAR_ERRORS:
			return { ...state, undefined };
		default:
			return state;
	}
};
