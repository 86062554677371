import {
	FETCH_QUESTIONNAIRE,
	FETCH_QUESTIONNAIRES,
	DELETE_QUESTIONNAIRE,
	VALIDATE_QUESTIONNAIRE,
	QUESTIONNAIRE_ERROR,
} from './types';
import { questionnaires } from '../apis/waw';
import history from '../history';

export const fetchQuestionnaire = id => async dispatch => {
	try {
		const response = await questionnaires.get(`/${id}`);
		dispatch({
			type: FETCH_QUESTIONNAIRE,
			payload: response.data,
		});
	} catch (err) {
		dispatch({
			type: QUESTIONNAIRE_ERROR,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const fetchQuestionnaires = () => async dispatch => {
	try {
		const response = await questionnaires.get('/');
		dispatch({
			type: FETCH_QUESTIONNAIRES,
			payload: response.data,
		});
	} catch (err) {
		dispatch({
			type: QUESTIONNAIRE_ERROR,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const validateQuestionnaire = id => async dispatch => {
	try {
		const response = await questionnaires.post(`/${id}/validate`);
		dispatch({
			type: VALIDATE_QUESTIONNAIRE,
			payload: response.data,
		});
	} catch (err) {
		dispatch({
			type: QUESTIONNAIRE_ERROR,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};

export const deleteQuestionnaire = id => async dispatch => {
	try {
		await questionnaires.delete(`/${id}`);
		dispatch({
			type: DELETE_QUESTIONNAIRE,
			payload: id,
		});
		history.push('/vragenlijsten');
	} catch (err) {
		dispatch({
			type: QUESTIONNAIRE_ERROR,
			payload: {
				error: err.response.data.error,
			},
		});
	}
};
