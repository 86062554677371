import React from 'react';
import { Route } from 'react-router-dom';

import CandidateList from '../components/candidates/CandidateList';
import CandidateEdit from '../components/candidates/CandidateEdit';
import CandidateCreate from '../components/candidates/CandidateCreate';
import CandidateDelete from '../components/candidates/CandidateDelete';
import CandidateDetail from '../components/candidates/CandidateDetail';
import CandidateEmail from '../components/candidates/CandidateEmail';

const CandidatesRoutes = () => {
	return (
		<>
			<Route path="/" exact component={CandidateList} />
			<Route path="/kandidaten" exact component={CandidateList} />
			<Route path="/kandidaten/toevoegen" exact component={CandidateCreate} />
			<Route path="/kandidaten/detail/:id" exact component={CandidateDetail} />
			<Route path="/kandidaten/verwijderen/:id" exact component={CandidateDelete} />
			<Route path="/kandidaten/aanpassen/:id" exact component={CandidateEdit} />
			<Route path="/kandidaten/email/:id" exact component={CandidateEmail} />
		</>
	);
};

export default CandidatesRoutes;
