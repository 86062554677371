import React from 'react';
import { Route } from 'react-router-dom';
import Preferences from '../components/Preferences';
import TextEdit from '../components/settings/TextEdit';

const PreferencesRoutes = () => {
	return (
		<>
			<Route path="/voorkeuren" exact component={Preferences} />
			<Route path="/voorkeuren/:key" exact component={TextEdit} />
		</>
	);
};

export default PreferencesRoutes;
