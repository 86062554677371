import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Grid } from 'semantic-ui-react';
import '../css/Menu.css';
import Icon from './Icon';
import {
	CAN_VIEW_CANDIDATES,
	CAN_VIEW_COMBINATIONS,
	CAN_VIEW_CORETALENTS,
	CAN_VIEW_QUESTIONS,
	CAN_VIEW_REPORTS,
	CAN_VIEW_ROLES,
	CAN_VIEW_SETTINGS,
	CAN_VIEW_USERS,
} from '../permissions';

class WaWMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeItem: 'candidates',
		};
	}

	handleItemClick = (e, { name }) => this.setState({ activeItem: name });

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}

		return false;
	};

	render() {
		return (
			<Grid.Column width={2} className="waw-menu">
				<Menu vertical text>
					{this.checkPermission(CAN_VIEW_CANDIDATES) ? this.renderCandidatesLink() : null}
					{this.checkPermission(CAN_VIEW_REPORTS) ? this.renderReportsLink() : null}
					{this.checkPermission(CAN_VIEW_QUESTIONS) ? this.renderQuestionsLink() : null}
					{this.checkPermission(CAN_VIEW_CORETALENTS)
						? this.renderCoreTalentsLink()
						: null}
					{this.checkPermission(CAN_VIEW_COMBINATIONS)
						? this.renderCombinationsLink()
						: null}
					{this.checkPermission(CAN_VIEW_USERS) ? this.renderUsersLink() : null}
					{this.checkPermission(CAN_VIEW_SETTINGS) || this.checkPermission(CAN_VIEW_ROLES)
						? this.renderSettingsLink()
						: null}
				</Menu>
			</Grid.Column>
		);
	}

	renderCandidatesLink = () => {
		const { activeItem } = this.state;
		return (
			<Menu.Item
				as={Link}
				to="/Kandidaten"
				className="waw-menu-item"
				name="candidates"
				active={activeItem === 'candidates'}
				onClick={this.handleItemClick}
			>
				<Icon
					icon={
						activeItem === 'candidates'
							? 'candidates_heading_blue'
							: 'candidates_heading_white'
					}
					width="2.7em"
					height="2.7em"
				/>
				<h3>Kandidaten</h3>
			</Menu.Item>
		);
	};

	renderReportsLink = () => {
		const { activeItem } = this.state;
		return (
			<Menu.Item
				as={Link}
				to="/rapporten"
				className="waw-menu-item"
				name="reports"
				active={activeItem === 'reports'}
				onClick={this.handleItemClick}
			>
				<Icon
					icon={
						activeItem === 'reports' ? 'reports_heading_blue' : 'reports_heading_white'
					}
					width="2.7em"
					height="2.7em"
				/>
				<h3>Rapporten</h3>
			</Menu.Item>
		);
	};

	renderQuestionsLink = () => {
		const { activeItem } = this.state;
		return (
			<Menu.Item
				as={Link}
				to="/vragen"
				name="questions"
				className="waw-menu-item"
				active={activeItem === 'questions'}
				onClick={this.handleItemClick}
			>
				<Icon
					icon={
						activeItem === 'questions'
							? 'questions_heading_blue'
							: 'questions_heading_white'
					}
					width="2.7em"
					height="2.7em"
				/>
				<h3>Vragen</h3>
			</Menu.Item>
		);
	};

	renderCoreTalentsLink = () => {
		const { activeItem } = this.state;
		return (
			<Menu.Item
				as={Link}
				to="/kerntalenten"
				name="coretalents"
				className="waw-menu-item"
				active={activeItem === 'coretalents'}
				onClick={this.handleItemClick}
			>
				<Icon
					icon={
						activeItem === 'coretalents'
							? 'coretalents_heading_blue'
							: 'coretalents_heading_white'
					}
					width="2.7em"
					height="2.7em"
				/>
				<h3>KernTalenten</h3>
			</Menu.Item>
		);
	};

	renderCombinationsLink = () => {
		const { activeItem } = this.state;
		return (
			<Menu.Item
				as={Link}
				to="/combinaties"
				name="combinations"
				className="waw-menu-item"
				active={activeItem === 'combinations'}
				onClick={this.handleItemClick}
			>
				<Icon
					icon={
						activeItem === 'combinations'
							? 'combinations_heading_blue'
							: 'combinations_heading_white'
					}
					width="2.7em"
					height="2.7em"
				/>
				<h3> Combinaties</h3>
			</Menu.Item>
		);
	};

	renderUsersLink = () => {
		const { activeItem } = this.state;
		return (
			<Menu.Item
				as={Link}
				to="/gebruikers"
				className="waw-menu-item"
				name="users"
				active={activeItem === 'users'}
				onClick={this.handleItemClick}
			>
				<Icon
					icon={activeItem === 'users' ? 'users_heading_blue' : 'users_heading_white'}
					width="2.7em"
					height="2.7em"
				/>
				<h3> Gebruikers</h3>
			</Menu.Item>
		);
	};

	renderSettingsLink = () => {
		const { activeItem } = this.state;
		return (
			<Menu.Item
				as={Link}
				to="/voorkeuren"
				name="settings"
				className="waw-menu-item"
				active={activeItem === 'settings'}
				onClick={this.handleItemClick}
			>
				<Icon
					icon={
						activeItem === 'settings'
							? 'settings_heading_blue'
							: 'settings_heading_white'
					}
					width="2.7em"
					height="2.7em"
				/>
				<h3>Voorkeuren</h3>
			</Menu.Item>
		);
	};
}
const mapStateToProps = state => {
	return { auth: state.auth };
};

export default connect(
	mapStateToProps,
	null,
)(WaWMenu);
