import React, { createRef } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import QuestionRoutes from '../routes/questionRoutes';
import CoreTalentsRoutes from '../routes/coretalentsRoutes';
import CandidatesRoutes from '../routes/candidatesRoutes';
import SubQuestionRoutes from '../routes/subquestionRoutes';
import UsersRoutes from '../routes/usersRoutes';
import RolesRoutes from '../routes/rolesRoutes';
import QuestionnaireRoutes from '../routes/questionnaireRoutes';
import CombinationsRoutes from '../routes/combinationsRoutes';
import ReportsRoutes from '../routes/reportsRoutes';
import PreferencesRoutes from '../routes/preferencesRoutes';
import CandidateOverviewRoutes from '../routes/candidateOverviewRoutes';

import history from '../history.js';
import Menu from './Menu';
import Header from './Header';
import Login from './Login';
import AuthRoute from './AuthRoute';
import { search } from '../actions/searchActions';
import { Grid, Responsive } from 'semantic-ui-react';
import '../css/Header.css';

const SIZE = 700;
class App extends React.Component {
	contextRef = createRef();

	constructor(props) {
		super(props);
		const initialColumnWidth = window.innerWidth <= SIZE ? 16 : 14;
		this.state = {
			columnWidth: initialColumnWidth,
		};
	}

	componentDidMount() {
		this.unlisten = history.listen(() => {
			this.props.search('');
		});
		window.addEventListener('resize', this.updateDimensions.bind(this));
	}

	componentWillUnmount() {
		this.unlisten();
		window.removeEventListener('resize', this.updateDimensions.bind(this));
	}

	updateDimensions = () => {
		if (window.innerWidth <= SIZE) {
			this.setState({ columnWidth: 16 });
		}
		if (window.innerWidth > SIZE) {
			this.setState({ columnWidth: 14 });
		}
	};

	render() {
		return (
			<Router history={history}>
				<Switch>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/vragenlijst">
						<div ref={this.contextRef}>
							<Grid className="waw-base-grid">
								<Responsive as={Grid.Row} minWidth={SIZE} />
								<Grid.Row>
									<Responsive as={Grid.Column} minWidth={SIZE} />
									<Grid.Column width={this.state.columnWidth}>
										<QuestionnaireRoutes mainContextRef={this.contextRef} />
									</Grid.Column>
									<Responsive as={Grid.Column} minWidth={SIZE} />
								</Grid.Row>
							</Grid>
						</div>
					</Route>
					<Route path="/kandidaat-overzicht">
						<CandidateOverviewRoutes />
					</Route>
					<AuthRoute path="/" token={this.props.token}>
						<Grid columns={16} padded>
							<Grid.Row />
							<Grid.Row>
								<Grid.Column width={16}>
									<Header />
								</Grid.Column>
							</Grid.Row>

							<Grid.Row>
								<Menu />
								<Grid.Column width={14}>
									<div className="waw-container">
										<PreferencesRoutes />
										<QuestionRoutes />
										<CoreTalentsRoutes />
										<CandidatesRoutes />
										<SubQuestionRoutes />
										<UsersRoutes />
										<RolesRoutes />
										<CombinationsRoutes />
										<ReportsRoutes />
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</AuthRoute>
				</Switch>
			</Router>
		);
	}
}

const mapStateToProps = state => {
	return { token: state.auth.token };
};

export default connect(mapStateToProps, { search })(App);
