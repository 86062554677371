import { ADD_ANSWER } from '../actions/types';

export default (state = {}, action) => {
	switch (action.type) {
		case ADD_ANSWER:
			return { ...state, [action.payload._id]: action.payload };
		default:
			return state;
	}
};
