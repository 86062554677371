import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { fetchClusters } from '../../actions/clustersActions';
import TableToolbar from '../TableToolbar';
import ClusterItem from './ClusterItem';
import Loader from '../Loader';
import { CAN_ADD_COMBINATIONS } from '../../permissions';

class ClusterList extends React.Component {
	state = {
		column: null,
		direction: null,
	};

	handleSort = clickedColumn => () => {
		const { column, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'ascending',
			});
			return;
		}

		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};

	componentDidMount() {
		this.props.fetchClusters();
	}

	renderList() {
		if (!this.props.clusters) {
			return <Loader />;
		}

		return this.props.clusters.map(cluster => {
			return <ClusterItem cluster={cluster} key={cluster._id} />;
		});
	}

	render() {
		if (!this.props.clusters) return <Loader />;
		const { column, direction } = this.state;
		let clusters = _.sortBy(this.props.clusters, [column, 'question']);
		clusters = direction === 'ascending' ? clusters : clusters.reverse();
		return (
			<>
				<TableToolbar
					rightButtonText="Nieuwe Cluster"
					to="/clusters/toevoegen"
					permission={CAN_ADD_COMBINATIONS}
				/>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'name' ? direction : null}
								onClick={this.handleSort('name')}
							>
								Clusters & Combinaties
							</Table.HeaderCell>
							<Table.HeaderCell>Kerntalenten</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{clusters.map(cluster => (
							<ClusterItem key={cluster._id} cluster={cluster} />
						))}
					</Table.Body>
				</Table>
			</>
		);
	}
}

const mapStateToProps = state => {
	const searchFilter = (cluster, value) =>
		cluster.name.toLowerCase().includes(value.toLowerCase()) ||
		cluster.coreTalents.find(talent => talent.code.toLowerCase().includes(value.toLowerCase()));
	const clusters = Object.values(state.clusters).filter(cluster =>
		searchFilter(cluster, state.search.search ? state.search.search : ''),
	);
	return { clusters };
};

export default connect(
	mapStateToProps,
	{ fetchClusters: fetchClusters },
)(ClusterList);
