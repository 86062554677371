import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Progress } from 'semantic-ui-react';
import logo from '../../img/wawlogo.svg';
import { clearErrors } from '../../actions/errorsActions';

class QuestionnaireError extends React.Component {
	componentDidMount() {
		this.props.clearErrors();
	}

	render() {
		return (
			<>
				<Grid.Row />
				<Grid.Row>
					<Container fluid className="waw-questionnaire">
						<Progress percent={100} progress color="orange" size="large" />
						<Container fluid className="questionnaire-end">
							<img alt="logo" src={logo} />
							<br />
							<h1>Onze excuses, er ging iets mis.</h1>
							<p> {this.props.errorMessage}</p>
							<p>Neem contact op met je analist via info@waw.jobs</p>
							<p>Je mag deze pagina nu sluiten.</p>
							<p>
								<b>Bedankt!</b>
							</p>
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
						</Container>
					</Container>
				</Grid.Row>
			</>
		);
	}
}

export default connect(
	null,
	{
		clearErrors,
	},
)(QuestionnaireError);
