import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { reports } from '../../apis/waw';
import WaWIcon from '../Icon';
import { CAN_DELETE_REPORTS, CAN_VIEW_REPORTS, CAN_SEND_EMAILS } from '../../permissions';
import Util from '../../util';

class ReportItem extends React.Component {
	constructor(props) {
		super(props);
		if (this.props.showCandidateName === 'undefined') {
		}
		this.util = new Util();
	}

	onDownload() {
		reports.get(`/${this.props.report._id}/download`);
	}

	renderDownloadAction = report => {
		if (this.checkPermission(CAN_VIEW_REPORTS)) {
			return (
				<a
					href={`/api/reports/${report._id}/download`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<WaWIcon icon="download" />
				</a>
			);
		}
		return null;
	};

	renderDeleteAction = report => {
		if (this.checkPermission(CAN_DELETE_REPORTS)) {
			return (
				<Link to={`/rapporten/verwijderen/${report._id}`}>
					<WaWIcon icon="delete" />
				</Link>
			);
		}
		return null;
	};

	renderEmailAction = report => {
		if (this.checkPermission(CAN_SEND_EMAILS)) {
			return (
				<Link to={`/rapporten/verzenden/${report._id}`}>
					<WaWIcon icon="email" />
				</Link>
			);
		}
		return null;
	};

	render() {
		const report = this.props.report;
		return (
			<Table.Row>
				<Table.Cell>{report.name}</Table.Cell>
				{this.props.showCandidateName ? (
					<Table.Cell>
						{report.candidate.firstname + ' ' + report.candidate.lastname}
					</Table.Cell>
				) : null}
				<Table.Cell>{this.util.formatDate(report.createdAt)}</Table.Cell>
				<Table.Cell>
					{report.analist
						? report.analist.firstname + ' ' + report.analist.lastname
						: 'Geen analist'}
				</Table.Cell>
				<Table.Cell>{report.destination}</Table.Cell>
				<Table.Cell collapsing>
					<div className="waw-table-actions">
						{this.renderEmailAction(report)}
						{this.renderDownloadAction(report)}
						{this.renderDeleteAction(report)}
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}

ReportItem.defaultProps = {
	showCandidateName: true,
};

const mapStateToProps = state => {
	return { auth: state.auth };
};

export default connect(
	mapStateToProps,
	null,
)(ReportItem);
