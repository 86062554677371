import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { fetchTexts } from '../../actions/settingsActions';
import Icon from '../Icon';
import Loader from '../Loader';
import history from '../../history';
import { CAN_EDIT_SETTINGS, CAN_VIEW_SETTINGS } from '../../permissions';

class TextList extends React.Component {
	componentDidMount() {
		this.props.fetchTexts();
	}

	renderText = (textId, title, text) => {
		return (
			<>
				<Table.Cell onClick={() => history.push(`/voorkeuren/${textId}`)}>
					{title}
				</Table.Cell>
				<Table.Cell onClick={() => history.push(`/voorkeuren/${textId}`)}>
					{text}
				</Table.Cell>
				<Table.Cell>{this.renderEditLink(textId)}</Table.Cell>
			</>
		);
	};

	renderEditLink = textId => {
		if (this.checkPermission(CAN_EDIT_SETTINGS)) {
			return (
				<Link to={`/voorkeuren/${textId}`}>
					<Icon icon="edit" />
				</Link>
			);
		}
		return null;
	};

	render() {
		if (!this.checkPermission(CAN_VIEW_SETTINGS)) {
			return null;
		}
		if (!this.props.settings) return <Loader />;
		return (
			<>
				<Table sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Tekst</Table.HeaderCell>
							<Table.HeaderCell>Beschrijving</Table.HeaderCell>
							<Table.HeaderCell>Acties</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							{this.renderText(
								'mail_link',
								'Mail bij verzenden van link',
								'Dit is de standaard tekst die in een e-mail naar een kandidaat wordt verzonden voor hij/zij de test ingevuld heeft, en moet de link bevatten om de test te kunnen starten.',
							)}
						</Table.Row>
						<Table.Row>
							{this.renderText(
								'mail_results',
								'Mail bij verzenden van resultaten',
								'Dit is de standaard tekst die in een e-mail naar een kandidaat wordt verzonden nadat hij/zij de test ingevuld heeft, en moet de link	bevatten om naar de pagina het overzicht van de resulaten te kunnen	gaan.',
							)}
						</Table.Row>
						<Table.Row>
							{this.renderText(
								'mail_sneak_preview',
								"Mail bij het verzenden van de 'sneak preview'",
								'Dit is de standaard tekst die in een e-mail naar een kandidaat wordt verzonden nadat hij/zij de test ingevuld heeft, maar VOOR dat de resultaten door een analist geanaliseerd zijn.',
							)}
						</Table.Row>
						<Table.Row>
							{this.renderText(
								'mail_client',
								'Mail bij het verzenden van een rapport naar een klant',
								'Dit is de standaard tekst die in een e-mail naar een klant zal verschijnen wanneer een rapport verzonden wordt.',
							)}
						</Table.Row>
						<Table.Row>
							{this.renderText(
								'report_ending',
								'Persoonlijke aanspreking aan het einde van een rapport',
								'Dit is de standaard tekst die wordt opgenomen aan het einde van een rapport, en kan de naam van de kandidaat en/of analist bevatten.',
							)}
						</Table.Row>
					</Table.Body>
				</Table>
			</>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}

const mapStateToProps = state => {
	return {
		settings: state.settings,
		auth: state.auth,
	};
};

export default connect(
	mapStateToProps,
	{ fetchTexts },
)(TextList);
