import React from 'react';
import { adjustScores } from '../../actions/candidatesActions';
import CandidateDetailAnswersForm from './CandidateDetailAnswersForm';
import { connect } from 'react-redux';
import { Table, Divider, Container } from 'semantic-ui-react';
import Loader from '../Loader';
import { CAN_EDIT_REPORTS } from '../../permissions';

class CandidateDetailAnswers extends React.Component {
	onSubmit = answers => {
		this.props.adjustScores(this.props.candidate._id, answers);
	};

	render() {
		if (!this.props.candidate) return <Loader />;
		if (!this.checkPermission(CAN_EDIT_REPORTS))
			return <div>Je hebt niet genoeg permissies om deze pagina te bekijken.</div>;
		let error;
		if (this.props.candidate.questionnaire) {
			const answers = this.props.candidate.questionnaire.answeredQuestions;
			if (answers) {
				let initialValues;
				if (
					this.props.candidate.questionnaire &&
					this.props.candidate.questionnaire.answeredQuestions
				) {
					initialValues = this.props.candidate.questionnaire.answeredQuestions.sort(
						(a, b) => {
							if (a.coreTalentCode > b.coreTalentCode) return 1;
							if (a.coreTalentCode < b.coreTalentCode) return -1;
							// If equal, do this
							if (a.score > b.score) return -1;
							if (a.score < b.score) return 1;
							return 0;
						},
					);
				} else {
					initialValues = [];
				}
				return (
					<div>
						<h2 className="printOnly">
							{`${this.props.candidate.firstname} ${this.props.candidate.lastname}`}
						</h2>
						<Container fluid className="description">
							<Divider />
							<h2>Beschrijving</h2>
							<p>{this.props.candidate.questionnaire.description}</p>
						</Container>
						<CandidateDetailAnswersForm
							onSubmit={this.onSubmit}
							initialValues={{ answers: initialValues }}
						/>
					</div>
				);
			} else {
				error = 'Nog geen vragen beantwoord';
			}
		} else {
			error = 'Vragenlijst nog niet gestart';
		}
		return (
			<Table sortable celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>KernTalent</Table.HeaderCell>
						<Table.HeaderCell>Vraag</Table.HeaderCell>
						<Table.HeaderCell>Antwoord</Table.HeaderCell>
						<Table.HeaderCell>Aanpassing</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row>
						<Table.Cell>
							<td colSpan="4">{error}</td>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}

const mapStateToProps = state => {
	return {
		auth: state.auth,
	};
};

export default connect(
	mapStateToProps,
	{ adjustScores: adjustScores },
)(CandidateDetailAnswers);
