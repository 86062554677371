import { CREATE_ROLE, FETCH_ROLE, FETCH_ROLES, DELETE_ROLE, EDIT_ROLE } from './types';
import { roles } from '../apis/waw';
import history from '../history';

export const fetchRole = id => async dispatch => {
	const response = await roles.get(`/${id}`);
	dispatch({
		type: FETCH_ROLE,
		payload: response.data,
	});
};

export const fetchRoles = () => async dispatch => {
	const response = await roles.get('/');
	dispatch({
		type: FETCH_ROLES,
		payload: response.data,
	});
};

export const createRole = formValues => async dispatch => {
	const response = await roles.post('/', { ...formValues });
	dispatch({
		type: CREATE_ROLE,
		payload: response.data,
	});
	history.push('/voorkeuren');
};

export const deleteRole = id => async dispatch => {
	await roles.delete(`/${id}`);
	dispatch({
		type: DELETE_ROLE,
		payload: id,
	});
	history.push('/voorkeuren');
};

export const editRole = (formValues, id) => async dispatch => {
	const response = await roles.put(`/${id}`, { ...formValues });
	dispatch({
		type: EDIT_ROLE,
		payload: response.data,
	});
	history.push('/voorkeuren');
};
