import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import Icon from '../Icon';

class ClusterForm extends React.Component {
	onSubmit = formValues => {
		this.props.onSubmit(formValues);
	};

	renderCoreTalents = ({ fields, meta: { error } }) => {
		return (
			<div>
				{fields.map((coreTalent, index) => {
					return (
						<div className="waw-flex field" key={index}>
							<div className="waw-flex-auto">
								<Field
									name={coreTalent}
									component="select"
									disabled={!this.props.allowEditCoreTalents}
								>
									{this.props.coreTalents.map((coreTalent, index) => (
										<option key={`option-${index}`} value={coreTalent._id}>
											{coreTalent.name}
										</option>
									))}
								</Field>
							</div>
							<button
								type="button"
								className="waw-flex-icon"
								onClick={() => fields.remove(index)}
								disabled={!this.props.allowEditCoreTalents}
							>
								<Icon icon="delete" />
							</button>
						</div>
					);
				})}
				<div className="field">
					<button
						type="button"
						className="ui button primary"
						disabled={!this.props.allowEditCoreTalents}
						onClick={() => fields.push(this.props.coreTalents[0]._id)}
					>
						Kerntalent toevoegen
					</button>
				</div>
			</div>
		);
	};

	render() {
		return (
			<form className="ui form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<div className="waw-error">{this.props.error}</div>
				<div className="field">
					<label>Naam</label>
					<div>
						<Field name="name" component="input" type="text" />
					</div>
				</div>
				<div className="field">
					<label>
						Kerntalenten{' '}
						{this.props.allowEditCoreTalents
							? ''
							: '(Kunnen niet aangepast worden omdat de cluster reeds combinaties bevat)'}
					</label>
					<div>
						<FieldArray name="coreTalents" component={this.renderCoreTalents} />
					</div>
				</div>
				<div className="waw-flex waw-end">
					<button type="submit" className="ui button primary">
						Opslaan
					</button>
				</div>
			</form>
		);
	}
}

ClusterForm = reduxForm({
	form: 'clusterForm',
})(ClusterForm);

export default ClusterForm;
