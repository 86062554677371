import React from 'react';
import { Grid, Container, Divider, Card, Progress, Image, Responsive } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { fetchReport } from '../../actions/reportsActions';
import { fetchCandidate } from '../../actions/candidatesActions';
import { fetchCoreTalents } from '../../actions/coretalentsActions';

import Loader from '../Loader';
import Icon from '../Icon';
import history from '../../history';
import {
	STATE_QUESTIONNAIRE_COMPLETED,
	STATE_SNEAK_PREVIEW_SENT,
	STATE_ANALYSIS_COMPLETED,
	STATE_RESULTS_SENT,
} from '../CandidateStates';

const images = require.context('../../img', true);

class Overview extends React.Component {
	componentDidMount() {
		if (this.props.match.params.candidateId)
			this.props.fetchCandidate(this.props.match.params.candidateId);
		if (this.props.match.params.reportId)
			this.props.fetchReport(this.props.match.params.reportId);
		this.props.fetchCoreTalents();
	}

	render() {
		if (!this.props.candidate) return <Loader />;
		const candidateState = this.props.candidate.state;
		return (
			<>
				<Responsive
					as={Grid}
					minWidth={900}
					padded
					columns={16}
					className="waw-candidate-overview"
				>
					<Grid.Row>
						<Grid.Column floated="left" width={4}>
							<Image src={images(`./wawlogo.svg`)} size="tiny" />
						</Grid.Column>
						<Grid.Column floated="right" width={4}>
							<h2 className="waw-overview-header">
								{candidateState === STATE_QUESTIONNAIRE_COMPLETED ||
								candidateState === STATE_SNEAK_PREVIEW_SENT
									? 'Sneak Preview'
									: 'KernTalenten Rapport'}
							</h2>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={4}>
							<div className="waw-overview-box">
								<Container textAlign="center">
									{this.renderCandidateInfo()}
								</Container>
								<Container>
									<h3>Beschrijving</h3>
									{this.renderDescription()}
								</Container>
							</div>
						</Grid.Column>
						<Grid.Column width={12}>
							<Grid.Row>
								{candidateState === STATE_ANALYSIS_COMPLETED ||
								candidateState === STATE_RESULTS_SENT ? (
									<>
										<h3>JOUW STERKSTE</h3>
										<h1 className="coretalents-title">KernTalenten</h1>
									</>
								) : null}
							</Grid.Row>
							<Grid.Row width="equal">
								{candidateState === STATE_QUESTIONNAIRE_COMPLETED ||
								candidateState === STATE_SNEAK_PREVIEW_SENT
									? this.renderSneakPreview()
									: this.renderFullCoreTalents()}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
				</Responsive>

				<Responsive
					as={Grid}
					maxWidth={900}
					padded
					columns={16}
					className="waw-candidate-overview"
				>
					<Grid.Row>
						<Grid.Column width={16}>
							<Image src={images(`./wawlogo.svg`)} size="tiny" className="wawlogo" />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16}>
							<Grid.Row>
								<div className="waw-overview-box">
									<Container textAlign="center">
										{this.renderCandidateInfo()}
									</Container>
									<Container>
										<h3>Beschrijving</h3>
										{this.renderDescription()}
									</Container>
								</div>
							</Grid.Row>
							<Grid.Row width="equal">
								{candidateState === STATE_QUESTIONNAIRE_COMPLETED ||
								candidateState === STATE_SNEAK_PREVIEW_SENT
									? this.renderSneakPreview()
									: this.renderFullCoreTalents()}
							</Grid.Row>
						</Grid.Column>
					</Grid.Row>
				</Responsive>
			</>
		);
	}

	renderFullCoreTalents = () => {
		if (!this.dataAreAvailable()) return this.renderCompleteTestFirstMessage();
		const talenten = this.props.candidate.coreTalentsScores.sort((a, b) => b.score - a.score);

		return (
			<Card.Group>
				{talenten.map(talent => {
					const correspondingTalent = this.getCorrespondingCoretalent(talent.code);
					if (!correspondingTalent || !correspondingTalent.includeInReport) return null;
					return (
						<Card
							key={talent.code}
							className="waw-overview-card"
							onClick={() => {
								if (this.props.match.params.reportId) {
									history.push(
										`/kandidaat-overzicht/${
											this.props.match.params.candidateId
										}/${this.props.match.params.reportId}/detail/${
											talent.code
										}`,
									);
								} else {
									history.push(
										`/kandidaat-overzicht/${
											this.props.match.params.candidateId
										}/detail/${talent.code}`,
									);
								}
							}}
						>
							<Card.Content>
								<Card.Header className="waw-card-header">{talent.name}</Card.Header>
								<Card.Meta />
								<Progress percent={talent.score} color="orange" progress />
							</Card.Content>
						</Card>
					);
				})}
			</Card.Group>
		);
	};

	renderSneakPreview = () => {
		if (!this.dataAreAvailable()) return this.renderCompleteTestFirstMessage();
		const sortedTalents = this.props.candidate.coreTalentsScores.sort(
			(a, b) => b.score - a.score,
		);
		return <Card.Group>{this.renderHighestFourTalents(sortedTalents.slice(0, 5))}</Card.Group>;
	};

	renderHighestFourTalents = sortedTalents => {
		let counter = 0;
		return sortedTalents.map(talent => {
			if (counter >= 4) return null;
			const correspondingTalent = this.getCorrespondingCoretalent(talent.code);
			if (!correspondingTalent || !correspondingTalent.includeInReport) return null;
			counter++;
			return (
				<Card key={talent.code} className="waw-sneak-preview-card">
					<Card.Content>
						<Card.Header className="waw-sneak-preview-card-header">
							{correspondingTalent.name}
						</Card.Header>
						<Progress percent={talent.score} color="orange" progress />
						<div className="sneak-preview-card-content">
							{correspondingTalent.description}
						</div>
					</Card.Content>
				</Card>
			);
		});
	};

	getCorrespondingCoretalent = code => {
		return this.props.coretalents.find(coreTalent => coreTalent.code === code);
	};

	renderCandidateInfo = () => {
		if (this.props.candidate) {
			return (
				<>
					<h2 className="candidate-name">
						{this.props.candidate.firstname} {this.props.candidate.lastname}
					</h2>
					{this.props.match.params.reportId ? (
						<>
							<h3>Download je rapport</h3>
							<div className="waw-report-download">
								<a
									href={`/api/reports/${
										this.props.match.params.reportId
									}/download`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Icon icon="download" />
								</a>
							</div>

							<div className="waw-social" />
							<Divider />
						</>
					) : null}
				</>
			);
		} else {
			return <Loader />;
		}
	};

	renderDescription = () => {
		if (!this.props.candidate) return <Loader />;
		const candidateState = this.props.candidate.state;
		if (
			candidateState === STATE_QUESTIONNAIRE_COMPLETED ||
			candidateState === STATE_SNEAK_PREVIEW_SENT
		) {
			return (
				<>
					<p>
						Hier vind je een sneak preview met jouw 4 (voorlopig) sterkste KernTalenten.
					</p>
					<p>
						Let op: je antwoorden zijn{' '}
						<b>nog niet gecheckt door een erkende KernTalenten-analist</b>.
					</p>
					<p>
						Bij zo’n check zullen we je antwoorden controleren, scherpstellen en wegen.
						Dat leidt tot een uitgebreid persoonlijk rapport met jouw scores op alle 23
						KernTalenten.
					</p>
				</>
			);
		} else if (
			candidateState === STATE_ANALYSIS_COMPLETED ||
			candidateState === STATE_RESULTS_SENT
		) {
			return (
				<>
					<p>
						Hier kan je een overzicht zien van al je KernTalenten, in aflopende
						volgorde.
					</p>
					<p>
						Wanneer je op een KernTalent <b>klikt</b>, zal je er meer informatie over
						krijgen.
					</p>
				</>
			);
		}
	};

	dataAreAvailable = () => {
		return this.props.candidate && this.props.candidate.coreTalentsScores;
	};

	renderCompleteTestFirstMessage = () => {
		return (
			<Container className="waw-overview-box">
				<h3>Test nog niet volledig!</h3>
				<p>
					Sorry, je moet eerst de test volledig invullen voordat je je resultaten kan
					bekijken!
				</p>
			</Container>
		);
	};
}

const mapStateToProps = (state, ownProps) => {
	return {
		candidate: state.candidates[ownProps.match.params.candidateId],
		report: state.reports[ownProps.match.params.reportId],
		coretalents: Object.values(state.coretalents),
	};
};

export default connect(
	mapStateToProps,
	{ fetchReport, fetchCandidate, fetchCoreTalents },
)(Overview);
