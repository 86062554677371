import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { deleteCandidate, fetchCandidate } from '../../actions/candidatesActions';
import Loader from '../Loader';
import Modal from '../Modal';
import history from '../../history';

class CandidateDelete extends React.Component {
	componentDidMount() {
		this.props.fetchCandidate(this.props.match.params.id);
	}

	renderContent() {
		return (
			<>
				<p>
					Ben je zeker dat je kanidaat{' '}
					<b>
						{this.props.candidate.firstname} {this.props.candidate.lastname}
					</b>{' '}
					wil verwijderen?
				</p>
			</>
		);
	}

	renderActions() {
		return (
			<>
				<button
					onClick={() => this.props.deleteCandidate(this.props.match.params.id)}
					className="ui negative button"
				>
					Verwijderen
				</button>
				<Link to="/kandidaten" className="ui button">
					Annuleren
				</Link>
			</>
		);
	}

	render() {
		if (!this.props.candidate) return <Loader />;
		return (
			<Modal
				title="Kandidaat verwijderen"
				content={this.renderContent()}
				actions={this.renderActions()}
				onDismiss={() => history.push('/kandidaten')}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return { candidate: state.candidates[ownProps.match.params.id] };
};

export default connect(
	mapStateToProps,
	{ fetchCandidate: fetchCandidate, deleteCandidate: deleteCandidate },
)(CandidateDelete);
