import React from "react";
import { fetchUsers } from "../../actions/usersActions";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Loader from "../Loader";

class CandidateForm extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  onSubmit = formValues => {
    this.props.onSubmit(formValues);
  };

  render() {
    if (!this.props.users) return <Loader />;
    return (
      <form
        className="ui form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <div className="waw-error">{this.props.error}</div>
        <div className="field">
          <label>Voornaam</label>
          <div>
            <Field name="firstname" component="input" required />
          </div>
        </div>
        <div className="field">
          <label>Familienaam</label>
          <div>
            <Field name="lastname" component="input" required />
          </div>
        </div>
        <div className="field">
          <label>E-mail adres</label>
          <div>
            <Field name="email" component="input" required />
          </div>
        </div>
        <div className="field">
          <label>Extra informatie</label>
          <div>
            <Field name="info" component="textarea" />
          </div>
        </div>
        <br />
        <button type="submit" className="ui button primary">
          Opslaan
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return { users: Object.values(state.users) };
};

CandidateForm = reduxForm({
  form: "candidatesForm"
})(CandidateForm);

export default connect(
  mapStateToProps,
  { fetchUsers: fetchUsers }
)(CandidateForm);
