import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { fetchCombinationsOfCluster } from '../../actions/combinationsActions';
import Loader from '../Loader';
import Icon from '../Icon';
import { CAN_EDIT_COMBINATIONS } from '../../permissions';

class CombinationList extends React.Component {
	componentDidMount() {
		this.props.fetchCombinationsOfCluster(this.props.clusterId);
	}

	renderEditAction = combination => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_EDIT_COMBINATIONS)) {
			return (
				<Link to={`/combinaties/aanpassen/${combination._id}`}>
					<Icon icon="edit" />
				</Link>
			);
		}
	};

	renderDeleteAction = combination => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === CAN_EDIT_COMBINATIONS)) {
			return (
				<Link to={`/combinaties/verwijderen/${combination._id}`}>
					<Icon icon="delete" />
				</Link>
			);
		}
	};

	render() {
		if (!this.props.combinations) return <Loader />;
		return this.props.combinations.map(combination => (
			<Table.Row key={combination._id} className="waw-table-sub-row">
				<Table.Cell>{combination.code}</Table.Cell>
				<Table.Cell>
					<div className="waw-flex">
						{combination.coreTalents.map(talent => (
							<div key={combination._id + talent._id} className="waw-flex-1">
								{talent.strength}
							</div>
						))}
					</div>
				</Table.Cell>
				<Table.Cell collapsing>
					<div className="waw-table-actions">
						{this.renderEditAction(combination)}
						{this.renderDeleteAction(combination)}
					</div>
				</Table.Cell>
			</Table.Row>
		));
	}
}

const mapStateToProps = (state, ownProps) => {
	let combinationsWithRightID = Object.values(state.combinations).filter(
		combination => combination.cluster === ownProps.clusterId,
	);
	return { combinations: combinationsWithRightID, auth: state.auth };
};

export default connect(
	mapStateToProps,
	{ fetchCombinationsOfCluster: fetchCombinationsOfCluster },
)(CombinationList);
