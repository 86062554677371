import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Grid, Divider, Confirm, Container } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactToPrint from 'react-to-print';

import Loader from '../Loader';
import { fetchCandidate } from '../../actions/candidatesActions';
import { fetchUsers } from '../../actions/usersActions';

import { validateQuestionnaire, fetchQuestionnaire } from '../../actions/questionnairesActions';
import CandidateDetailReports from './CandidateDetailReports';
import CandidateDetailCoreTalents from './CandidateDetailCoreTalents';
import CandidateDetailAnswers from './CandidateDetailAnswers';
import history from '../../history';
import CandidateLogs from './CandidateLogs';
import '../../css/CandidateDetail.css';
import Util from '../../util';

import {
	CAN_VALIDATE_QUESTIONNAIRES,
	CAN_SEND_EMAILS,
	CAN_ADD_REPORTS,
	CAN_EDIT_REPORTS,
} from '../../permissions';
import {
	STATE_QUESTIONNAIRE_COMPLETED,
	STATE_SNEAK_PREVIEW_SENT,
	STATE_ANALYSIS_COMPLETED,
	STATE_RESULTS_SENT,
} from '../CandidateStates';

class CandidateDetail extends React.Component {
	answersPrintRef = createRef();
	coreTalentsPrintRef = createRef();

	constructor() {
		super();
		this.state = {
			showReports: false,
			showCoreTalents: true,
			showAnswers: false,
			confirmDialogOpen: false,
			analist: null,
		};
		this.util = new Util();
	}

	async componentDidMount() {
		await Promise.all([
			this.props.fetchUsers(),
			this.props.fetchCandidate(this.props.match.params.id),
		]);
		if (this.props.candidate && this.props.candidate.questionnaire) {
			let questionnaireId;
			if (this.props.candidate.questionnaire._id)
				questionnaireId = this.props.candidate.questionnaire._id;
			else questionnaireId = this.props.candidate.questionnaire;

			fetchQuestionnaire(questionnaireId);
		}
	}

	renderQuestionnaireStatus(candidate) {
		return <CandidateLogs candidate={candidate} />;
	}

	renderUpperSection() {
		let candidate = this.props.candidate;
		return (
			<Grid padded>
				<Grid.Row>
					<Grid.Column>
						<div className="detail-heading">
							<h1>
								{candidate.firstname} {candidate.lastname}
							</h1>
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="waw-candidate-info">
					<Grid.Column width={9}>
						<>
							<p>
								{candidate.email ? candidate.email : 'Geen e-mail adres gevonden!'}
							</p>
							{this.renderQuestionnaireStatus(candidate)}
						</>
					</Grid.Column>
					<Grid.Column width={6} floated="right">
						<b>Link naar vragenlijst:</b>
						<br />
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={candidate.questionnaireLink}
						>
							{candidate.questionnaireLink}
						</a>
						<CopyToClipboard text={candidate.questionnaireLink} onCopy={() => {}}>
							<span className="waw-copy">
								<Icon circular name="clipboard outline" />
							</span>
						</CopyToClipboard>
						{candidate.resultLink ? (
							<>
								<br />
								<b>Link naar resultaten:</b>
								<br />
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={candidate.resultLink}
								>
									{candidate.resultLink}
								</a>
								<CopyToClipboard text={candidate.resultLink} onCopy={() => {}}>
									<span className="waw-copy">
										<Icon circular name="clipboard outline" />
									</span>
								</CopyToClipboard>{' '}
							</>
						) : null}
					</Grid.Column>
				</Grid.Row>
				<Divider />
				<Grid.Row>
					<Container fluid>
						<Grid padded>
							<Grid.Row>
								<Grid.Column floated="left" width={3}>
									<h2>Extra informatie</h2>
								</Grid.Column>
								<Grid.Column floated="right" width={4}>
									<Confirm
										open={this.state.confirmDialogOpen}
										content="Ben je zeker dat je deze resulaten wil vrijgeven? Vanaf nu kunnen rapporten gegenereerd worden."
										cancelButton="Nee, niet vrijgeven"
										confirmButton="Ja, vrijgeven"
										onCancel={() =>
											this.setState({
												confirmDialogOpen: false,
											})
										}
										onConfirm={async () => {
											await this.props.validateQuestionnaire(
												this.props.candidate.questionnaire._id,
											);
											this.setState({
												confirmDialogOpen: false,
											});
											window.location.reload();
										}}
									/>
									{this.checkPermission(CAN_VALIDATE_QUESTIONNAIRES)
										? this.renderValidateButton()
										: null}
									{this.checkPermission(CAN_SEND_EMAILS)
										? this.renderEmailButton()
										: null}
									{this.checkPermission(CAN_ADD_REPORTS)
										? this.renderNewReportButton()
										: null}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16}>
									<p>
										{candidate.info
											? candidate.info
											: 'Geen extra info gevonden.'}
									</p>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Grid.Row>
				<Divider />
			</Grid>
		);
	}
	renderButtons() {
		return (
			<Grid padded>
				<Grid.Row>
					<Grid.Column width={8}>
						<Button
							active={this.state.showReports}
							basic={!this.state.showReports}
							color={this.state.showReports ? 'orange' : null}
							onClick={() => this.showTable('reports')}
						>
							Rapporten
						</Button>

						<Button
							active={this.state.showCoreTalents}
							basic={!this.state.showCoreTalents}
							color={this.state.showCoreTalents ? 'orange' : null}
							onClick={() => this.showTable('coretalents')}
						>
							KernTalenten
						</Button>
						{this.checkPermission(CAN_EDIT_REPORTS) ? (
							<Button
								active={this.state.showAnswers}
								basic={!this.state.showAnswers}
								color={this.state.showAnswers ? 'orange' : null}
								onClick={() => this.showTable('answers')}
							>
								Antwoorden
							</Button>
						) : null}
					</Grid.Column>
					<Grid.Column width={4} />
					<Grid.Column floated="right" width={4}>
						{this.state.showAnswers ? (
							<ReactToPrint
								trigger={() => (
									<Button className="new-report" basic>
										<Icon name="print" />
										Print
									</Button>
								)}
								content={() => this.answersPrintRef}
							/>
						) : null}
						{this.state.showCoreTalents ? (
							<ReactToPrint
								trigger={() => (
									<Button className="new-report" basic>
										<Icon name="print" />
										Print
									</Button>
								)}
								content={() => this.coreTalentsPrintRef}
							/>
						) : null}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}

	renderValidateButton = () => {
		const questionnaire = this.props.candidate.questionnaire;
		if (
			questionnaire &&
			(this.props.candidate.state === STATE_QUESTIONNAIRE_COMPLETED ||
				this.props.candidate.state === STATE_SNEAK_PREVIEW_SENT)
		) {
			return (
				<Button icon basic onClick={() => this.setState({ confirmDialogOpen: true })}>
					<Icon name="check" /> Vrijgeven
				</Button>
			);
		}
	};

	renderNewReportButton = () => {
		if (
			this.props.candidate.state === 'analysis_completed' ||
			this.props.candidate.state === 'results_sent'
		) {
			return (
				<Button
					icon
					basic
					onClick={() => history.push(`/rapporten/toevoegen/${this.props.candidate._id}`)}
				>
					<Icon name="plus" /> Nieuw rapport
				</Button>
			);
		}
	};

	renderEmailButton = () => {
		if (this.props.candidate.email) {
			let text = 'Verstuur link';
			const candidateState = this.props.candidate.state;
			if (
				candidateState === STATE_QUESTIONNAIRE_COMPLETED ||
				candidateState === STATE_SNEAK_PREVIEW_SENT
			) {
				text = "Verstuur 'Sneak Preview'";
			} else if (
				candidateState === STATE_ANALYSIS_COMPLETED ||
				candidateState === STATE_RESULTS_SENT
			) {
				text = 'Verstuur resultaten';
			}
			return (
				<Button
					icon
					basic
					onClick={() => history.push(`/kandidaten/email/${this.props.candidate._id}`)}
				>
					<Icon name="mail outline" />
					{' ' + text}
				</Button>
			);
		}
	};

	renderLowerSection() {
		if (this.state.showReports)
			return <CandidateDetailReports candidate={this.props.candidate} />;
		if (this.state.showAnswers) {
			let initialValues = { answers: null };
			if (
				this.props.candidate.questionnaire &&
				this.props.candidate.questionnaire.answeredQuestions
			) {
				initialValues = this.props.candidate.questionnaire.answeredQuestions.sort(
					(a, b) => {
						if (a.coreTalentCode > b.coreTalentCode) return 1;
						if (a.coreTalentCode < b.coreTalentCode) return -1;
						// If equal, do this
						if (a.score > b.score) return -1;
						if (a.score < b.score) return 1;
						return 0;
					},
				);
			}
			return (
				<>
					<CandidateDetailAnswers
						ref={el => (this.answersPrintRef = el)}
						candidate={this.props.candidate}
						initialValues={{ answers: initialValues }}
					/>
				</>
			);
		}
		if (this.state.showCoreTalents)
			return (
				<CandidateDetailCoreTalents
					candidate={this.props.candidate}
					ref={el => (this.coreTalentsPrintRef = el)}
				/>
			);
	}

	showTable(table) {
		switch (table) {
			case 'reports':
				this.setState({
					showReports: true,
					showCoreTalents: false,
					showAnswers: false,
				});
				break;
			case 'coretalents':
				this.setState({
					showReports: false,
					showCoreTalents: true,
					showAnswers: false,
				});
				break;
			case 'answers':
				this.setState({
					showReports: false,
					showCoreTalents: false,
					showAnswers: true,
				});
				break;
			case 'none':
				this.setState({
					showReports: false,
					showCoreTalents: false,
					showAnswers: false,
				});
				break;
			default:
				this.setState({
					showReports: true,
					showCoreTalents: false,
					showAnswers: false,
				});
		}
	}

	render() {
		if (!this.props.candidate) {
			return (
				<Container>
					<Loader />
				</Container>
			);
		}

		return (
			<>
				{this.renderUpperSection(this.props.candidate)}
				{this.renderButtons()}
				{this.renderLowerSection()}
			</>
		);
	}

	checkPermission = permission => {
		const permissions = this.props.auth.permissions;
		if (permissions && permissions.find(perm => perm === permission)) {
			return true;
		}
		return false;
	};
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.auth,
		candidate: state.candidates[ownProps.match.params.id],
		users: state.users,
	};
};

export default connect(
	mapStateToProps,
	{
		fetchCandidate: fetchCandidate,
		fetchQuestionnaire: fetchQuestionnaire,
		fetchUsers: fetchUsers,
		validateQuestionnaire,
	},
)(CandidateDetail);
