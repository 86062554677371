import React from "react";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Icon from "../Icon";
import { CAN_EDIT_ROLES, CAN_DELETE_ROLES } from "../../permissions";

const default_value = "DEFAULT";

class RoleItem extends React.Component {
  renderRights = () => {
    //TODO: sort!
    return Object.keys(this.props.role).map(key => {
      if (this.props.role[key]) {
        const translation = this.translate(key);
        if (translation !== default_value)
          return <li key={`${this.props.role._id}:${key}`}>{translation}</li>;
      }
      return null;
    });
  };

  render() {
    const role = this.props.role;
    return (
      <Table.Row key={role._id}>
        <Table.Cell>{role.name}</Table.Cell>
        <Table.Cell>
          <ul>{this.renderRights()}</ul>
        </Table.Cell>
        <Table.Cell collapsing>
          {role.name !== "SYSTEM_ADMIN" ? this.renderActions(role) : null}
        </Table.Cell>
      </Table.Row>
    );
  }

  renderActions = role => {
    return (
      <div className="waw-table-actions">
        {this.renderEditAction(role)}
        {this.renderDeleteAction(role)}
      </div>
    );
  };

  renderEditAction = role => {
    const permissions = this.props.auth.permissions;
    if (permissions && permissions.find(perm => perm === CAN_EDIT_ROLES)) {
      return (
        <Link to={`/rollen/aanpassen/${role._id}`}>
          <Icon icon="edit" />
        </Link>
      );
    }
  };

  renderDeleteAction = role => {
    const permissions = this.props.auth.permissions;
    if (permissions && permissions.find(perm => perm === CAN_DELETE_ROLES)) {
      return (
        <Link to={`/rollen/verwijderen/${role._id}`}>
          <Icon icon="delete" />
        </Link>
      );
    }
  };

  translate = key => {
    let translation;
    switch (key) {
      case "canViewCandidates":
        translation = "Kan kandidaten bekijken";
        break;
      case "canAddCandidates":
        translation = "Kan kandidaten toevoegen";
        break;
      case "canEditCandidates":
        translation = "Kan kandidaten aanpassen";
        break;
      case "canDeleteCandidates":
        translation = "Kan kandidaten verwijderen";
        break;
      case "canViewCoreTalents":
        translation = "Kan KernTalenten bekijken";
        break;
      case "canAddCoreTalents":
        translation = "Kan KernTalenten toevoegen";
        break;
      case "canEditCoreTalents":
        translation = "Kan KernTalenten aanpassen";
        break;
      case "canDeleteCoreTalents":
        translation = "Kan KernTalenten verwijderen";
        break;
      case "canViewCombinations":
        translation = "Kan combinaties bekijken";
        break;
      case "canAddCombinations":
        translation = "Kan combinaties toevoegen";
        break;
      case "canEditCombinations":
        translation = "Kan combinaties aanpassen";
        break;
      case "canDeleteCombinations":
        translation = "Kan combinaties verwijderen";
        break;
      case "canViewQuestions":
        translation = "Kan vragen bekijken";
        break;
      case "canAddQuestions":
        translation = "Kan vragen toevoegen";
        break;
      case "canEditQuestions":
        translation = "Kan vragen aanpassen";
        break;
      case "canDeleteQuestions":
        translation = "Kan vragen verwijderen";
        break;
      case "canViewReports":
        translation = "Kan rapporten bekijken";
        break;
      case "canAddReports":
        translation = "Kan rapporten toevoegen";
        break;
      case "canEditReports":
        translation = "Kan rapporten aanpassen";
        break;
      case "canDeleteReports":
        translation = "Kan rapporten verwijderen";
        break;
      case "canViewUsers":
        translation = "Kan gebruikers bekijken";
        break;
      case "canAddUsers":
        translation = "Kan gebruikers toevoegen";
        break;
      case "canEditUsers":
        translation = "Kan gebruikers aanpassen";
        break;
      case "canDeleteUsers":
        translation = "Kan gebruikers verwijderen";
        break;
      case "canViewRoles":
        translation = "Kan rollen bekijken";
        break;
      case "canAddRoles":
        translation = "Kan rollen toevoegen";
        break;
      case "canEditRoles":
        translation = "Kan rollen aanpassen";
        break;
      case "canDeleteRoles":
        translation = "Kan rollen verwijderen";
        break;
      case "canViewSettings":
        translation = "Kan voorkeuren bekijken";
        break;
      case "canEditSettings":
        translation = "Kan voorkeuren aanpassen";
        break;
      case "canValidateTestResults":
        translation = "Kan testresultaten vrijgeven";
        break;
      case "canSendEmails":
        translation = "Kan e-mails verzenden";
        break;
      default:
        translation = default_value;
        break;
    }
    return translation;
  };
}
const mapStateToProps = state => {
  return { auth: state.auth };
};
export default connect(
  mapStateToProps,
  null
)(RoleItem);
